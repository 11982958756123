import { interpolate, toText } from "./.fable/fable-library.3.0.0/String.js";

export const AppConfig_appVersion = "Online Documentation v.2021.05.19.00";

export const AppConfig_apiaddrr = "https://projectsapi.aimtecglobal.com/api/";

export const AppConfig_serviceDeskUrl = "https://sd.aimtecglobal.com/";

const PbsListApiAddress_apiAddress = AppConfig_apiaddrr;

export function PbsListApiAddress_fetchPbsesAddress(projectId) {
    return PbsListApiAddress_apiAddress + toText(interpolate("PbsCookie/ProjectPbses/%P()", [projectId]));
}

export function PbsListApiAddress_fetchPbsTranslationsAddress(projectId, languageId) {
    return PbsListApiAddress_apiAddress + toText(interpolate("PbsCookie/ProjectTranslations/%P()/%P()", [projectId, languageId]));
}

export function PbsListApiAddress_postUpdatePbsAddress() {
    return PbsListApiAddress_apiAddress + "PbsCookie/EditPbs";
}

export function PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress() {
    return PbsListApiAddress_apiAddress + "PbsCookie/EditDescriptionCustomer";
}

export function PbsListApiAddress_postResetPbsCustomerDescriptionAddress() {
    return PbsListApiAddress_apiAddress + "PbsCookie/ResetDescriptionCustomer";
}

const PbsMenuApiAddress_apiAddress = AppConfig_apiaddrr;

export function PbsMenuApiAddress_fetchProjectAddress(projectId) {
    return PbsMenuApiAddress_apiAddress + toText(interpolate("ProjectCookie/Project/%P()", [projectId]));
}

export function PbsMenuApiAddress_fetchPbsChildrenAddress(pbsId) {
    return PbsMenuApiAddress_apiAddress + toText(interpolate("ProjectCookie/PbsChildren/%P()", [pbsId]));
}

const SessionApiAddress_apiAddress = AppConfig_apiaddrr;

export const SessionApiAddress_serviceDeskUrl = AppConfig_serviceDeskUrl;

export function SessionApiAddress_fetchSessionAddress() {
    return SessionApiAddress_apiAddress + "odc/session";
}

const ServiceDeskApiAddress_apiAddress = AppConfig_apiaddrr;

export function ServiceDeskApiAddress_fetchIncidentsRelatedToProcessAddress(processId) {
    return ServiceDeskApiAddress_apiAddress + toText(interpolate("SedCookie/IncidentsRelatedToProcess/%P()", [processId]));
}

export function ServiceDeskApiAddress_createIncidentAddress() {
    return ServiceDeskApiAddress_apiAddress + "SedCookie/CreateIncident";
}

export function ServiceDeskApiAddress_serviceDeskUploadAddress() {
    return ServiceDeskApiAddress_apiAddress + "SedCookie/upload";
}

