import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { list_type, record_type, int32_type, option_type, string_type, class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "./Aimtec.D365.Entity.Types.js";
import { getSlice, findIndex, map, reduce, filter, distinct, sortBy, tryFind, find, fold, singleton, empty, append, last, length } from "../../.fable/fable-library.3.0.0/List.js";
import { comparePrimitives, safeHash, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";

export class Pbs extends Record {
    constructor(Id, Name, NameOnly, Code, PbsCode, Description, DescriptionCustomer, SortKey, Level, ModifiedOn, ModifiedBy, Role, ProjectService, ProjectServiceModule, AssignedToCustomer, AssignedTo, ParentPbsId, OpenRequestsCount, TotalRequestsCount) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.NameOnly = NameOnly;
        this.Code = Code;
        this.PbsCode = PbsCode;
        this.Description = Description;
        this.DescriptionCustomer = DescriptionCustomer;
        this.SortKey = SortKey;
        this.Level = (Level | 0);
        this.ModifiedOn = ModifiedOn;
        this.ModifiedBy = ModifiedBy;
        this.Role = Role;
        this.ProjectService = ProjectService;
        this.ProjectServiceModule = ProjectServiceModule;
        this.AssignedToCustomer = AssignedToCustomer;
        this.AssignedTo = AssignedTo;
        this.ParentPbsId = ParentPbsId;
        this.OpenRequestsCount = (OpenRequestsCount | 0);
        this.TotalRequestsCount = (TotalRequestsCount | 0);
    }
}

export function Pbs$reflection() {
    return record_type("Aimtec.D365.Pbs.Pbs", [], Pbs, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["NameOnly", string_type], ["Code", string_type], ["PbsCode", string_type], ["Description", string_type], ["DescriptionCustomer", option_type(string_type)], ["SortKey", string_type], ["Level", int32_type], ["ModifiedOn", string_type], ["ModifiedBy", option_type(XrmEntityReference$reflection())], ["Role", option_type(XrmEntityReference$reflection())], ["ProjectService", option_type(XrmEntityReference$reflection())], ["ProjectServiceModule", option_type(XrmEntityReference$reflection())], ["AssignedToCustomer", option_type(XrmEntityReference$reflection())], ["AssignedTo", option_type(XrmEntityReference$reflection())], ["ParentPbsId", option_type(class_type("System.Guid"))], ["OpenRequestsCount", int32_type], ["TotalRequestsCount", int32_type]]);
}

export class PbsTranslation extends Record {
    constructor(Id, Name, NameOnly, PbsCode, Description, PbsId, ModifiedOn, ModifiedBy) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.NameOnly = NameOnly;
        this.PbsCode = PbsCode;
        this.Description = Description;
        this.PbsId = PbsId;
        this.ModifiedOn = ModifiedOn;
        this.ModifiedBy = ModifiedBy;
    }
}

export function PbsTranslation$reflection() {
    return record_type("Aimtec.D365.Pbs.PbsTranslation", [], PbsTranslation, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["NameOnly", string_type], ["PbsCode", string_type], ["Description", string_type], ["PbsId", class_type("System.Guid")], ["ModifiedOn", string_type], ["ModifiedBy", string_type]]);
}

export class CreatePbsTranslationPostParams extends Record {
    constructor(ProjectId, ParentPbsId, LanguageId) {
        super();
        this.ProjectId = ProjectId;
        this.ParentPbsId = ParentPbsId;
        this.LanguageId = LanguageId;
    }
}

export function CreatePbsTranslationPostParams$reflection() {
    return record_type("Aimtec.D365.Pbs.CreatePbsTranslationPostParams", [], CreatePbsTranslationPostParams, () => [["ProjectId", class_type("System.Guid")], ["ParentPbsId", class_type("System.Guid")], ["LanguageId", class_type("System.Guid")]]);
}

export class PbsMenuItem extends Record {
    constructor(Id, Level, Name, Code, OpenRequestsCount, TotalRequestsCount) {
        super();
        this.Id = Id;
        this.Level = (Level | 0);
        this.Name = Name;
        this.Code = Code;
        this.OpenRequestsCount = (OpenRequestsCount | 0);
        this.TotalRequestsCount = (TotalRequestsCount | 0);
    }
}

export function PbsMenuItem$reflection() {
    return record_type("Aimtec.D365.Pbs.PbsMenuItem", [], PbsMenuItem, () => [["Id", class_type("System.Guid")], ["Level", int32_type], ["Name", string_type], ["Code", string_type], ["OpenRequestsCount", int32_type], ["TotalRequestsCount", int32_type]]);
}

export function PbsMenuItemModule_fromPbs(pbs) {
    return new PbsMenuItem(pbs.Id, pbs.Level, (pbs.Code + " ") + pbs.NameOnly, pbs.Code, pbs.OpenRequestsCount, pbs.TotalRequestsCount);
}

export class PbsMenu extends Record {
    constructor(Parent, Children) {
        super();
        this.Parent = Parent;
        this.Children = Children;
    }
}

export function PbsMenu$reflection() {
    return record_type("Aimtec.D365.Pbs.PbsMenu", [], PbsMenu, () => [["Parent", PbsMenuItem$reflection()], ["Children", list_type(PbsMenu$reflection())]]);
}

export class PbsToEdit extends Record {
    constructor(Id, Name, Description) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
    }
}

export function PbsToEdit$reflection() {
    return record_type("Aimtec.D365.Pbs.PbsToEdit", [], PbsToEdit, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type]]);
}

export function PbsToEdit_ofPbs_Z1C2CB6B8(pbs) {
    return new PbsToEdit(pbs.Id, pbs.NameOnly, pbs.Description);
}

export function PbsOperations_createMenu(menuItems) {
    const menuFolder = (menuRoot, pbs) => {
        const lastParentMenuNodeForPbs = (itemToAdd_mut, tree_mut) => {
            let node, node_2;
            lastParentMenuNodeForPbs:
            while (true) {
                const itemToAdd = itemToAdd_mut, tree = tree_mut;
                if (node = tree, node.Parent.Level === (itemToAdd.Level - 1)) {
                    const node_1 = tree;
                    return node_1;
                }
                else if (node_2 = tree, length(node_2.Children) !== 0) {
                    const node_3 = tree;
                    itemToAdd_mut = itemToAdd;
                    tree_mut = last(node_3.Children);
                    continue lastParentMenuNodeForPbs;
                }
                else {
                    return tree;
                }
                break;
            }
        };
        const addMenuNode = (menuRoot_1, pbs_1) => {
            const parent = lastParentMenuNodeForPbs(pbs_1, menuRoot_1);
            parent.Children = append(parent.Children, singleton(new PbsMenu(pbs_1, empty())));
            return menuRoot_1;
        };
        return addMenuNode(menuRoot, pbs);
    };
    const rootMenuElement = new PbsMenu(new PbsMenuItem("00000000-0000-0000-0000-000000000000", 0, "root", "root", 0, 0), empty());
    return fold(menuFolder, rootMenuElement, menuItems);
}

export function PbsOperations_createPBSHieararchy(companyProccesses, filteredProcesses) {
    const createHierarchyList = (accummulator_mut, pbs_mut) => {
        createHierarchyList:
        while (true) {
            const accummulator = accummulator_mut, pbs = pbs_mut;
            const matchValue = pbs.ParentPbsId;
            if (matchValue != null) {
                const parentPbsId = matchValue;
                const parentPbs = find((p) => (p.Id === parentPbsId), companyProccesses);
                accummulator_mut = append(accummulator, append(singleton(pbs), singleton(parentPbs)));
                pbs_mut = parentPbs;
                continue createHierarchyList;
            }
            else {
                return append(accummulator, singleton(pbs));
            }
            break;
        }
    };
    const mergeLists = (accummulator_1_mut, rolePBses_mut) => {
        mergeLists:
        while (true) {
            const accummulator_1 = accummulator_1_mut, rolePBses = rolePBses_mut;
            if (rolePBses.tail != null) {
                const tail = rolePBses.tail;
                const head = rolePBses.head;
                const matchValue_1 = head.ParentPbsId;
                if (matchValue_1 != null) {
                    const parentPbsId_1 = matchValue_1;
                    const matchValue_2 = tryFind((p_1) => (p_1.Id === parentPbsId_1), accummulator_1);
                    if (matchValue_2 == null) {
                        accummulator_1_mut = append(accummulator_1, createHierarchyList(empty(), head));
                        rolePBses_mut = tail;
                        continue mergeLists;
                    }
                    else {
                        const p_2 = matchValue_2;
                        accummulator_1_mut = append(accummulator_1, singleton(head));
                        rolePBses_mut = tail;
                        continue mergeLists;
                    }
                }
                else {
                    accummulator_1_mut = append(accummulator_1, singleton(head));
                    rolePBses_mut = tail;
                    continue mergeLists;
                }
            }
            else {
                return accummulator_1;
            }
            break;
        }
    };
    return sortBy((pbs_1) => pbs_1.SortKey, distinct(mergeLists(empty(), filteredProcesses), {
        Equals: equalsSafe,
        GetHashCode: safeHash,
    }), {
        Compare: comparePrimitives,
    });
}

export function PbsOperations_createPbsFamily0(allProjectPbses, processId) {
    const composeProcHierarchy = (accumulator, processId_1) => {
        const matchValue = filter((pbs_1) => (pbs_1.ParentPbsId === processId_1), filter((pbs) => (pbs.ParentPbsId != null), allProjectPbses));
        if (matchValue.tail != null) {
            const t = matchValue.tail;
            const h = matchValue.head;
            const childList = matchValue;
            const accumulated = append(accumulator, childList);
            return reduce(append, map((tupledArg) => {
                const childId = tupledArg[0];
                const children = tupledArg[1];
                return composeProcHierarchy(append(accumulator, children), childId);
            }, map((child) => [child.Id, childList], childList)));
        }
        else {
            return accumulator;
        }
    };
    return composeProcHierarchy(allProjectPbses, processId);
}

export function PbsOperations_createPbsFamily(allProjectPbses, pbs) {
    const indexOfParent = findIndex((p) => (p.Id === pbs.Id), allProjectPbses) | 0;
    const listWithoutAboveElements = getSlice(indexOfParent, void 0, allProjectPbses);
    const indexOfFirstElementThatHasTheSameLevel = findIndex((p_1) => {
        if (p_1.Level === pbs.Level) {
            return p_1.Id !== pbs.Id;
        }
        else {
            return false;
        }
    }, listWithoutAboveElements) | 0;
    const listWithoutBottomElements = getSlice(void 0, indexOfFirstElementThatHasTheSameLevel - 1, listWithoutAboveElements);
    return listWithoutBottomElements;
}

