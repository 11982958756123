import { class_type } from "../fable-library.3.0.0/Reflection.js";
import { bind, some } from "../fable-library.3.0.0/Option.js";
import { getEnumerator } from "../fable-library.3.0.0/Seq.js";

export class OptionBuilder {
    constructor() {
    }
}

export function OptionBuilder$reflection() {
    return class_type("FsToolkit.ErrorHandling.OptionCE.OptionBuilder", void 0, OptionBuilder);
}

export function OptionBuilder_$ctor() {
    return new OptionBuilder();
}

export function OptionBuilder__Return_1505(this$, x) {
    return some(x);
}

export function OptionBuilder__ReturnFrom_6F691636(this$, m) {
    return m;
}

export function OptionBuilder__Bind_Z424BC9ED(this$, m, f) {
    return bind(f, m);
}

export function OptionBuilder__Zero(this$) {
    return OptionBuilder__Return_1505(this$);
}

export function OptionBuilder__Combine_Z424BC9ED(this$, m, f) {
    return bind(f, m);
}

export function OptionBuilder__Delay_FCFD9EF(this$, f) {
    return f;
}

export function OptionBuilder__Run_FCFD9EF(this$, f) {
    return f();
}

export function OptionBuilder__TryWith_Z570AC55B(this$, m, h) {
    try {
        return OptionBuilder__Run_FCFD9EF(this$, m);
    }
    catch (e) {
        return h(e);
    }
}

export function OptionBuilder__TryFinally_33907399(this$, m, compensation) {
    try {
        return OptionBuilder__Run_FCFD9EF(this$, m);
    }
    finally {
        compensation();
    }
}

export function OptionBuilder__Using_Z2726DC0(this$, resource, binder) {
    return OptionBuilder__TryFinally_33907399(this$, () => binder(resource), () => {
        if (!(resource === null)) {
            let copyOfStruct = resource;
            copyOfStruct.Dispose();
        }
    });
}

export function OptionBuilder__While_72012EE3(this$, guard, generator) {
    if (!guard()) {
        return OptionBuilder__Zero(this$);
    }
    else {
        return OptionBuilder__Bind_Z424BC9ED(this$, OptionBuilder__Run_FCFD9EF(this$, generator), () => OptionBuilder__While_72012EE3(this$, guard, generator));
    }
}

export function OptionBuilder__For_Z3E3EAA6E(this$, sequence, binder) {
    let copyOfStruct;
    return OptionBuilder__Using_Z2726DC0(this$, (copyOfStruct = sequence, getEnumerator(copyOfStruct)), (enum$) => OptionBuilder__While_72012EE3(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), OptionBuilder__Delay_FCFD9EF(this$, () => binder(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export const option = OptionBuilder_$ctor();

