import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { createElement } from "react";
import { Interop_reactApi } from "../../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { render } from "../../Preloader/Preloader.js";


export const Render = createElement("div", {
    className: "preloader-container",
    children: Interop_reactApi.Children.toArray([render("")]),
});

