import * as style from "../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../.fable/fable-library.3.0.0/Util.js";
import { find, ofSeq, singleton, ofArray, map, exists } from "../../../.fable/fable-library.3.0.0/List.js";
import { FSharpChoice$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { PbsListMsg } from "./Types.js";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { toString } from "../../../.fable/fable-library.3.0.0/Types.js";
import { CKEditorComponent, EditorProps } from "../CKEDITOR/View.js";
import { render as render_1 } from "../ErrorPage/ErrorPage.js";
import { append, singleton as singleton_1, delay } from "../../../.fable/fable-library.3.0.0/Seq.js";
import { interpolate, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { render as render_2 } from "../Preloader/Preloader.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

function pbsHeading(pbs, props) {
    const matchValue = pbs.Level | 0;
    switch (matchValue) {
        case 1: {
            return createElement("h1", createObj(props));
        }
        case 2: {
            return createElement("h2", createObj(props));
        }
        case 3: {
            return createElement("h3", createObj(props));
        }
        case 4: {
            return createElement("h4", createObj(props));
        }
        default: {
            return createElement("h5", createObj(props));
        }
    }
}

function pbsDepthClassName(pbs) {
    const matchValue = pbs.Level | 0;
    switch (matchValue) {
        case 1: {
            return "process-window-mobile process-window-depth-1";
        }
        case 2: {
            return "process-window-mobile process-window-depth-2";
        }
        case 3: {
            return "process-window-mobile process-window-depth-3";
        }
        case 4: {
            return "process-window-mobile process-window-depth-4";
        }
        default: {
            return "process-window-mobile process-window-depth-5";
        }
    }
}

export function $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(state, pbsId) {
    if (exists((pbs) => (pbs.Id === pbsId), state.PbsesEdited)) {
        return new FSharpChoice$2(1, void 0);
    }
    else if (exists((pbs_1) => (pbs_1.Id === pbsId), state.CustomerDescriptionsEdited)) {
        return new FSharpChoice$2(2, void 0);
    }
    else if (exists((pbs_2) => (pbs_2.Id === pbsId), state.PbsesSaved)) {
        return new FSharpChoice$2(3, void 0);
    }
    else if (exists((pbs_3) => (pbs_3.Id === pbsId), state.CustomerDescriptionsSaved)) {
        return new FSharpChoice$2(4, void 0);
    }
    else if (exists((tupledArg) => {
        const pbs_4 = tupledArg[0];
        return pbs_4.Id === pbsId;
    }, state.PbsesError)) {
        return new FSharpChoice$2(5, void 0);
    }
    else if (exists((tupledArg_1) => {
        const pbs_5 = tupledArg_1[0];
        return pbs_5.Id === pbsId;
    }, state.CustomerDescriptionError)) {
        return new FSharpChoice$2(6, void 0);
    }
    else {
        return new FSharpChoice$2(0, void 0);
    }
}

export function render(renderInputProps) {
    const onServiceDeskStart = renderInputProps.onServiceDeskStart;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const matchValue = state.ComponentState;
    switch (matchValue.tag) {
        case 2: {
            const errorMessages = matchValue.fields[0];
            return react.createElement(react.Fragment, {}, ...map((msg) => createElement("h1", {
                children: [msg],
            }), errorMessages));
        }
        case 0: {
            const matchValue_1 = state.Pbses;
            if (matchValue_1.tail != null) {
                const t = matchValue_1.tail;
                const h = matchValue_1.head;
                const onProcessDeselect = () => {
                };
                const pbsDisplay = (pbs) => {
                    const onPbsEdit = createElement("button", {
                        className: "btn btn-secondary",
                        children: "Edit Pbs",
                        onClick: (_arg1) => {
                            dispatch(new PbsListMsg(19, pbs.Id));
                        },
                    });
                    const onPbsEditCustomer = createElement("button", {
                        className: "btn btn-secondary",
                        children: "Add Description",
                        onClick: (_arg2) => {
                            dispatch(new PbsListMsg(13, pbs.Id));
                        },
                    });
                    const isSelectedClassName = (id) => {
                        const matchValue_2 = state.SelectedPbs;
                        if (matchValue_2 == null) {
                            return "";
                        }
                        else {
                            const pId = matchValue_2;
                            if (id === pId.Id) {
                                return "selected-process";
                            }
                            else {
                                return "";
                            }
                        }
                    };
                    const serviceDeskButton = (pbsId) => {
                        if (onServiceDeskStart == null) {
                            return react.createElement(react.Fragment, {});
                        }
                        else {
                            const osdsFn = onServiceDeskStart;
                            return createElement("button", {
                                className: "btn btn-warning",
                                onClick: (_arg3) => {
                                    osdsFn(pbsId);
                                },
                                children: "Service Desk",
                            });
                        }
                    };
                    let pbsControlButtons;
                    const matchValue_3 = state.AccessToFunctions;
                    switch (matchValue_3.tag) {
                        case 1: {
                            pbsControlButtons = createElement("div", {
                                className: "btn-group",
                                children: Interop_reactApi.Children.toArray([serviceDeskButton(pbs.Id)]),
                            });
                            break;
                        }
                        case 2: {
                            pbsControlButtons = createElement("div", {
                                className: "btn-group position-absolute top-0 end-0",
                                children: Interop_reactApi.Children.toArray([serviceDeskButton(pbs.Id), onPbsEdit]),
                            });
                            break;
                        }
                        case 3: {
                            pbsControlButtons = createElement("div", {
                                className: "btn-group",
                                children: Interop_reactApi.Children.toArray([serviceDeskButton(pbs.Id), onPbsEditCustomer]),
                            });
                            break;
                        }
                        case 4: {
                            pbsControlButtons = createElement("div", {
                                className: "btn-group",
                                children: Interop_reactApi.Children.toArray([serviceDeskButton(pbs.Id), onPbsEdit, onPbsEditCustomer]),
                            });
                            break;
                        }
                        default: {
                            pbsControlButtons = react.createElement(react.Fragment, {});
                        }
                    }
                    return createElement("div", {
                        className: isSelectedClassName(pbs.Id),
                        id: pbs.Id,
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "",
                                children: Interop_reactApi.Children.toArray([pbsHeading(pbs, ofArray([["style", {
                                    textDecoration: "underline",
                                }], ["className", "process-header"], ["children", pbs.NameOnly], ["onClick", (_arg4) => {
                                    let tupledArg;
                                    dispatch((tupledArg = [state.SelectedProjectId, new XrmEntityReference(pbs.Id, pbs.Name)], new PbsListMsg(4, tupledArg[0], tupledArg[1])));
                                }]]))]),
                            })]),
                        }), createElement("p", {
                            dangerouslySetInnerHTML: {
                                __html: pbs.Description,
                            },
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsControlButtons]),
                        })]),
                    });
                };
                const cancelPbsEditBtn = (pbsId_1) => createElement("button", {
                    className: "btn btn-warning",
                    children: "Cancel",
                    onClick: (_arg5) => {
                        dispatch(new PbsListMsg(20, pbsId_1));
                    },
                });
                const savePbsEditBtn = (pbsId_2) => createElement("button", {
                    className: "btn btn-success",
                    children: "Save",
                    onClick: (_arg6) => {
                        let tupledArg_1;
                        dispatch((tupledArg_1 = [false, pbsId_2], new PbsListMsg(24, tupledArg_1[0], tupledArg_1[1])));
                    },
                });
                const savePbsAndCloseEditBtn = (pbsId_3) => createElement("button", {
                    className: "btn btn-success",
                    children: "Save",
                    onClick: (_arg7) => {
                        let tupledArg_2;
                        dispatch((tupledArg_2 = [true, pbsId_3], new PbsListMsg(24, tupledArg_2[0], tupledArg_2[1])));
                    },
                });
                const cancelCustomerDescriptionEdit = (pbsId_4) => createElement("button", {
                    className: "btn btn-warning",
                    children: "Cancel",
                    onClick: (_arg8) => {
                        dispatch(new PbsListMsg(14, pbsId_4));
                    },
                });
                const saveCutomerDescription = (pbsId_5) => createElement("button", {
                    className: "btn btn-success",
                    children: "Save",
                    onClick: (_arg9) => {
                        dispatch(new PbsListMsg(16, pbsId_5));
                    },
                });
                const resetCustomerDescription = (pbsId_6) => createElement("button", {
                    className: "btn btn-danger",
                    children: "Erase Description",
                    onClick: (_arg10) => {
                        dispatch(new PbsListMsg(18, pbsId_6));
                    },
                });
                const editPbsNameInput = (onNameChange, pbs_1) => createElement("input", {
                    className: "form-control",
                    type: "Text",
                    defaultValue: pbs_1.Name,
                    onChange: (e) => {
                        onNameChange(toString(op_Dynamic()(e.currentTarget)("value")));
                    },
                });
                const descriptionCustomerContainer = (d) => createElement("div", {
                    className: "description-customer-container",
                    children: Interop_reactApi.Children.toArray([createElement("p", {
                        dangerouslySetInnerHTML: {
                            __html: d,
                        },
                    })]),
                });
                const ckeditorCmp = (onDescriptionChange, pbs_2) => {
                    const editorProps = new EditorProps(pbs_2.Id, "pbs_ckeditor", pbs_2.Description, (editorData) => {
                        onDescriptionChange(editorData);
                    }, pbs_2.Id);
                    return react.createElement(CKEditorComponent, editorProps);
                };
                const ckeditorCustomerCmp = (onDescriptionChange_1, pbs_3) => {
                    let matchValue_4, d_1;
                    const editorProps_1 = new EditorProps(pbs_3.Id, "pbs_ckeditor", (matchValue_4 = pbs_3.DescriptionCustomer, (matchValue_4 == null) ? "" : (d_1 = matchValue_4, d_1)), (editorData_1) => {
                        onDescriptionChange_1(editorData_1);
                    }, pbs_3.Id);
                    return react.createElement(CKEditorComponent, editorProps_1);
                };
                const errorCmp = (errorMessage, resetFunction) => render_1(singleton(errorMessage), resetFunction);
                return createElement("div", createObj(ofSeq(delay(() => {
                    let pbsesOrFilteredPbses;
                    const matchValue_5 = state.FilteredPbses;
                    if (matchValue_5.tail != null) {
                        const t_1 = matchValue_5.tail;
                        const h_1 = matchValue_5.head;
                        pbsesOrFilteredPbses = map((tuple_1) => tuple_1[0], state.FilteredPbses);
                    }
                    else {
                        pbsesOrFilteredPbses = map((tuple) => tuple[0], state.Pbses);
                    }
                    return singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((pbs_4) => {
                        const matchValue_6 = [state, pbs_4.Id];
                        let pattern_matching_result;
                        const activePatternResult15552 = $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(matchValue_6[0], matchValue_6[1]);
                        if (activePatternResult15552.tag === 1) {
                            pattern_matching_result = 1;
                        }
                        else if (activePatternResult15552.tag === 2) {
                            pattern_matching_result = 2;
                        }
                        else if (activePatternResult15552.tag === 3) {
                            pattern_matching_result = 3;
                        }
                        else if (activePatternResult15552.tag === 4) {
                            pattern_matching_result = 3;
                        }
                        else if (activePatternResult15552.tag === 5) {
                            pattern_matching_result = 4;
                        }
                        else if (activePatternResult15552.tag === 6) {
                            pattern_matching_result = 5;
                        }
                        else {
                            pattern_matching_result = 0;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                return createElement("div", {
                                    className: pbsDepthClassName(pbs_4),
                                    children: Interop_reactApi.Children.toArray(Array.from(ofSeq(delay(() => append(singleton_1(pbsDisplay(pbs_4)), delay(() => {
                                        const matchValue_7 = pbs_4.DescriptionCustomer;
                                        if (matchValue_7 == null) {
                                            return singleton_1(react.createElement(react.Fragment, {}));
                                        }
                                        else {
                                            const d_2 = matchValue_7;
                                            return singleton_1(descriptionCustomerContainer(d_2));
                                        }
                                    })))))),
                                });
                            }
                            case 1: {
                                return createElement("div", {
                                    id: toText(interpolate("%A%P()", [pbs_4.Id])),
                                    className: pbsDepthClassName(pbs_4) + " edit-border",
                                    children: Interop_reactApi.Children.toArray([createElement("div", {
                                        className: "alert alert-info",
                                        children: toText(interpolate("Edit Process: %P()", [pbs_4.Name])),
                                    }), createElement("br", {}), createElement("div", {
                                        className: "row",
                                        children: Interop_reactApi.Children.toArray([createElement("div", {
                                            className: "btn-group",
                                            children: Interop_reactApi.Children.toArray([cancelPbsEditBtn(pbs_4.Id), savePbsEditBtn(pbs_4.Id), savePbsAndCloseEditBtn(pbs_4.Id)]),
                                        })]),
                                    }), editPbsNameInput((name_56) => {
                                        let tupledArg_3;
                                        dispatch((tupledArg_3 = [pbs_4.Id, name_56], new PbsListMsg(21, tupledArg_3[0], tupledArg_3[1])));
                                    }, pbs_4), ckeditorCmp((description) => {
                                        let tupledArg_4;
                                        dispatch((tupledArg_4 = [pbs_4.Id, description], new PbsListMsg(22, tupledArg_4[0], tupledArg_4[1])));
                                    }, pbs_4), createElement("hr", {})]),
                                });
                            }
                            case 2: {
                                return react.createElement(react.Fragment, {}, createElement("div", {
                                    className: toText(interpolate("%P() row edit-border", [pbsDepthClassName(pbs_4)])),
                                    children: Interop_reactApi.Children.toArray([createElement("div", {
                                        className: "row",
                                        children: Interop_reactApi.Children.toArray([createElement("p", {
                                            dangerouslySetInnerHTML: {
                                                __html: pbs_4.Description,
                                            },
                                        }), createElement("div", {
                                            className: "alert alert-info",
                                            children: toText(interpolate("Add your description to: %P()", [pbs_4.Name])),
                                        }), createElement("div", {
                                            className: "btn-group",
                                            children: Interop_reactApi.Children.toArray([cancelCustomerDescriptionEdit(pbs_4.Id), saveCutomerDescription(pbs_4.Id), resetCustomerDescription(pbs_4.Id)]),
                                        }), ckeditorCustomerCmp((description_1) => {
                                            let tupledArg_5;
                                            dispatch((tupledArg_5 = [pbs_4.Id, description_1], new PbsListMsg(15, tupledArg_5[0], tupledArg_5[1])));
                                        }, pbs_4)]),
                                    })]),
                                }));
                            }
                            case 3: {
                                return createElement("div", {
                                    className: "preloader-container",
                                    children: Interop_reactApi.Children.toArray([render_2("")]),
                                });
                            }
                            case 4: {
                                const patternInput = find((tupledArg_6) => {
                                    const p = tupledArg_6[0];
                                    const errMsg = tupledArg_6[1];
                                    return p.Id === pbs_4.Id;
                                }, state.PbsesError);
                                const pbs_6 = patternInput[0];
                                const errorMessage_1 = patternInput[1];
                                return react.createElement(react.Fragment, {}, errorCmp(errorMessage_1, () => {
                                    dispatch(new PbsListMsg(32, pbs_6.Id));
                                }), createElement("br", {}));
                            }
                            case 5: {
                                const patternInput_1 = find((tupledArg_7) => {
                                    const p_1 = tupledArg_7[0];
                                    const errMsg_1 = tupledArg_7[1];
                                    return p_1.Id === pbs_4.Id;
                                }, state.PbsesError);
                                const pbs_7 = patternInput_1[0];
                                const errorMessage_2 = patternInput_1[1];
                                return react.createElement(react.Fragment, {}, errorCmp(errorMessage_2, () => {
                                    dispatch(new PbsListMsg(34, pbs_7.Id));
                                }), createElement("br", {}));
                            }
                        }
                    }, pbsesOrFilteredPbses)))]);
                }))));
            }
            else {
                return createElement("h1", {
                    children: ["No Pbses loaded."],
                });
            }
        }
        default: {
            return render_2("");
        }
    }
}

