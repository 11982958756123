import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { record_type, string_type, class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class XrmEntityReference extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function XrmEntityReference$reflection() {
    return record_type("Aimtec.D365.Entity.XrmEntityReference", [], XrmEntityReference, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

