import * as style from "../../../../../../Aimtec.Components.React/Components/Preloader/style.scss";
import { createElement } from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";


export function render(message) {
    return createElement("div", {
        className: "preloader",
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: "img/cloud-orange.svg",
        })]),
    });
}

