import { ofSeq, filter, empty } from "../../../.fable/fable-library.3.0.0/List.js";
import { CookieSessionState, CookieSessionMsg, ExternalMsg } from "./Types.js";
import { fetchSession } from "./Rest.js";
import { Cmd_none, Cmd_OfFunc_result } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ComponentState as ComponentState_1 } from "../../Types.js";
import { join } from "../../../.fable/fable-library.3.0.0/String.js";

export function update(msg, state) {
    switch (msg.tag) {
        case 3: {
            return [state, empty(), new ExternalMsg(0)];
        }
        case 0: {
            return [state, fetchSession(state.CookieSessionAddresses.FetchSessionAddress), new ExternalMsg(0)];
        }
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                const msg_1 = result.fields[0];
                return [state, Cmd_OfFunc_result(new CookieSessionMsg(4, new Error(msg_1))), new ExternalMsg(0)];
            }
            else {
                const session = result.fields[0];
                return [new CookieSessionState(state.CookieSessionAddresses, new ComponentState_1(0), session), empty(), new ExternalMsg(1, session)];
            }
        }
        case 4: {
            const exn = msg.fields[0];
            return [state, Cmd_OfFunc_result(new CookieSessionMsg(2)), new ExternalMsg(0)];
        }
        default: {
            const removeHasbang = (text) => join("", filter((c) => (c !== "#"), ofSeq(text.split(""))));
            window.location.href = ((state.CookieSessionAddresses.ServiceDeskAddress + "Account/Login?ReturnUrl=") + removeHasbang(window.location.href));
            return [state, Cmd_none(), new ExternalMsg(0)];
        }
    }
}

