import { postCreatePbsTranslation, postUpdatePbsTranslation, postUpdatePbs, postResetCustomerDescription, postUpdatePbsCustomerDescription, fetchTranslations, fetchPbses } from "./RestCookie.js";
import { loginRedirect, postCreatePbsTranslation as postCreatePbsTranslation_1, postUpdatePbsTranslation as postUpdatePbsTranslation_1, postUpdatePbs as postUpdatePbs_1, postResetCustomerDescription as postResetCustomerDescription_1, postUpdatePbsCustomerDescription as postUpdatePbsCustomerDescription_1, fetchTranslations as fetchTranslations_1, fetchPbses as fetchPbses_1 } from "./RestMsal.js";
import { arrayHash, equalArrays, safeHash, equalsSafe, equals, curry } from "../../../.fable/fable-library.3.0.0/Util.js";
import { Cmd_OfFunc_result, Cmd_none } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsListMsg, PbsListComponentState, PbsListExtraMsg } from "./Types.js";
import { defaultArg, map } from "../../../.fable/fable-library.3.0.0/Option.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { find, append, distinct, tryFind, filter, map as map_1, singleton, empty } from "../../../.fable/fable-library.3.0.0/List.js";
import { ComponentState as ComponentState_8 } from "../../Types.js";
import { option as option_33, OptionBuilder__Return_1505, OptionBuilder__Bind_Z424BC9ED, OptionBuilder__Delay_FCFD9EF, OptionBuilder__Run_FCFD9EF } from "../../../.fable/FsToolkit.ErrorHandling.2.0.0/OptionCE.fs.js";
import { PbsToEdit_ofPbs_Z1C2CB6B8, CreatePbsTranslationPostParams, PbsToEdit } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { List_remove, List_add, List_replace } from "../../../AIM/Aimtec.D365.Domain/CollectionExtensions.js";
import { validatePbsDescription } from "./PbsListJsInterop.js";

export function update(pcio, msg, state) {
    let tupledArg_9, tupledArg_16, tupledArg_21, tupledArg_17, tupledArg_26, msg_15;
    let fetchPbsesFunction;
    if (pcio == null) {
        fetchPbsesFunction = ((address_1) => ((projectId_1) => fetchPbses(address_1, projectId_1)));
    }
    else {
        const pci = pcio;
        fetchPbsesFunction = ((address) => ((projectId) => fetchPbses_1(pci, address, projectId)));
    }
    let fetchTranslationsFunction;
    if (pcio == null) {
        fetchTranslationsFunction = ((address_3) => ((projectId_3) => ((languageId_1) => fetchTranslations(curry(2, address_3), projectId_3, languageId_1))));
    }
    else {
        const pci_1 = pcio;
        fetchTranslationsFunction = ((address_2) => ((projectId_2) => ((languageId) => fetchTranslations_1(pci_1, address_2, projectId_2, languageId))));
    }
    let postUpdatePbsCustomerDescriptionFunction;
    if (pcio == null) {
        postUpdatePbsCustomerDescriptionFunction = ((address_5) => ((pbsToUpdate_1) => postUpdatePbsCustomerDescription(address_5, pbsToUpdate_1)));
    }
    else {
        const pci_2 = pcio;
        postUpdatePbsCustomerDescriptionFunction = ((address_4) => ((pbsToUpdate) => postUpdatePbsCustomerDescription_1(pci_2, address_4, pbsToUpdate)));
    }
    let postResetCustomerDescriptionFunction;
    if (pcio == null) {
        postResetCustomerDescriptionFunction = ((address_7) => ((pbsToUpdate_3) => postResetCustomerDescription(address_7, pbsToUpdate_3)));
    }
    else {
        const pci_3 = pcio;
        postResetCustomerDescriptionFunction = ((address_6) => ((pbsToUpdate_2) => postResetCustomerDescription_1(pci_3, address_6, pbsToUpdate_2)));
    }
    let postUpdatePbsFunction;
    if (pcio == null) {
        postUpdatePbsFunction = ((address_9) => ((saveAndClose_1) => ((pbsToUpdate_5) => postUpdatePbs(address_9, saveAndClose_1, pbsToUpdate_5))));
    }
    else {
        const pci_4 = pcio;
        postUpdatePbsFunction = ((address_8) => ((saveAndClose) => ((pbsToUpdate_4) => postUpdatePbs_1(pci_4, address_8, saveAndClose, pbsToUpdate_4))));
    }
    let postUpdatePbsTranslationFunction;
    if (pcio == null) {
        postUpdatePbsTranslationFunction = ((address_11) => ((saveAndClose_3) => ((pbsToUpdate_7) => postUpdatePbsTranslation(address_11, saveAndClose_3, pbsToUpdate_7))));
    }
    else {
        const pci_5 = pcio;
        postUpdatePbsTranslationFunction = ((address_10) => ((saveAndClose_2) => ((pbsToUpdate_6) => postUpdatePbsTranslation_1(pci_5, address_10, saveAndClose_2, pbsToUpdate_6))));
    }
    let postTranslationCreatePostRequestProps;
    if (pcio == null) {
        postTranslationCreatePostRequestProps = ((address_13) => ((createTranslationParams_1) => postCreatePbsTranslation(address_13, createTranslationParams_1)));
    }
    else {
        const pci_6 = pcio;
        postTranslationCreatePostRequestProps = ((address_12) => ((createTranslationParams) => postCreatePbsTranslation_1(pci_6, address_12, createTranslationParams)));
    }
    let loginRedirectFn;
    if (pcio == null) {
        loginRedirectFn = Cmd_none();
    }
    else {
        const pci_7 = pcio;
        loginRedirectFn = loginRedirect(pci_7);
    }
    switch (msg.tag) {
        case 1: {
            const response = msg.fields[0];
            return [state, Cmd_none(), new PbsListExtraMsg(0)];
        }
        case 2: {
            const selectedPbsId = msg.fields[1];
            const projectId_4 = msg.fields[0];
            const selectedPbsReference = map((id) => (new XrmEntityReference(id, "")), selectedPbsId);
            const matchValue = state.SelectedProjectId;
            if (matchValue == null) {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, selectedPbsReference, new XrmEntityReference(projectId_4, ""), state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchPbsesFunction(state.RestAddresses.FetchPbsesAddress)(projectId_4), new PbsListExtraMsg(0)];
            }
            else {
                const currentProjectId = matchValue;
                if (currentProjectId.Id === projectId_4) {
                    return [state, empty(), new PbsListExtraMsg(0)];
                }
                else {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(1), state.Pbses, selectedPbsReference, new XrmEntityReference(projectId_4, ""), state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchPbsesFunction(state.RestAddresses.FetchPbsesAddress)(projectId_4), new PbsListExtraMsg(0)];
                }
            }
        }
        case 3: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                const message = result.fields[0];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(2, singleton(message)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
            else {
                const pbses = result.fields[0][0];
                const languges = result.fields[0][1];
                const pbsesWithNones = map_1((p) => [p, void 0], pbses);
                let scrollIntoPbsCmdO;
                const matchValue_2 = state.SelectedPbs;
                if (matchValue_2 != null) {
                    const spr = matchValue_2;
                    scrollIntoPbsCmdO = Cmd_OfFunc_result(new PbsListMsg(5, spr.Id));
                }
                else {
                    scrollIntoPbsCmdO = Cmd_none();
                }
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(0), pbsesWithNones, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, languges, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), scrollIntoPbsCmdO, new PbsListExtraMsg(0)];
            }
        }
        case 4: {
            const projId = msg.fields[0];
            const pbsId = msg.fields[1];
            let extraMessageOrNoop;
            const matchValue_3 = state.SelectedPbs;
            if (matchValue_3 != null) {
                const selectedId = matchValue_3;
                if (selectedId.Id === pbsId.Id) {
                    extraMessageOrNoop = (new PbsListExtraMsg(0));
                }
                else {
                    const tupledArg_1 = [projId, pbsId];
                    extraMessageOrNoop = (new PbsListExtraMsg(1, tupledArg_1[0], tupledArg_1[1]));
                }
            }
            else {
                const tupledArg = [projId, pbsId];
                extraMessageOrNoop = (new PbsListExtraMsg(1, tupledArg[0], tupledArg[1]));
            }
            const scrollIntoPbs = Cmd_OfFunc_result(new PbsListMsg(5, pbsId.Id));
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, pbsId, projId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), scrollIntoPbs, extraMessageOrNoop];
        }
        case 5: {
            const pbsId_1 = msg.fields[0];
            const element = document.getElementById(pbsId_1);
            if (!equals(element, null)) {
                window.scroll(0, element.offsetTop - 80);
            }
            return [state, Cmd_none(), new PbsListExtraMsg(0)];
        }
        case 6: {
            const projectId_5 = msg.fields[0];
            const languageId_2 = msg.fields[1];
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(1), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), fetchTranslationsFunction(state.RestAddresses.FetchTranslationsAddress)(projectId_5)(languageId_2), new PbsListExtraMsg(0)];
        }
        case 7: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 0) {
                const pbsTraList = result_1.fields[0][0];
                const languageId_3 = result_1.fields[0][1];
                const fakeLanguageReference = new XrmEntityReference(languageId_3, "fake reference");
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(0), pbsTraList, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, fakeLanguageReference, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
            }
            else {
                const message_1 = result_1.fields[0];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(2, singleton(message_1)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
        }
        case 8: {
            const updatedPbses = map_1((tupledArg_2) => {
                const p_1 = tupledArg_2[0];
                const t = tupledArg_2[1];
                return [p_1, void 0];
            }, state.Pbses);
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, updatedPbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, void 0, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 9: {
            const accessLevel = msg.fields[0];
            return [new PbsListComponentState(state.RestAddresses, accessLevel, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 10: {
            const serviceId = msg.fields[0];
            return [state, empty(), new PbsListExtraMsg(0)];
        }
        case 11: {
            const serviceModuleReference = msg.fields[0];
            const serviceModuleId = serviceModuleReference.Id;
            const matchValue_4 = state.Pbses;
            if (matchValue_4.tail == null) {
                return [state, empty(), new PbsListExtraMsg(0)];
            }
            else {
                const t_1 = matchValue_4.tail;
                const h = matchValue_4.head;
                const filteredPbses = filter((tupledArg_4) => {
                    const p_3 = tupledArg_4[0];
                    return p_3.ProjectServiceModule.Id === serviceModuleId;
                }, filter((tupledArg_3) => {
                    const p_2 = tupledArg_3[0];
                    return p_2.ProjectServiceModule != null;
                }, state.Pbses));
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, filteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
            }
        }
        case 12: {
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, empty(), state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 21: {
            const pbsId_2 = msg.fields[0];
            const name = msg.fields[1];
            return defaultArg(map((l) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs) => (pbs.Id === pbsId_2), state.PbsesEdited), (_arg1_1) => {
                const pbsToChangeName = _arg1_1;
                const changedPbs = new PbsToEdit(pbsToChangeName.Id, name, pbsToChangeName.Description);
                const changedPbses = List_replace((p_4) => (p_4.Id === pbsId_2), (p_5) => changedPbs, state.PbsesEdited);
                return OptionBuilder__Return_1505(option_33, changedPbses);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 22: {
            const pbsId_3 = msg.fields[0];
            const description = msg.fields[1];
            return defaultArg(map((l_1) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_1, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_1) => (pbs_1.Id === pbsId_3), state.PbsesEdited), (_arg2_1) => {
                const pbsToChangeName_1 = _arg2_1;
                const changedPbs_1 = new PbsToEdit(pbsToChangeName_1.Id, pbsToChangeName_1.Name, description);
                const changedPbses_1 = List_replace((p_6) => (p_6.Id === pbsId_3), (p_7) => changedPbs_1, state.PbsesEdited);
                return OptionBuilder__Return_1505(option_33, changedPbses_1);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 13: {
            const pbsId_4 = msg.fields[0];
            return defaultArg(map((l_2) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_2, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => {
                const pbses_1 = state.Pbses;
                return OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_2) => (pbs_2.Id === pbsId_4), map_1((tuple) => tuple[0], pbses_1)), (_arg3) => {
                    const pbs_3 = _arg3;
                    const pbsToEdit = new PbsToEdit(pbs_3.Id, pbs_3.NameOnly, pbs_3.Description);
                    const customerDescriptionsEdited = distinct(List_add(pbsToEdit, state.CustomerDescriptionsEdited), {
                        Equals: equalsSafe,
                        GetHashCode: safeHash,
                    });
                    return OptionBuilder__Return_1505(option_33, customerDescriptionsEdited);
                });
            }))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 14: {
            const pbsId_5 = msg.fields[0];
            return defaultArg(map((l_3) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_3, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_4) => (pbs_4.Id === pbsId_5), state.CustomerDescriptionsEdited), (_arg4) => {
                const pbsToEdit_1 = _arg4;
                const customerDescriptionsEdited_1 = distinct(List_remove(pbsToEdit_1, state.CustomerDescriptionsEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, customerDescriptionsEdited_1);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 15: {
            const text = msg.fields[1];
            const pbsId_6 = msg.fields[0];
            return defaultArg(map((l_4) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, l_4, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_5) => (pbs_5.Id === pbsId_6), state.CustomerDescriptionsEdited), (_arg5) => {
                const pbsToChangeName_2 = _arg5;
                const changedPbs_2 = new PbsToEdit(pbsToChangeName_2.Id, pbsToChangeName_2.Name, text);
                const changedPbses_2 = List_replace((p_8) => (p_8.Id === pbsId_6), (p_9) => changedPbs_2, state.CustomerDescriptionsEdited);
                return OptionBuilder__Return_1505(option_33, changedPbses_2);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 16: {
            const pbsId_7 = msg.fields[0];
            return defaultArg(map((tupledArg_5) => {
                const el = tupledArg_5[0];
                const sl = tupledArg_5[1];
                const p_10 = tupledArg_5[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, el, sl, state.CustomerDescriptionError), postUpdatePbsCustomerDescriptionFunction(state.RestAddresses.PostResetPbsCustomerDescriptionAddress)(p_10), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_6) => (pbs_6.Id === pbsId_7), state.CustomerDescriptionsEdited), (_arg6) => {
                const pbsToEdit_2 = _arg6;
                const customerDescriptionsEdited_2 = distinct(List_remove(pbsToEdit_2, state.CustomerDescriptionsEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const customerDescriptionsSaved = distinct(List_add(pbsToEdit_2, state.CustomerDescriptionsSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, [customerDescriptionsEdited_2, customerDescriptionsSaved, pbsToEdit_2]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 17: {
            const result_2 = msg.fields[0];
            if (result_2.tag === 1) {
                const pbsId_8 = result_2.fields[0][0];
                const errorMessage = result_2.fields[0][1];
                return [state, Cmd_OfFunc_result((tupledArg_9 = [pbsId_8, errorMessage], new PbsListMsg(31, tupledArg_9[0], tupledArg_9[1]))), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack = result_2.fields[0];
                return defaultArg(map((tupledArg_8) => {
                    const sl_1 = tupledArg_8[0];
                    const p_13 = tupledArg_8[1];
                    const rp = tupledArg_8[2];
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, sl_1, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_7) => (pbs_7.Id === pbsBack.Id), state.CustomerDescriptionsSaved), (_arg7) => {
                    const pbsToEdit_3 = _arg7;
                    const customerDescriptionsSaved_1 = distinct(List_remove(pbsToEdit_3, state.CustomerDescriptionsSaved), {
                        Equals: equalsSafe,
                        GetHashCode: safeHash,
                    });
                    const pbses_2 = state.Pbses;
                    const replacedPbses = List_replace((tupledArg_6) => {
                        const p_11 = tupledArg_6[0];
                        return p_11.Id === pbsBack.Id;
                    }, (tupledArg_7) => {
                        const p_12 = tupledArg_7[0];
                        const t_2 = tupledArg_7[1];
                        return [pbsBack, t_2];
                    }, pbses_2);
                    return OptionBuilder__Return_1505(option_33, [customerDescriptionsSaved_1, pbsToEdit_3, replacedPbses]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 18: {
            const pbsId_9 = msg.fields[0];
            return defaultArg(map((tupledArg_10) => {
                const el_1 = tupledArg_10[0];
                const sl_2 = tupledArg_10[1];
                const p_14 = tupledArg_10[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, el_1, sl_2, state.CustomerDescriptionError), postResetCustomerDescriptionFunction(state.RestAddresses.PostResetPbsCustomerDescriptionAddress)(p_14), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_8) => (pbs_8.Id === pbsId_9), state.CustomerDescriptionsEdited), (_arg8) => {
                const pbsToEdit_4 = _arg8;
                const customerDescriptionsEdited_3 = distinct(List_remove(pbsToEdit_4, state.CustomerDescriptionsEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const customerDescriptionsSaved_2 = distinct(List_add(pbsToEdit_4, state.CustomerDescriptionsSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, [customerDescriptionsEdited_3, customerDescriptionsSaved_2, pbsToEdit_4]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 19: {
            const pbsId_10 = msg.fields[0];
            return defaultArg(map((l_5) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, void 0, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_5, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => {
                const pbses_3 = map_1((tuple_1) => tuple_1[0], state.Pbses);
                const translations = map_1((traO_1) => traO_1, filter((traO) => (traO != null), map_1((tuple_2) => tuple_2[1], state.Pbses)));
                const listToSearch = append(pbses_3, translations);
                return OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_9) => (pbs_9.Id === pbsId_10), listToSearch), (_arg9) => {
                    const pbs_10 = _arg9;
                    const pbsToEdit_5 = new PbsToEdit(pbs_10.Id, pbs_10.NameOnly, pbs_10.Description);
                    const pbsesEdited = distinct(List_add(pbsToEdit_5, state.PbsesEdited), {
                        Equals: equalsSafe,
                        GetHashCode: safeHash,
                    });
                    return OptionBuilder__Return_1505(option_33, pbsesEdited);
                });
            }))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 20: {
            const pbsId_11 = msg.fields[0];
            return defaultArg(map((l_6) => [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, l_6, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)], OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_11) => (pbs_11.Id === pbsId_11), state.PbsesEdited), (_arg10) => {
                const pbsToEdit_6 = _arg10;
                const pbsesEdited_1 = distinct(List_remove(pbsToEdit_6, state.PbsesEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, pbsesEdited_1);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 23: {
            const validatedPbsToSave = msg.fields[1];
            const saveAndClose_4 = msg.fields[0];
            return [state, postUpdatePbsFunction(state.RestAddresses.PostUpdatePbsAddress)(saveAndClose_4)(validatedPbsToSave), new PbsListExtraMsg(0)];
        }
        case 24: {
            const saveAndClose_5 = msg.fields[0];
            const pbsId_12 = msg.fields[1];
            return defaultArg(map((tupledArg_11) => {
                let tupledArg_12;
                const el_2 = tupledArg_11[0];
                const sl_3 = tupledArg_11[1];
                const p_15 = tupledArg_11[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, el_2, sl_3, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), (tupledArg_12 = [saveAndClose_5, p_15], validatePbsDescription(tupledArg_12[0], tupledArg_12[1])), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_12) => (pbs_12.Id === pbsId_12), state.PbsesEdited), (_arg11) => {
                const pbsToEdit_7 = _arg11;
                const pbsesEdited_2 = distinct(List_remove(pbsToEdit_7, state.PbsesEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const pbsesSaved = distinct(List_add(pbsToEdit_7, state.PbsesSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, [pbsesEdited_2, pbsesSaved, pbsToEdit_7]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 25: {
            const saveAndClose_7 = msg.fields[0];
            const result_3 = msg.fields[1];
            if (result_3.tag === 1) {
                const pbsId_13 = result_3.fields[0][0];
                const errorMessage_1 = result_3.fields[0][1];
                return [state, Cmd_OfFunc_result((tupledArg_16 = [pbsId_13, errorMessage_1], new PbsListMsg(31, tupledArg_16[0], tupledArg_16[1]))), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack_1 = result_3.fields[0];
                return defaultArg(map((tupledArg_15) => {
                    const sl_4 = tupledArg_15[0];
                    const p_18 = tupledArg_15[1];
                    const rp_1 = tupledArg_15[2];
                    if (saveAndClose_7) {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, sl_4, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                    else {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, append(state.PbsesEdited, singleton(p_18)), sl_4, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_13) => (pbs_13.Id === pbsBack_1.Id), state.PbsesSaved), (_arg12) => {
                    const pbsToEdit_8 = _arg12;
                    const pbsesSaved_1 = distinct(List_remove(pbsToEdit_8, state.PbsesSaved), {
                        Equals: equalsSafe,
                        GetHashCode: safeHash,
                    });
                    const pbses_4 = state.Pbses;
                    const replacedPbses_1 = List_replace((tupledArg_13) => {
                        const p_16 = tupledArg_13[0];
                        const t_3 = tupledArg_13[1];
                        return p_16.Id === pbsBack_1.Id;
                    }, (tupledArg_14) => {
                        const p_17 = tupledArg_14[0];
                        const t_4 = tupledArg_14[1];
                        return [pbsBack_1, t_4];
                    }, pbses_4);
                    return OptionBuilder__Return_1505(option_33, [pbsesSaved_1, pbsToEdit_8, replacedPbses_1]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 26: {
            const projectId_6 = msg.fields[0];
            const pbsId_14 = msg.fields[1];
            const languageId_4 = msg.fields[2];
            const postProps = new CreatePbsTranslationPostParams(projectId_6, pbsId_14, languageId_4);
            const pbsToSave = find((p_20) => (p_20.Id === pbsId_14), map_1(PbsToEdit_ofPbs_Z1C2CB6B8, map_1((tuple_3) => tuple_3[0], state.Pbses)));
            const updatedPbsesEdited = List_remove(pbsToSave, state.PbsesEdited);
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, updatedPbsesEdited, append(state.PbsesSaved, singleton(pbsToSave)), state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), postTranslationCreatePostRequestProps(state.RestAddresses.PostCreatePbsTranslationAddress)(postProps), new PbsListExtraMsg(0)];
        }
        case 27: {
            const result_4 = msg.fields[0];
            if (result_4.tag === 0) {
                const projectId_7 = result_4.fields[0][1];
                const pbsTranslationAsPbs = result_4.fields[0][0];
                const parentPbsId = result_4.fields[0][2];
                const languageId_5 = result_4.fields[0][3];
                const tupleToUpdate = find((tupledArg_18) => {
                    const p_21 = tupledArg_18[0];
                    const t_5 = tupledArg_18[1];
                    return p_21.Id === parentPbsId;
                }, state.Pbses);
                const updatedTuple = [tupleToUpdate[0], pbsTranslationAsPbs];
                const updatedPbs = PbsToEdit_ofPbs_Z1C2CB6B8(tupleToUpdate[0]);
                const updatedPbses_1 = List_replace((tupledArg_19) => {
                    const p_22 = tupledArg_19[0];
                    const t_6 = tupledArg_19[1];
                    return p_22.Id === parentPbsId;
                }, (tupledArg_20) => {
                    const p_23 = tupledArg_20[0];
                    const t_7 = tupledArg_20[1];
                    return updatedTuple;
                }, state.Pbses);
                const upadtedPbsesBeingSaved = List_remove(updatedPbs, state.PbsesSaved);
                const updatedPbsesEdited_1 = append(state.PbsesEdited, singleton(updatedPbs));
                if (state.SelectedLanguage == null) {
                    const translationsRequest = Cmd_OfFunc_result((tupledArg_21 = [projectId_7, languageId_5], new PbsListMsg(6, tupledArg_21[0], tupledArg_21[1])));
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, updatedPbses_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, updatedPbsesEdited_1, upadtedPbsesBeingSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), translationsRequest, new PbsListExtraMsg(0)];
                }
                else {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, updatedPbses_1, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, updatedPbsesEdited_1, upadtedPbsesBeingSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
                }
            }
            else {
                const pbsId_15 = result_4.fields[0][0];
                const errorMessage_2 = result_4.fields[0][1];
                return [state, Cmd_OfFunc_result((tupledArg_17 = [pbsId_15, errorMessage_2], new PbsListMsg(31, tupledArg_17[0], tupledArg_17[1]))), new PbsListExtraMsg(0)];
            }
        }
        case 28: {
            const saveAndClose_8 = msg.fields[0];
            const pbsId_16 = msg.fields[1];
            return defaultArg(map((tupledArg_22) => {
                const el_3 = tupledArg_22[0];
                const sl_5 = tupledArg_22[1];
                const p_24 = tupledArg_22[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, el_3, sl_5, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), postUpdatePbsTranslationFunction(state.RestAddresses.PostUpdatePbsTranslationAddress)(saveAndClose_8)(p_24), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_14) => (pbs_14.Id === pbsId_16), state.PbsesEdited), (_arg13) => {
                const pbsToEdit_9 = _arg13;
                const pbsesEdited_3 = distinct(List_remove(pbsToEdit_9, state.PbsesEdited), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const pbsesSaved_2 = distinct(List_add(pbsToEdit_9, state.PbsesSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                return OptionBuilder__Return_1505(option_33, [pbsesEdited_3, pbsesSaved_2, pbsToEdit_9]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 29: {
            const saveAndClose_9 = msg.fields[0];
            const result_5 = msg.fields[1];
            if (result_5.tag === 1) {
                const pbsId_17 = result_5.fields[0][0];
                const errorMessage_3 = result_5.fields[0][1];
                return [state, Cmd_OfFunc_result((tupledArg_26 = [pbsId_17, errorMessage_3], new PbsListMsg(31, tupledArg_26[0], tupledArg_26[1]))), new PbsListExtraMsg(0)];
            }
            else {
                const pbsBack_2 = result_5.fields[0];
                return defaultArg(map((tupledArg_25) => {
                    const sl_6 = tupledArg_25[0];
                    const p_27 = tupledArg_25[1];
                    const rp_2 = tupledArg_25[2];
                    if (saveAndClose_9) {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_2, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, sl_6, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                    else {
                        return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, rp_2, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, append(state.PbsesEdited, singleton(p_27)), sl_6, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
                    }
                }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_15) => (pbs_15.Id === pbsBack_2.Id), state.PbsesSaved), (_arg14) => {
                    const pbsToEdit_10 = _arg14;
                    const pbsesSaved_3 = distinct(List_remove(pbsToEdit_10, state.PbsesSaved), {
                        Equals: equalsSafe,
                        GetHashCode: safeHash,
                    });
                    const pbses_5 = state.Pbses;
                    const replacedPbses_2 = List_replace((tupledArg_23) => {
                        const p_25 = tupledArg_23[0];
                        const t_8 = tupledArg_23[1];
                        return t_8.Id === pbsBack_2.Id;
                    }, (tupledArg_24) => {
                        const p_26 = tupledArg_24[0];
                        const t_9 = tupledArg_24[1];
                        return [p_26, pbsBack_2];
                    }, pbses_5);
                    return OptionBuilder__Return_1505(option_33, [pbsesSaved_3, pbsToEdit_10, replacedPbses_2]);
                })))), [state, empty(), new PbsListExtraMsg(0)]);
            }
        }
        case 30: {
            const exn = msg.fields[0];
            const matchValue_6 = exn.message;
            if (msg_15 = matchValue_6, msg_15.indexOf("no_account_error") >= 0) {
                const msg_16 = matchValue_6;
                if (pcio == null) {
                    return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(2, singleton(exn.message)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
                }
                else {
                    const ua = pcio;
                    return [state, Cmd_OfFunc_result(new PbsListMsg(0)), new PbsListExtraMsg(0)];
                }
            }
            else {
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, new ComponentState_8(2, singleton(exn.message)), state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), Cmd_none(), new PbsListExtraMsg(0)];
            }
        }
        case 31: {
            const pbsId_18 = msg.fields[0];
            const errorMessage_4 = msg.fields[1];
            return defaultArg(map((tupledArg_27) => {
                const sl_7 = tupledArg_27[0];
                const erl = tupledArg_27[1];
                const p_28 = tupledArg_27[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, sl_7, erl, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_16) => (pbs_16.Id === pbsId_18), state.PbsesSaved), (_arg15) => {
                const pbsToEdit_11 = _arg15;
                const pbsesSaved_4 = distinct(List_remove(pbsToEdit_11, state.PbsesSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const pbsesError = List_add([pbsToEdit_11, errorMessage_4], state.PbsesError);
                return OptionBuilder__Return_1505(option_33, [pbsesSaved_4, pbsesError, pbsToEdit_11]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 33: {
            const pbsId_19 = msg.fields[0];
            const errorMessage_5 = msg.fields[1];
            return defaultArg(map((tupledArg_28) => {
                const sl_8 = tupledArg_28[0];
                const erl_1 = tupledArg_28[1];
                const p_29 = tupledArg_28[2];
                return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, state.CustomerDescriptionsEdited, sl_8, erl_1), empty(), new PbsListExtraMsg(0)];
            }, OptionBuilder__Run_FCFD9EF(option_33, OptionBuilder__Delay_FCFD9EF(option_33, () => OptionBuilder__Bind_Z424BC9ED(option_33, tryFind((pbs_17) => (pbs_17.Id === pbsId_19), state.CustomerDescriptionsSaved), (_arg16) => {
                const pbsToEdit_12 = _arg16;
                const customerDescriptionsSaved_3 = distinct(List_remove(pbsToEdit_12, state.CustomerDescriptionsSaved), {
                    Equals: equalsSafe,
                    GetHashCode: safeHash,
                });
                const pbsesError_1 = List_add([pbsToEdit_12, errorMessage_5], state.CustomerDescriptionError);
                return OptionBuilder__Return_1505(option_33, [customerDescriptionsSaved_3, pbsesError_1, pbsToEdit_12]);
            })))), [state, empty(), new PbsListExtraMsg(0)]);
        }
        case 32: {
            const pbsId_20 = msg.fields[0];
            const pbsToRemove = find((tupledArg_29) => {
                const pbs_18 = tupledArg_29[0];
                return pbs_18.Id === pbsId_20;
            }, state.PbsesError);
            const pbsesError_2 = distinct(List_remove(pbsToRemove, state.PbsesError), {
                Equals: equalArrays,
                GetHashCode: arrayHash,
            });
            const pbsToEdit_13 = pbsToRemove[0];
            const pbsesEdited_4 = List_add(pbsToEdit_13, state.PbsesEdited);
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, pbsesEdited_4, state.PbsesSaved, pbsesError_2, state.FilteredPbses, state.CustomerDescriptionsEdited, state.CustomerDescriptionsSaved, state.CustomerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        case 34: {
            const pbsId_21 = msg.fields[0];
            const pbsToRemove_1 = find((tupledArg_30) => {
                const pbs_19 = tupledArg_30[0];
                return pbs_19.Id === pbsId_21;
            }, state.CustomerDescriptionError);
            const customerDescriptionError = distinct(List_remove(pbsToRemove_1, state.CustomerDescriptionError), {
                Equals: equalArrays,
                GetHashCode: arrayHash,
            });
            const customerDescriptionToEdit = pbsToRemove_1[0];
            const customerDescriptionsEdited_4 = List_add(customerDescriptionToEdit, state.CustomerDescriptionsEdited);
            return [new PbsListComponentState(state.RestAddresses, state.AccessToFunctions, state.ComponentState, state.Pbses, state.SelectedPbs, state.SelectedProjectId, state.SelectedService, state.SelectedServiceModule, state.SelectedLanguage, state.TranslationLanguages, state.TranslationSelectedLanguage, state.PbsesEdited, state.PbsesSaved, state.PbsesError, state.FilteredPbses, customerDescriptionsEdited_4, state.CustomerDescriptionsSaved, customerDescriptionError), empty(), new PbsListExtraMsg(0)];
        }
        default: {
            return [state, loginRedirectFn, new PbsListExtraMsg(0)];
        }
    }
}

