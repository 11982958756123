import { equals, getEnumUnderlyingType, isEnum, getTupleElements, isTuple, getElementType, isArray, getFunctionElements, isFunction, getUnionCases, getUnionCaseFields, isUnion, getGenerics, getRecordElements, name, isRecord, fullName } from "../fable-library.3.0.0/Reflection.js";
import { UnionCase, RecordField, TypeInfo } from "./TypeInfo.fs.js";
import { map } from "../fable-library.3.0.0/Array.js";
import { collect, singleton, append, delay } from "../fable-library.3.0.0/Seq.js";
import { structuralHash, Lazy } from "../fable-library.3.0.0/Util.js";
import { Dictionary } from "../fable-library.3.0.0/MutableMap.js";
import { tryGetValue } from "../fable-library.3.0.0/MapUtil.js";
import { FSharpRef } from "../fable-library.3.0.0/Types.js";
import { endsWith, isNullOrEmpty } from "../fable-library.3.0.0/String.js";

export function $007CPrimitiveType$007C_$007C(primType) {
    const matchValue = fullName(primType);
    switch (matchValue) {
        case "System.String": {
            return new TypeInfo(1);
        }
        case "System.Int16": {
            return new TypeInfo(10);
        }
        case "System.Int32": {
            return new TypeInfo(5);
        }
        case "Microsoft.FSharp.Core.int64`1":
        case "System.Int64": {
            return new TypeInfo(11);
        }
        case "System.UInt16": {
            return new TypeInfo(2);
        }
        case "System.UInt32": {
            return new TypeInfo(3);
        }
        case "System.UInt64": {
            return new TypeInfo(4);
        }
        case "System.DateTime": {
            return new TypeInfo(13);
        }
        case "System.TimeSpan": {
            return new TypeInfo(16);
        }
        case "System.DateTimeOffset": {
            return new TypeInfo(14);
        }
        case "System.Boolean": {
            return new TypeInfo(6);
        }
        case "System.Single": {
            return new TypeInfo(7);
        }
        case "System.Double": {
            return new TypeInfo(8);
        }
        case "Microsoft.FSharp.Core.decimal`1":
        case "System.Decimal": {
            return new TypeInfo(9);
        }
        case "System.Numerics.BigInteger": {
            return new TypeInfo(15);
        }
        case "Microsoft.FSharp.Core.Unit": {
            return new TypeInfo(0);
        }
        case "System.Guid": {
            return new TypeInfo(17);
        }
        case "System.Byte": {
            return new TypeInfo(12);
        }
        case "System.Object": {
            return new TypeInfo(18);
        }
        default: {
            return void 0;
        }
    }
}

export function $007CRecordType$007C_$007C(t) {
    if (isRecord(t)) {
        return map((field) => [field, name(field), field[1]], getRecordElements(t));
    }
    else {
        return void 0;
    }
}

export function $007CSetType$007C_$007C(t) {
    if (fullName(t).indexOf("Microsoft.FSharp.Collections.FSharpSet`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CNullable$007C_$007C(t) {
    if (fullName(t).indexOf("System.Nullable`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CUnionType$007C_$007C(t) {
    if (isUnion(t)) {
        return map((info) => {
            const caseName = name(info);
            const caseTypes = map((prop) => prop[1], getUnionCaseFields(info));
            return [caseName, info, caseTypes];
        }, getUnionCases(t));
    }
    else {
        return void 0;
    }
}

export function $007CMapType$007C_$007C(t) {
    if (fullName(t).indexOf("Microsoft.FSharp.Collections.FSharpMap`2") === 0) {
        const genArgs = getGenerics(t);
        return [genArgs[0], genArgs[1]];
    }
    else {
        return void 0;
    }
}

export function $007CListType$007C_$007C(t) {
    if (fullName(t).indexOf("Microsoft.FSharp.Collections.FSharpList`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function flattenFuncTypes(typeDef) {
    return Array.from(delay(() => {
        if (isFunction(typeDef)) {
            const patternInput = getFunctionElements(typeDef);
            const range = patternInput[1];
            const domain = patternInput[0];
            return append(flattenFuncTypes(domain), delay(() => flattenFuncTypes(range)));
        }
        else {
            return singleton(typeDef);
        }
    }));
}

export function $007CFuncType$007C_$007C(t) {
    if (isFunction(t)) {
        return flattenFuncTypes(t);
    }
    else {
        return void 0;
    }
}

export function $007CArrayType$007C_$007C(t) {
    if (isArray(t)) {
        return getElementType(t);
    }
    else {
        return void 0;
    }
}

export function $007COptionType$007C_$007C(t) {
    if (fullName(t).indexOf("Microsoft.FSharp.Core.FSharpOption`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CTupleType$007C_$007C(t) {
    if (isTuple(t)) {
        return getTupleElements(t);
    }
    else {
        return void 0;
    }
}

export function $007CSeqType$007C_$007C(t) {
    if (fullName(t).indexOf("System.Collections.Generic.IEnumerable`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CDictionaryType$007C_$007C(t) {
    if (fullName(t).indexOf("System.Collections.Generic.Dictionary") === 0) {
        const genArgs = getGenerics(t);
        return [genArgs[0], genArgs[1]];
    }
    else {
        return void 0;
    }
}

export function $007CResizeArrayType$007C_$007C(t) {
    if (fullName(t).indexOf("System.Collections.Generic.List") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CHashSetType$007C_$007C(t) {
    if (fullName(t).indexOf("System.Collections.Generic.HashSet") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CAsyncType$007C_$007C(t) {
    if (fullName(t).indexOf("Microsoft.FSharp.Control.FSharpAsync`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

export function $007CPromiseType$007C_$007C(t) {
    if (fullName(t).indexOf("Fable.Core.JS.Promise`1") === 0) {
        return getGenerics(t)[0];
    }
    else {
        return void 0;
    }
}

function lazyToDelayed(l, unitVar0) {
    return l.Value;
}

export function $007CEnumType$007C_$007C(t) {
    if (isEnum(t)) {
        return getEnumUnderlyingType(t);
    }
    else {
        return void 0;
    }
}

function _createTypeInfo(resolvedType) {
    let l, l_3, l_4, l_5, l_6, l_7, l_8, l_9, l_10, l_11, l_12, l_13, l_14, l_15, l_16, l_17;
    const activePatternResult3127 = $007CPrimitiveType$007C_$007C(resolvedType);
    if (activePatternResult3127 != null) {
        const typeInfo = activePatternResult3127;
        return typeInfo;
    }
    else {
        const activePatternResult3126 = $007CFuncType$007C_$007C(resolvedType);
        if (activePatternResult3126 != null) {
            const types = activePatternResult3126;
            return new TypeInfo(32, (l = (new Lazy(() => map(createTypeInfo, types))), () => lazyToDelayed(l, void 0)));
        }
        else {
            const activePatternResult3125 = $007CRecordType$007C_$007C(resolvedType);
            if (activePatternResult3125 != null) {
                const fields = activePatternResult3125;
                const l_1 = new Lazy(() => {
                    const fields_1 = Array.from(delay(() => collect((matchValue) => {
                        const fieldType = matchValue[2];
                        const fieldName = matchValue[1];
                        const field = matchValue[0];
                        return singleton(new RecordField(fieldName, createTypeInfo(fieldType), field));
                    }, fields)));
                    return [fields_1, resolvedType];
                });
                return new TypeInfo(34, () => lazyToDelayed(l_1, void 0));
            }
            else {
                const activePatternResult3124 = $007CUnionType$007C_$007C(resolvedType);
                if (activePatternResult3124 != null) {
                    const cases = activePatternResult3124;
                    const l_2 = new Lazy(() => [Array.from(delay(() => collect((matchValue_1) => {
                        const caseTypes = matchValue_1[2];
                        const caseName = matchValue_1[0];
                        const caseInfo = matchValue_1[1];
                        return singleton(new UnionCase(caseName, map(createTypeInfo, caseTypes), caseInfo));
                    }, cases))), resolvedType]);
                    return new TypeInfo(35, () => lazyToDelayed(l_2, void 0));
                }
                else {
                    const activePatternResult3123 = $007CEnumType$007C_$007C(resolvedType);
                    if (activePatternResult3123 != null) {
                        const elemType = activePatternResult3123;
                        return new TypeInfo(33, (l_3 = (new Lazy(() => [createTypeInfo(elemType), resolvedType])), () => lazyToDelayed(l_3, void 0)));
                    }
                    else {
                        const activePatternResult3122 = $007CListType$007C_$007C(resolvedType);
                        if (activePatternResult3122 != null) {
                            const elemType_1 = activePatternResult3122;
                            return new TypeInfo(23, (l_4 = (new Lazy(() => createTypeInfo(elemType_1))), () => lazyToDelayed(l_4, void 0)));
                        }
                        else {
                            const activePatternResult3121 = $007CResizeArrayType$007C_$007C(resolvedType);
                            if (activePatternResult3121 != null) {
                                const elemType_2 = activePatternResult3121;
                                return new TypeInfo(30, (l_5 = (new Lazy(() => createTypeInfo(elemType_2))), () => lazyToDelayed(l_5, void 0)));
                            }
                            else {
                                const activePatternResult3120 = $007CHashSetType$007C_$007C(resolvedType);
                                if (activePatternResult3120 != null) {
                                    const elemType_3 = activePatternResult3120;
                                    return new TypeInfo(31, (l_6 = (new Lazy(() => createTypeInfo(elemType_3))), () => lazyToDelayed(l_6, void 0)));
                                }
                                else {
                                    const activePatternResult3119 = $007CArrayType$007C_$007C(resolvedType);
                                    if (activePatternResult3119 != null) {
                                        const elemType_4 = activePatternResult3119;
                                        return new TypeInfo(25, (l_7 = (new Lazy(() => createTypeInfo(elemType_4))), () => lazyToDelayed(l_7, void 0)));
                                    }
                                    else {
                                        const activePatternResult3118 = $007CTupleType$007C_$007C(resolvedType);
                                        if (activePatternResult3118 != null) {
                                            const types_1 = activePatternResult3118;
                                            return new TypeInfo(27, (l_8 = (new Lazy(() => map(createTypeInfo, types_1))), () => lazyToDelayed(l_8, void 0)));
                                        }
                                        else {
                                            const activePatternResult3117 = $007COptionType$007C_$007C(resolvedType);
                                            if (activePatternResult3117 != null) {
                                                const elemType_5 = activePatternResult3117;
                                                return new TypeInfo(22, (l_9 = (new Lazy(() => createTypeInfo(elemType_5))), () => lazyToDelayed(l_9, void 0)));
                                            }
                                            else {
                                                const activePatternResult3116 = $007CNullable$007C_$007C(resolvedType);
                                                if (activePatternResult3116 != null) {
                                                    const elemType_6 = activePatternResult3116;
                                                    return new TypeInfo(22, (l_10 = (new Lazy(() => createTypeInfo(elemType_6))), () => lazyToDelayed(l_10, void 0)));
                                                }
                                                else {
                                                    const activePatternResult3115 = $007CSetType$007C_$007C(resolvedType);
                                                    if (activePatternResult3115 != null) {
                                                        const elemType_7 = activePatternResult3115;
                                                        return new TypeInfo(24, (l_11 = (new Lazy(() => createTypeInfo(elemType_7))), () => lazyToDelayed(l_11, void 0)));
                                                    }
                                                    else {
                                                        const activePatternResult3114 = $007CMapType$007C_$007C(resolvedType);
                                                        if (activePatternResult3114 != null) {
                                                            const keyType = activePatternResult3114[0];
                                                            const valueType = activePatternResult3114[1];
                                                            return new TypeInfo(28, (l_12 = (new Lazy(() => [createTypeInfo(keyType), createTypeInfo(valueType)])), () => lazyToDelayed(l_12, void 0)));
                                                        }
                                                        else {
                                                            const activePatternResult3113 = $007CDictionaryType$007C_$007C(resolvedType);
                                                            if (activePatternResult3113 != null) {
                                                                const keyType_1 = activePatternResult3113[0];
                                                                const valueType_1 = activePatternResult3113[1];
                                                                return new TypeInfo(29, (l_13 = (new Lazy(() => [createTypeInfo(keyType_1), createTypeInfo(valueType_1), valueType_1])), () => lazyToDelayed(l_13, void 0)));
                                                            }
                                                            else {
                                                                const activePatternResult3112 = $007CSeqType$007C_$007C(resolvedType);
                                                                if (activePatternResult3112 != null) {
                                                                    const elemType_8 = activePatternResult3112;
                                                                    return new TypeInfo(26, (l_14 = (new Lazy(() => createTypeInfo(elemType_8))), () => lazyToDelayed(l_14, void 0)));
                                                                }
                                                                else {
                                                                    const activePatternResult3111 = $007CAsyncType$007C_$007C(resolvedType);
                                                                    if (activePatternResult3111 != null) {
                                                                        const elemType_9 = activePatternResult3111;
                                                                        return new TypeInfo(20, (l_15 = (new Lazy(() => createTypeInfo(elemType_9))), () => lazyToDelayed(l_15, void 0)));
                                                                    }
                                                                    else {
                                                                        const activePatternResult3110 = $007CPromiseType$007C_$007C(resolvedType);
                                                                        if (activePatternResult3110 != null) {
                                                                            const elemType_10 = activePatternResult3110;
                                                                            return new TypeInfo(21, (l_16 = (new Lazy(() => createTypeInfo(elemType_10))), () => lazyToDelayed(l_16, void 0)));
                                                                        }
                                                                        else {
                                                                            return new TypeInfo(19, (l_17 = (new Lazy(() => resolvedType)), () => lazyToDelayed(l_17, void 0)));
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

const typeInfoCache = new Dictionary([], {
    Equals: equals,
    GetHashCode: structuralHash,
});

export function createTypeInfo(resolvedType) {
    let matchValue;
    let outArg = null;
    matchValue = [tryGetValue(typeInfoCache, resolvedType, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        const ti = matchValue[1];
        return ti;
    }
    else {
        const ti_1 = _createTypeInfo(resolvedType);
        const notAnonymousRecord = ((!isNullOrEmpty(fullName(resolvedType))) ? (!endsWith(fullName(resolvedType), "`1[]")) : false) ? (!endsWith(fullName(resolvedType), "`2[]")) : false;
        if (notAnonymousRecord) {
            typeInfoCache.set(resolvedType, ti_1);
            return ti_1;
        }
        else {
            return ti_1;
        }
    }
}

export function isPrimitive(_arg1) {
    switch (_arg1.tag) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 17:
        case 22: {
            return true;
        }
        default: {
            const otherwise = _arg1;
            return false;
        }
    }
}

export function enumUnion(_arg1) {
    if (_arg1.tag === 35) {
        const getCases = _arg1.fields[0];
        return getCases()[0].every((case$) => (case$.CaseTypes.length === 0));
    }
    else {
        const otherwise = _arg1;
        return false;
    }
}

