import { Record } from "../../../.fable/fable-library.3.0.0/Types.js";
import { record_type, class_type, lambda_type, unit_type, option_type, string_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { UserInfo$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import * as style from "../../../../../../Aimtec.Components.React/Components/AimtecHeader/style.scss";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { ofSeq } from "../../../.fable/fable-library.3.0.0/List.js";
import { singleton, append, delay } from "../../../.fable/fable-library.3.0.0/Seq.js";
import { interpolate, toText, join } from "../../../.fable/fable-library.3.0.0/String.js";

export class HeaderPropps extends Record {
    constructor(AppNameAndVersion, ProjectReference, PbsReference, UserInfo, AuthenticationFunction, LogoClick, HeaderClick, ProjectClick) {
        super();
        this.AppNameAndVersion = AppNameAndVersion;
        this.ProjectReference = ProjectReference;
        this.PbsReference = PbsReference;
        this.UserInfo = UserInfo;
        this.AuthenticationFunction = AuthenticationFunction;
        this.LogoClick = LogoClick;
        this.HeaderClick = HeaderClick;
        this.ProjectClick = ProjectClick;
    }
}

export function HeaderPropps$reflection() {
    return record_type("AimtecHeaderComponentView.HeaderPropps", [], HeaderPropps, () => [["AppNameAndVersion", string_type], ["ProjectReference", option_type(XrmEntityReference$reflection())], ["PbsReference", option_type(XrmEntityReference$reflection())], ["UserInfo", option_type(UserInfo$reflection())], ["AuthenticationFunction", lambda_type(unit_type, unit_type)], ["LogoClick", lambda_type(unit_type, unit_type)], ["HeaderClick", lambda_type(unit_type, unit_type)], ["ProjectClick", lambda_type(class_type("System.Guid"), unit_type)]]);
}


export function render(props) {
    return createElement("nav", {
        className: "navbar navbar-expand-lg navbar-dark bg-dark",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "container-fluid",
            children: Interop_reactApi.Children.toArray(Array.from(ofSeq(delay(() => append(singleton(createElement("img", {
                src: "img/cloud-logo.svg",
                className: "navbar-brand cursor-pointer",
                onClick: (_arg1) => {
                    props.LogoClick();
                },
            })), delay(() => append(singleton(createElement("button", {
                className: "navbar-toggler",
                type: "button",
                ["data-bs-toggle"]: "collapse",
                ["data-bs-target"]: "#navbarSupportedContent",
                ["aria-controls"]: join(" ", ["navbarSupportedContent"]),
                ["aria-expanded"]: false,
                ["aria-label"]: "Toggle navigation",
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    className: "navbar-toggler-icon",
                })]),
            })), delay(() => {
                let projectLabelOrFrag;
                const matchValue = props.ProjectReference;
                if (matchValue == null) {
                    projectLabelOrFrag = react.createElement(react.Fragment, {});
                }
                else {
                    const pr = matchValue;
                    projectLabelOrFrag = createElement("li", {
                        className: "nav-item",
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            className: "nav-link active cursor-pointer",
                            children: toText(interpolate(" | %P()", [pr.Name])),
                            onClick: (_arg2) => {
                                props.ProjectClick(pr.Id);
                            },
                        })]),
                    });
                }
                let pbsLabelOrFrag;
                const matchValue_1 = props.PbsReference;
                if (matchValue_1 == null) {
                    pbsLabelOrFrag = react.createElement(react.Fragment, {});
                }
                else {
                    const pbs = matchValue_1;
                    pbsLabelOrFrag = createElement("li", {
                        className: "nav-item",
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            className: "nav-link active",
                            children: toText(interpolate(" | %P()", [pbs.Name])),
                        })]),
                    });
                }
                let loginOrLogout;
                const matchValue_2 = props.UserInfo;
                if (matchValue_2 != null) {
                    const ui = matchValue_2;
                    loginOrLogout = createElement("a", {
                        className: "nav-lin authentication-label",
                        children: toText(interpolate("%P() %P()", [ui.FirstName, ui.LastName])),
                        onClick: (_arg3) => {
                            props.AuthenticationFunction();
                        },
                    });
                }
                else {
                    loginOrLogout = react.createElement(react.Fragment, {});
                }
                return singleton(createElement("div", {
                    id: "navbarSupportedContent",
                    className: "collapse navbar-collapse",
                    children: Interop_reactApi.Children.toArray([createElement("ul", {
                        className: "navbar-nav me-auto mb-2 mb-lg-0",
                        children: Interop_reactApi.Children.toArray([createElement("li", {
                            className: "nav-item",
                            children: Interop_reactApi.Children.toArray([createElement("a", {
                                className: "nav-link active cursor-pointer",
                                children: props.AppNameAndVersion,
                                onClick: (_arg4) => {
                                    props.HeaderClick();
                                },
                            })]),
                        }), projectLabelOrFrag, pbsLabelOrFrag]),
                    }), loginOrLogout]),
                }));
            })))))))),
        })]),
    });
}

