import { Record, Union } from "../../.fable/fable-library.3.0.0/Types.js";
import { list_type, option_type, enum_type, int32_type, string_type, class_type, record_type, union_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "./Aimtec.D365.Entity.Types.js";
import { UserInfo$reflection } from "./Aimtec.D365.Odc.Types.js";

export class StateCode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Active", "Resolved", "Canceled"];
    }
}

export function StateCode$reflection() {
    return union_type("Aimtec.D365.Sed.StateCode", [], StateCode, () => [[], [], []]);
}

export class RelatedPbsData extends Record {
    constructor(ParentPbsId, Project, ProjectServiceId, ProjectServiceModuleId, UserInfo) {
        super();
        this.ParentPbsId = ParentPbsId;
        this.Project = Project;
        this.ProjectServiceId = ProjectServiceId;
        this.ProjectServiceModuleId = ProjectServiceModuleId;
        this.UserInfo = UserInfo;
    }
}

export function RelatedPbsData$reflection() {
    return record_type("Aimtec.D365.Sed.RelatedPbsData", [], RelatedPbsData, () => [["ParentPbsId", XrmEntityReference$reflection()], ["Project", XrmEntityReference$reflection()], ["ProjectServiceId", XrmEntityReference$reflection()], ["ProjectServiceModuleId", XrmEntityReference$reflection()], ["UserInfo", UserInfo$reflection()]]);
}

export class ServiceDeskToCreate extends Record {
    constructor(Id, Description, Title, RequestType, Urgency, ParentPbsId, ProjectId, ProjectServiceId, ProjectServiceModuleId, AccountId, ContactId) {
        super();
        this.Id = Id;
        this.Description = Description;
        this.Title = Title;
        this.RequestType = (RequestType | 0);
        this.Urgency = (Urgency | 0);
        this.ParentPbsId = ParentPbsId;
        this.ProjectId = ProjectId;
        this.ProjectServiceId = ProjectServiceId;
        this.ProjectServiceModuleId = ProjectServiceModuleId;
        this.AccountId = AccountId;
        this.ContactId = ContactId;
    }
}

export function ServiceDeskToCreate$reflection() {
    return record_type("Aimtec.D365.Sed.ServiceDeskToCreate", [], ServiceDeskToCreate, () => [["Id", class_type("System.Guid")], ["Description", string_type], ["Title", string_type], ["RequestType", enum_type("Aimtec.D365.Sed.RequestType", int32_type, [["Incident", 200000001], ["ServiceRequest", 200000003], ["ChangeRequest", 200000002], ["Problem", 200000004], ["PreventiveMaintenance", 914670000]])], ["Urgency", enum_type("Aimtec.D365.Sed.Urgency", int32_type, [["High", 100000000], ["Medium", 100000001], ["Low", 100000002]])], ["ParentPbsId", XrmEntityReference$reflection()], ["ProjectId", XrmEntityReference$reflection()], ["ProjectServiceId", XrmEntityReference$reflection()], ["ProjectServiceModuleId", XrmEntityReference$reflection()], ["AccountId", XrmEntityReference$reflection()], ["ContactId", XrmEntityReference$reflection()]]);
}

export class Attachment extends Record {
    constructor(Id, Name, FileSize) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.FileSize = (FileSize | 0);
    }
}

export function Attachment$reflection() {
    return record_type("Aimtec.D365.Sed.Attachment", [], Attachment, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["FileSize", int32_type]]);
}

export class ServiceDeskRequest extends Record {
    constructor(Id, Description, Title, RequestNumber, RequestType, Urgency, Side, StatusCode, StateCode, Customer, PrimaryContact, Project, Entitlement, EntitlementProduct, EntitlementProductModule, Owner, Attachments) {
        super();
        this.Id = Id;
        this.Description = Description;
        this.Title = Title;
        this.RequestNumber = RequestNumber;
        this.RequestType = (RequestType | 0);
        this.Urgency = (Urgency | 0);
        this.Side = (Side | 0);
        this.StatusCode = (StatusCode | 0);
        this.StateCode = StateCode;
        this.Customer = Customer;
        this.PrimaryContact = PrimaryContact;
        this.Project = Project;
        this.Entitlement = Entitlement;
        this.EntitlementProduct = EntitlementProduct;
        this.EntitlementProductModule = EntitlementProductModule;
        this.Owner = Owner;
        this.Attachments = Attachments;
    }
}

export function ServiceDeskRequest$reflection() {
    return record_type("Aimtec.D365.Sed.ServiceDeskRequest", [], ServiceDeskRequest, () => [["Id", class_type("System.Guid")], ["Description", string_type], ["Title", string_type], ["RequestNumber", string_type], ["RequestType", enum_type("Aimtec.D365.Sed.RequestType", int32_type, [["Incident", 200000001], ["ServiceRequest", 200000003], ["ChangeRequest", 200000002], ["Problem", 200000004], ["PreventiveMaintenance", 914670000]])], ["Urgency", enum_type("Aimtec.D365.Sed.Urgency", int32_type, [["High", 100000000], ["Medium", 100000001], ["Low", 100000002]])], ["Side", enum_type("Aimtec.D365.Sed.Side", int32_type, [["Supplier", 100000000], ["Customer", 100000001]])], ["StatusCode", enum_type("Aimtec.D365.Sed.StatusCode", int32_type, [["Entry", 1], ["ApproveEntry", 200000008], ["Assign", 2], ["AssignHOLD", 914670006], ["CategorizeAndPrioritize", 3], ["CategorizeAndPrioritizeHOLD", 914670000], ["Proposal", 4], ["ProposalHOLD", 914670001], ["ProposalAcceptance", 200000000], ["TargetConcept", 200000001], ["TargetConceptHOLD", 914670002], ["TargetConceptAcceptance", 200000002], ["UpdatePlannedDates", 200000003], ["UpdatePlannedDatesHOLD", 914670003], ["Solution", 200000004], ["SolutionHOLD", 914670004], ["SolutionAcceptance", 200000005], ["DeploymentToProduction", 200000006], ["DeploymentToProductionHOLD", 914670005], ["Closure", 200000007]])], ["StateCode", StateCode$reflection()], ["Customer", option_type(XrmEntityReference$reflection())], ["PrimaryContact", option_type(XrmEntityReference$reflection())], ["Project", option_type(XrmEntityReference$reflection())], ["Entitlement", option_type(XrmEntityReference$reflection())], ["EntitlementProduct", option_type(XrmEntityReference$reflection())], ["EntitlementProductModule", option_type(XrmEntityReference$reflection())], ["Owner", option_type(XrmEntityReference$reflection())], ["Attachments", list_type(Attachment$reflection())]]);
}

export class ServiceDeskResponsePayload extends Record {
    constructor(UserInfo, ParentPbsId, ProjectReference, ProjectServiceId, ProjectServiceModuleId, ServiceDeskRequestList) {
        super();
        this.UserInfo = UserInfo;
        this.ParentPbsId = ParentPbsId;
        this.ProjectReference = ProjectReference;
        this.ProjectServiceId = ProjectServiceId;
        this.ProjectServiceModuleId = ProjectServiceModuleId;
        this.ServiceDeskRequestList = ServiceDeskRequestList;
    }
}

export function ServiceDeskResponsePayload$reflection() {
    return record_type("Aimtec.D365.Sed.ServiceDeskResponsePayload", [], ServiceDeskResponsePayload, () => [["UserInfo", UserInfo$reflection()], ["ParentPbsId", XrmEntityReference$reflection()], ["ProjectReference", XrmEntityReference$reflection()], ["ProjectServiceId", XrmEntityReference$reflection()], ["ProjectServiceModuleId", XrmEntityReference$reflection()], ["ServiceDeskRequestList", list_type(ServiceDeskRequest$reflection())]]);
}

export function AimtecServiceDesk_statusCodeString(s) {
    switch (s) {
        case 1: {
            return "010 Entry";
        }
        case 2: {
            return "030 Assign";
        }
        case 3: {
            return "040 Categorize and Prioritize";
        }
        case 4: {
            return "050 Proposal";
        }
        case 200000000: {
            return "060 Proposal Acceptance";
        }
        case 200000001: {
            return "070 Target Concept";
        }
        case 200000002: {
            return "080 Target Concept Acceptance";
        }
        case 200000003: {
            return "090 Update Planned Dates";
        }
        case 200000004: {
            return "100 Solution";
        }
        case 200000005: {
            return "110 Solution Acceptance";
        }
        case 200000006: {
            return "120 Deployment to Production";
        }
        case 200000007: {
            return "130 Closure";
        }
        case 200000008: {
            return "020 Approve Entry";
        }
        case 914670000: {
            return "041 Categorize and Prioritize HOLD";
        }
        case 914670001: {
            return "051 Proposal HOLD";
        }
        case 914670002: {
            return "071 Target Concept HOLD";
        }
        case 914670003: {
            return "091 Update Planned Dates HOLD";
        }
        case 914670004: {
            return "101 Solution HOLD";
        }
        case 914670005: {
            return "121 Deployment to Production HOLD";
        }
        case 914670006: {
            return "031 Assign HOLD";
        }
        default: {
            return "Unrecognized Status Code value!";
        }
    }
}

export function AimtecServiceDesk_stateCodeStringToType(s) {
    switch (s) {
        case "Active": {
            return new StateCode(0);
        }
        case "Resolved": {
            return new StateCode(1);
        }
        case "Canceled": {
            return new StateCode(2);
        }
        default: {
            throw (new Error("Unrecognized Service Desk State Code!"));
        }
    }
}

export function AimtecServiceDesk_sideString(s) {
    switch (s) {
        case 100000000: {
            return "Supplier";
        }
        case 100000001: {
            return "Customer";
        }
        default: {
            return "Unrecognized Side value!";
        }
    }
}

export function AimtecServiceDesk_urgencyString(u) {
    switch (u) {
        case 100000000: {
            return "High";
        }
        case 100000001: {
            return "Medium";
        }
        case 100000002: {
            return "Low";
        }
        default: {
            return "Unrecognized Urgency value!";
        }
    }
}

export function AimtecServiceDesk_requestTypeString(rt) {
    switch (rt) {
        case 200000001: {
            return "Incident";
        }
        case 200000002: {
            return "Change Request";
        }
        case 200000003: {
            return "Service Request";
        }
        case 200000004: {
            return "Problem";
        }
        case 914670000: {
            return "Preventive Maintenance";
        }
        default: {
            return "Unrecognized Request Type value!";
        }
    }
}

