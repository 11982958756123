import { singleton, ofArray } from "../../../.fable/fable-library.3.0.0/List.js";
import { SilentRequest, RedirectRequest } from "../Fable.Msal/Fable.Msal.Types.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../.fable/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../.fable/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { Cmd_OfPromise_either } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsTreeMenuMsg } from "./Types.js";
import { fetch$, Types_RequestProperties, Types_HttpRequestHeaders } from "../../../.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { keyValueList } from "../../../.fable/fable-library.3.0.0/MapUtil.js";
import { handleRedirectPromise } from "../Fable.Msal/Fable.Msal.js";
import { SimpleJson_tryParse } from "../../../.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { createTypeInfo } from "../../../.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { OnlineDocumentation$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import { union_type, string_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { Convert_fromJson } from "../../../.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";

export function loginRedirect(pci) {
    const redirectRequest = new RedirectRequest(ofArray(["openid", "profile"]));
    const request = () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.loginRedirect(redirectRequest).then((() => {
        const authResponse = void 0;
        return Promise.resolve("OK");
    })))));
    return Cmd_OfPromise_either(request, void 0, (arg0) => (new PbsTreeMenuMsg(1, arg0)), (arg0_1) => (new PbsTreeMenuMsg(9, arg0_1)));
}

export function reqHeads(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

export function fetchProjectUserAgentApplication(pci, address, projectId) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            return fetch$(address(projectId_1), reqHeads(idToken)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentation$reflection()]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, projectId, (arg0) => (new PbsTreeMenuMsg(4, arg0)), (arg0_1) => (new PbsTreeMenuMsg(9, arg0_1)));
}

export function fetchPbsChildrenUserAgentApplication(pci, address, projectId) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            return fetch$(address(projectId_1), reqHeads(idToken)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentation$reflection()]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, projectId, (arg0) => (new PbsTreeMenuMsg(4, arg0)), (arg0_1) => (new PbsTreeMenuMsg(9, arg0_1)));
}

