import * as style from "../../../../../../Aimtec.Components.React/Components/ErrorPage/style.scss";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { map } from "../../../.fable/fable-library.3.0.0/List.js";


export function render(errorMessages, onResetError) {
    const descriptionMessageTextField = (msg) => createElement("div", {
        className: "alert alert-danger",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: toText(printf("%s"))(msg),
        })]),
    });
    const errors = react.createElement(react.Fragment, {}, ...map(descriptionMessageTextField, errorMessages));
    let resetErrorButton;
    if (onResetError == null) {
        resetErrorButton = react.createElement(react.Fragment, {});
    }
    else {
        const fn = onResetError;
        resetErrorButton = createElement("button", {
            className: "btn btn-primary",
            children: "Go Back",
            onClick: (_arg1) => {
                fn();
            },
        });
    }
    return createElement("div", {
        className: "",
        children: Interop_reactApi.Children.toArray([resetErrorButton, errors]),
    });
}

