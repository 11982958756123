import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { ServiceDeskMsg } from "./Types.js";
import { AimtecServiceDesk_urgencyString, AimtecServiceDesk_requestTypeString } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Sed.Types.js";
import { toString } from "../../../.fable/fable-library.3.0.0/Types.js";
import { FineUploaderModel, FineUploaderComponent } from "../FineUploader/FineUploaderComponent.js";

function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

export function render(renderInputProps) {
    let value_6;
    const dispatch = renderInputProps.dispatch;
    const sdtcr = renderInputProps.sdtcr;
    const uploadEndpoint = renderInputProps.uploadEndpoint;
    const serviceDeskHeading = createElement("div", {
        className: "service-desk-heading",
        children: Interop_reactApi.Children.toArray([createElement("h4", {
            children: ["Create Service Desk Request"],
        })]),
    });
    const serviceDeskName = createElement("div", {
        className: "service-desk-heading",
        children: Interop_reactApi.Children.toArray([(value_6 = toText(printf("Target Process: %s"))(sdtcr.ProjectId.Name), createElement("h4", {
            children: [value_6],
        }))]),
    });
    const createServiceDeskButton = createElement("button", {
        children: "Create Service Desk",
        className: "btn btn-success",
        onClick: (_arg1) => {
            dispatch(new ServiceDeskMsg(12, sdtcr));
        },
    });
    const cancellCreateServiceDeskButton = createElement("button", {
        children: "Cancel",
        className: "btn btn-warning",
        onClick: (_arg2) => {
            let tupledArg;
            dispatch((tupledArg = [sdtcr.ProjectId.Id, sdtcr.ParentPbsId.Id], new ServiceDeskMsg(7, tupledArg[0], tupledArg[1])));
        },
    });
    const serviceDeskTypeSelect = createElement("select", {
        className: "form-select",
        value: sdtcr.RequestType,
        onChange: (e) => {
            let tupledArg_1;
            dispatch((tupledArg_1 = [sdtcr.ProjectId.Id, sdtcr.ParentPbsId.Id, op_Dynamic()(e.currentTarget)("value"), sdtcr.Urgency], new ServiceDeskMsg(11, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2], tupledArg_1[3])));
        },
        onSelect: (e_1) => {
            let tupledArg_2;
            dispatch((tupledArg_2 = [sdtcr.ProjectId.Id, sdtcr.ParentPbsId.Id, op_Dynamic()(e_1.currentTarget)("value"), sdtcr.Urgency], new ServiceDeskMsg(11, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2], tupledArg_2[3])));
        },
        children: Interop_reactApi.Children.toArray([createElement("option", {
            value: 200000001,
            children: AimtecServiceDesk_requestTypeString(200000001),
        }), createElement("option", {
            value: 200000002,
            children: AimtecServiceDesk_requestTypeString(200000002),
        }), createElement("option", {
            value: 200000003,
            children: AimtecServiceDesk_requestTypeString(200000003),
        })]),
    });
    const urgencySelect = createElement("select", {
        className: "form-select",
        value: sdtcr.Urgency,
        onChange: (e_2) => {
            let tupledArg_3;
            dispatch((tupledArg_3 = [sdtcr.ProjectId.Id, sdtcr.ParentPbsId.Id, sdtcr.RequestType, op_Dynamic()(e_2.currentTarget)("value")], new ServiceDeskMsg(11, tupledArg_3[0], tupledArg_3[1], tupledArg_3[2], tupledArg_3[3])));
        },
        onSelect: (e_3) => {
            let tupledArg_4;
            dispatch((tupledArg_4 = [sdtcr.ProjectId.Id, sdtcr.ParentPbsId.Id, sdtcr.RequestType, op_Dynamic()(e_3.currentTarget)("value")], new ServiceDeskMsg(11, tupledArg_4[0], tupledArg_4[1], tupledArg_4[2], tupledArg_4[3])));
        },
        children: Interop_reactApi.Children.toArray([createElement("option", {
            value: 100000002,
            children: AimtecServiceDesk_urgencyString(100000002),
        }), createElement("option", {
            value: 100000001,
            children: AimtecServiceDesk_urgencyString(100000001),
        }), createElement("option", {
            value: 100000000,
            children: AimtecServiceDesk_urgencyString(100000000),
        })]),
    });
    const editTileInput = createElement("input", {
        className: "form-control text-box single-line",
        defaultValue: sdtcr.Title,
        type: "text",
        onChange: (e_4) => {
            dispatch(new ServiceDeskMsg(8, toString(op_Dynamic()(e_4.currentTarget)("value"))));
        },
    });
    const editDescriptionInput = createElement("textarea", {
        className: "form-control text-box multi-line",
        defaultValue: sdtcr.Description,
        rows: 10,
        onChange: (e_5) => {
            dispatch(new ServiceDeskMsg(9, toString(op_Dynamic()(e_5.currentTarget)("value"))));
        },
    });
    const serviceDeskProjectService = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: toText(printf("Service: %s"))(sdtcr.ProjectServiceId.Name),
        })]),
    });
    const serviceDeskProjectServiceModule = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: toText(printf("Service Module: %s"))(sdtcr.ProjectServiceModuleId.Name),
        })]),
    });
    const upload = react.createElement(FineUploaderComponent, new FineUploaderModel(sdtcr.Id, uploadEndpoint));
    return createElement("div", {
        className: "service-desk-container",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "btn-group",
                children: Interop_reactApi.Children.toArray([cancellCreateServiceDeskButton, createServiceDeskButton]),
            })]),
        }), serviceDeskHeading, serviceDeskName, createElement("hr", {}), createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col-md-4",
                children: Interop_reactApi.Children.toArray([serviceDeskProjectService]),
            }), createElement("div", {
                className: "col-md-8",
                children: Interop_reactApi.Children.toArray([serviceDeskProjectServiceModule]),
            })]),
        }), createElement("hr", {}), createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col-md-6",
                children: Interop_reactApi.Children.toArray([createElement("label", {
                    className: "control-label",
                    children: "Request Type",
                }), serviceDeskTypeSelect]),
            }), createElement("div", {
                className: "col-md-6",
                children: Interop_reactApi.Children.toArray([createElement("label", {
                    className: "control-label",
                    children: "Urgency",
                }), urgencySelect]),
            })]),
        }), createElement("br", {}), createElement("div", {
            className: "form-group",
            children: Interop_reactApi.Children.toArray([createElement("label", {
                className: "control-label",
                children: "Title",
            }), editTileInput]),
        }), createElement("div", {
            className: "form-group",
            children: Interop_reactApi.Children.toArray([createElement("label", {
                className: "control-label",
                children: "Description",
            }), editDescriptionInput]),
        }), upload, createElement("hr", {}), createElement("br", {})]),
    });
}

