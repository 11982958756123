import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { bool_type, class_type, list_type, tuple_type, enum_type, union_type, record_type, int32_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "./Aimtec.D365.Entity.Types.js";
import { map, exists, find } from "../../.fable/fable-library.3.0.0/List.js";

export class HelpdeskRole extends Record {
    constructor(ChangeRequestCreate, ChangeRequestView, ChangeRequestEdit, ChangeRequestApprove, ChangeRequestAcceptProposal, ChangeRequestAcceptTargetConcept, ChangeRequestAcceptSolution, ChangeRequestClose, ChangeRequestCancel, ServiceRequestCreate, ServiceRequestView, ServiceRequestEdit, ServiceRequestApprove, ServiceRequestAcceptProposal, ServiceRequestClose, ServiceRequestCancel, IncidentCreate, IncidentView, IncidentEdit, IncidentApprove, IncidentAcceptProposal, IncidentAcceptSolution, IncidentClose, IncidentCancel, ProblemCreate, ProblemView, ProblemEdit, ProblemApprove, ProblemAcceptProposal, ProblemAcceptSolution, ProblemClose, ProblemCancel) {
        super();
        this.ChangeRequestCreate = (ChangeRequestCreate | 0);
        this.ChangeRequestView = (ChangeRequestView | 0);
        this.ChangeRequestEdit = (ChangeRequestEdit | 0);
        this.ChangeRequestApprove = (ChangeRequestApprove | 0);
        this.ChangeRequestAcceptProposal = (ChangeRequestAcceptProposal | 0);
        this.ChangeRequestAcceptTargetConcept = (ChangeRequestAcceptTargetConcept | 0);
        this.ChangeRequestAcceptSolution = (ChangeRequestAcceptSolution | 0);
        this.ChangeRequestClose = (ChangeRequestClose | 0);
        this.ChangeRequestCancel = (ChangeRequestCancel | 0);
        this.ServiceRequestCreate = (ServiceRequestCreate | 0);
        this.ServiceRequestView = (ServiceRequestView | 0);
        this.ServiceRequestEdit = (ServiceRequestEdit | 0);
        this.ServiceRequestApprove = (ServiceRequestApprove | 0);
        this.ServiceRequestAcceptProposal = (ServiceRequestAcceptProposal | 0);
        this.ServiceRequestClose = (ServiceRequestClose | 0);
        this.ServiceRequestCancel = (ServiceRequestCancel | 0);
        this.IncidentCreate = (IncidentCreate | 0);
        this.IncidentView = (IncidentView | 0);
        this.IncidentEdit = (IncidentEdit | 0);
        this.IncidentApprove = (IncidentApprove | 0);
        this.IncidentAcceptProposal = (IncidentAcceptProposal | 0);
        this.IncidentAcceptSolution = (IncidentAcceptSolution | 0);
        this.IncidentClose = (IncidentClose | 0);
        this.IncidentCancel = (IncidentCancel | 0);
        this.ProblemCreate = (ProblemCreate | 0);
        this.ProblemView = (ProblemView | 0);
        this.ProblemEdit = (ProblemEdit | 0);
        this.ProblemApprove = (ProblemApprove | 0);
        this.ProblemAcceptProposal = (ProblemAcceptProposal | 0);
        this.ProblemAcceptSolution = (ProblemAcceptSolution | 0);
        this.ProblemClose = (ProblemClose | 0);
        this.ProblemCancel = (ProblemCancel | 0);
    }
}

export function HelpdeskRole$reflection() {
    return record_type("Aimtec.D365.Pas.HelpdeskRole", [], HelpdeskRole, () => [["ChangeRequestCreate", int32_type], ["ChangeRequestView", int32_type], ["ChangeRequestEdit", int32_type], ["ChangeRequestApprove", int32_type], ["ChangeRequestAcceptProposal", int32_type], ["ChangeRequestAcceptTargetConcept", int32_type], ["ChangeRequestAcceptSolution", int32_type], ["ChangeRequestClose", int32_type], ["ChangeRequestCancel", int32_type], ["ServiceRequestCreate", int32_type], ["ServiceRequestView", int32_type], ["ServiceRequestEdit", int32_type], ["ServiceRequestApprove", int32_type], ["ServiceRequestAcceptProposal", int32_type], ["ServiceRequestClose", int32_type], ["ServiceRequestCancel", int32_type], ["IncidentCreate", int32_type], ["IncidentView", int32_type], ["IncidentEdit", int32_type], ["IncidentApprove", int32_type], ["IncidentAcceptProposal", int32_type], ["IncidentAcceptSolution", int32_type], ["IncidentClose", int32_type], ["IncidentCancel", int32_type], ["ProblemCreate", int32_type], ["ProblemView", int32_type], ["ProblemEdit", int32_type], ["ProblemApprove", int32_type], ["ProblemAcceptProposal", int32_type], ["ProblemAcceptSolution", int32_type], ["ProblemClose", int32_type], ["ProblemCancel", int32_type]]);
}

export class HelpdeskType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeRequest", "ServiceRequest", "Incident", "Problem"];
    }
}

export function HelpdeskType$reflection() {
    return union_type("Aimtec.D365.Pas.HelpdeskType", [], HelpdeskType, () => [[], [], [], []]);
}

export class Operation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Create", "View", "Edit", "Approve", "AcceptProposal", "AcceptTargetConcept", "AcceptSolution", "Close", "Cancel"];
    }
}

export function Operation$reflection() {
    return union_type("Aimtec.D365.Pas.Operation", [], Operation, () => [[], [], [], [], [], [], [], [], []]);
}

export class ProjectAccess extends Record {
    constructor(Project, AccessRights, ContactId, EditCustomerDescription, AccessToOdc) {
        super();
        this.Project = Project;
        this.AccessRights = AccessRights;
        this.ContactId = ContactId;
        this.EditCustomerDescription = EditCustomerDescription;
        this.AccessToOdc = AccessToOdc;
    }
}

export function ProjectAccess$reflection() {
    return record_type("Aimtec.D365.Pas.ProjectAccess", [], ProjectAccess, () => [["Project", XrmEntityReference$reflection()], ["AccessRights", list_type(tuple_type(HelpdeskType$reflection(), Operation$reflection(), enum_type("Aimtec.D365.Pas.AccessLevel", int32_type, [["None", 200000000], ["MyOwn", 200000001], ["MyCompany", 200000002], ["All", 200000003]])))], ["ContactId", class_type("System.Guid")], ["EditCustomerDescription", bool_type], ["AccessToOdc", bool_type]]);
}

export function ProjectAccessModule_getAccessRight(role, value) {
    const matchValue = [role, value];
    if (matchValue[0] === "ChangeRequestCreate") {
        const number = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(0), number];
    }
    else if (matchValue[0] === "ChangeRequestView") {
        const number_1 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(1), number_1];
    }
    else if (matchValue[0] === "ChangeRequestEdit") {
        const number_2 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(2), number_2];
    }
    else if (matchValue[0] === "ChangeRequestApprove") {
        const number_3 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(3), number_3];
    }
    else if (matchValue[0] === "ChangeRequestAcceptProposal") {
        const number_4 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(4), number_4];
    }
    else if (matchValue[0] === "ChangeRequestAcceptTargetConcept") {
        const number_5 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(5), number_5];
    }
    else if (matchValue[0] === "ChangeRequestAcceptSolution") {
        const number_6 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(6), number_6];
    }
    else if (matchValue[0] === "ChangeRequestClose") {
        const number_7 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(7), number_7];
    }
    else if (matchValue[0] === "ChangeRequestCancel") {
        const number_8 = matchValue[1] | 0;
        return [new HelpdeskType(0), new Operation(8), number_8];
    }
    else if (matchValue[0] === "ServiceRequestCreate") {
        const number_9 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(0), number_9];
    }
    else if (matchValue[0] === "ServiceRequestView") {
        const number_10 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(1), number_10];
    }
    else if (matchValue[0] === "ServiceRequestEdit") {
        const number_11 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(2), number_11];
    }
    else if (matchValue[0] === "ServiceRequestApprove") {
        const number_12 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(3), number_12];
    }
    else if (matchValue[0] === "ServiceRequestAcceptProposal") {
        const number_13 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(4), number_13];
    }
    else if (matchValue[0] === "ServiceRequestClose") {
        const number_14 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(7), number_14];
    }
    else if (matchValue[0] === "ServiceRequestCancel") {
        const number_15 = matchValue[1] | 0;
        return [new HelpdeskType(1), new Operation(8), number_15];
    }
    else if (matchValue[0] === "IncidentCreate") {
        const number_16 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(0), number_16];
    }
    else if (matchValue[0] === "IncidentView") {
        const number_17 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(1), number_17];
    }
    else if (matchValue[0] === "IncidentEdit") {
        const number_18 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(2), number_18];
    }
    else if (matchValue[0] === "IncidentApprove") {
        const number_19 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(3), number_19];
    }
    else if (matchValue[0] === "IncidentAcceptProposal") {
        const number_20 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(4), number_20];
    }
    else if (matchValue[0] === "IncidentAcceptSolution") {
        const number_21 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(6), number_21];
    }
    else if (matchValue[0] === "IncidentClose") {
        const number_22 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(7), number_22];
    }
    else if (matchValue[0] === "IncidentCancel") {
        const number_23 = matchValue[1] | 0;
        return [new HelpdeskType(2), new Operation(8), number_23];
    }
    else if (matchValue[0] === "ProblemCreate") {
        const number_24 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(0), number_24];
    }
    else if (matchValue[0] === "ProblemView") {
        const number_25 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(1), number_25];
    }
    else if (matchValue[0] === "ProblemEdit") {
        const number_26 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(2), number_26];
    }
    else if (matchValue[0] === "ProblemApprove") {
        const number_27 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(3), number_27];
    }
    else if (matchValue[0] === "ProblemAcceptProposal") {
        const number_28 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(4), number_28];
    }
    else if (matchValue[0] === "ProblemAcceptSolution") {
        const number_29 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(6), number_29];
    }
    else if (matchValue[0] === "ProblemClose") {
        const number_30 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(7), number_30];
    }
    else if (matchValue[0] === "ProblemCancel") {
        const number_31 = matchValue[1] | 0;
        return [new HelpdeskType(3), new Operation(8), number_31];
    }
    else {
        throw (new Error("Unknown Role Access Level Combination"));
    }
}

export function ProjectAccessModule_currentProjectAccesses(pas, projectId) {
    const pa_1 = find((pa) => (pa.Project.Id === projectId), pas);
    return pa_1.AccessRights;
}

export function ProjectAccessModule_hasAcessToServiceDesk(currentProjectAccesses) {
    return exists((acl_1) => (acl_1 > 200000000), map((tupledArg) => {
        const acl = tupledArg[2] | 0;
        return acl | 0;
    }, currentProjectAccesses));
}

