import { ServiceDeskMsg } from "./Types.js";
import { render as render_1 } from "../ErrorPage/ErrorPage.js";
import { ofArray, find, singleton } from "../../../.fable/fable-library.3.0.0/List.js";
import { render as render_2 } from "./ServiceDesksTable.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { render as render_3 } from "./ServiceDeskDetail.js";
import { render as render_4 } from "./ServiceDeskCreate.js";
import { render as render_5 } from "../Preloader/Preloader.js";

export function render(state, dispatch, onServiceDeskQuit) {
    const matchValue = state.ComponentState;
    switch (matchValue.tag) {
        case 2: {
            const errorMessages = matchValue.fields[0];
            const onResetError = () => {
                dispatch(new ServiceDeskMsg(15));
            };
            return render_1(errorMessages, onResetError);
        }
        case 0: {
            let sedTableCmp;
            const matchValue_1 = state.RelatedPbsData;
            if (matchValue_1 == null) {
                sedTableCmp = render_1(singleton("Failed to obtain service desk related data."), void 0);
            }
            else {
                const rd = matchValue_1;
                sedTableCmp = render_2(state.ServiceDesksList, rd.Project.Id, rd.ParentPbsId.Id, state.SelectedSeriveDeskId, state.ServiceDeskCreate != null, dispatch, onServiceDeskQuit);
            }
            const matchValue_2 = state.ServiceDeskCreate;
            if (matchValue_2 == null) {
                const matchValue_3 = state.SelectedSeriveDeskId;
                if (matchValue_3 == null) {
                    return createElement("div", {
                        children: Interop_reactApi.Children.toArray([sedTableCmp]),
                    });
                }
                else {
                    const selectedServiceDeskId = matchValue_3;
                    const serviceDesk = find((sd) => (sd.Id === selectedServiceDeskId), state.ServiceDesksList);
                    const serviceDeskDetailCmp = render_3(serviceDesk, dispatch);
                    const children = ofArray([serviceDeskDetailCmp, createElement("br", {}), sedTableCmp]);
                    return createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    });
                }
            }
            else {
                const sdtcr = matchValue_2;
                const sedCreateCmp = createElement(render_4, {
                    uploadEndpoint: state.ServiceDeskRestAddresses.ServiceDeskUploadAddress(),
                    sdtcr: sdtcr,
                    dispatch: dispatch,
                });
                return createElement("div", {
                    children: Interop_reactApi.Children.toArray([sedCreateCmp, createElement("br", {}), sedTableCmp]),
                });
            }
        }
        default: {
            return render_5("");
        }
    }
}

