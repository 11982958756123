import { fetch$, Types_RequestProperties } from "../../../.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { ofArray, singleton } from "../../../.fable/fable-library.3.0.0/List.js";
import { createTypeInfo } from "../../../.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { Pbs$reflection, PbsToEdit$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { Convert_fromJson, Convert_serialize } from "../../../.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../.fable/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../.fable/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { SimpleJson_tryParse } from "../../../.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { class_type, union_type, string_type, tuple_type, list_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { Cmd_none, Cmd_OfPromise_either } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsListMsg } from "./Types.js";

export const getRequestProps = singleton(new Types_RequestProperties(4, "include"));

export function postRequestProps(jsonObject) {
    let serializedObj;
    const typeInfo = createTypeInfo(PbsToEdit$reflection());
    serializedObj = Convert_serialize(jsonObject, typeInfo);
    return ofArray([new Types_RequestProperties(1, {
        ["Content-Type"]: "application/json",
    }), new Types_RequestProperties(4, "include"), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function fetchPbses(address, projectId) {
    const request = (projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1);
        return fetch$(address_1, getRequestProps).then(((_arg1) => {
            const res = _arg1;
            return res.text().then(((_arg2) => {
                let matchValue, inputJson, typeInfo;
                const txt = _arg2;
                return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection()))]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                    throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                })()));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, projectId, (arg0) => (new PbsListMsg(3, arg0)), (arg0_1) => (new PbsListMsg(30, arg0_1)));
}

export function fetchTranslations(address, projectId, languageId) {
    return Cmd_none();
}

export function postCreatePbsTranslation(address, createTranslationParams) {
    return Cmd_none();
}

export function postUpdatePbs(address, saveAndClose, pbsToUpdate) {
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then(((_arg1) => {
            const res = _arg1;
            return res.text().then(((_arg2) => {
                let matchValue, inputJson, typeInfo;
                const txt = _arg2;
                return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                    throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                })()));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(25, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsTranslation(address, saveAndClose, pbsToUpdate) {
    return Cmd_none();
}

export function postUpdatePbsCustomerDescription(address, pbsToUpdate) {
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then(((_arg1) => {
            const res = _arg1;
            return res.text().then(((_arg2) => {
                let matchValue, inputJson, typeInfo;
                const txt = _arg2;
                return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                    throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                })()));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, pbsToUpdate, (arg0) => (new PbsListMsg(17, arg0)), (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

export function postResetCustomerDescription(address, pbsToUpdate) {
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address();
        return fetch$(address_1, postRequestProps(pbsToUpdate_1)).then(((_arg1) => {
            const res = _arg1;
            return res.text().then(((_arg2) => {
                let matchValue, inputJson, typeInfo;
                const txt = _arg2;
                return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                    throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                })()));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, pbsToUpdate, (arg0) => (new PbsListMsg(17, arg0)), (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

