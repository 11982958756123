import { ComponentState as ComponentState_8 } from "../../Types.js";
import { ServiceDeskMsg, ServiceDeskComponentState } from "./Types.js";
import { createIncident, fetchIncidentsRelatedToProcess } from "./REST.js";
import { newGuid } from "../../../.fable/fable-library.3.0.0/Guid.js";
import { isNullOrWhiteSpace, isNullOrEmpty, interpolate, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { RelatedPbsData as RelatedPbsData_1, ServiceDeskToCreate } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Sed.Types.js";
import { Cmd_OfFunc_result, Cmd_none } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty, ofArray, singleton } from "../../../.fable/fable-library.3.0.0/List.js";
import { RouterModule_nav } from "../../../.fable/Feliz.Router.3.7.0/Router.fs.js";
import { int32ToString } from "../../../.fable/fable-library.3.0.0/Util.js";
import { Result_Map, FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { ValidationCE_validation, ValidationCE_ValidationBuilder__Delay_Z29103BAD, ValidationCE_ValidationBuilder__Run_Z29103BAD } from "../../../.fable/FsToolkit.ErrorHandling.2.0.0/ValidationCE.fs.js";
import { Validation_ofResult, Validation_zip } from "../../../.fable/FsToolkit.ErrorHandling.2.0.0/Validation.fs.js";

export function update(msg, state) {
    let proIdStr_1, pbsIdStr_1, sedIdStr_1, xs_4, xs_6, xs_8, xs_10, xs_12, proIdStr, pbsIdStr, xs_2, id, sedIdStr, xs;
    switch (msg.tag) {
        case 1: {
            const selectedServiceDeskId_1 = msg.fields[1];
            const rtUrO = msg.fields[2];
            const parentPbsId_1 = msg.fields[0];
            const matchValue = state.RelatedPbsData;
            if (matchValue == null) {
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(1), state.RelatedPbsData, state.ServiceDesksList, selectedServiceDeskId_1, void 0), fetchIncidentsRelatedToProcess(state.ServiceDeskRestAddresses.FetchIncidentsRelatedToProcessAddress, rtUrO, parentPbsId_1)];
            }
            else {
                const rpd = matchValue;
                if (rpd.ParentPbsId.Id === parentPbsId_1) {
                    let serviceDeskCreateO;
                    if (rtUrO == null) {
                        serviceDeskCreateO = (void 0);
                    }
                    else {
                        const urgency = rtUrO[1] | 0;
                        const requestType = rtUrO[0] | 0;
                        const sdtcr = new ServiceDeskToCreate(newGuid(), "", "", requestType, urgency, rpd.ParentPbsId, rpd.Project, rpd.ProjectServiceId, rpd.ProjectServiceModuleId, rpd.UserInfo.ParentCustomerReference, new XrmEntityReference(rpd.UserInfo.Id, toText(interpolate("%P() %P()", [rpd.UserInfo.FirstName, rpd.UserInfo.LastName]))));
                        serviceDeskCreateO = sdtcr;
                    }
                    return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, selectedServiceDeskId_1, serviceDeskCreateO), Cmd_none()];
                }
                else {
                    return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(1), state.RelatedPbsData, state.ServiceDesksList, selectedServiceDeskId_1, void 0), fetchIncidentsRelatedToProcess(state.ServiceDeskRestAddresses.FetchIncidentsRelatedToProcessAddress, rtUrO, parentPbsId_1)];
                }
            }
        }
        case 2: {
            const rtUrO_1 = msg.fields[1];
            const result = msg.fields[0];
            if (result.tag === 1) {
                const message = result.fields[0];
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(2, singleton(message)), state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, state.ServiceDeskCreate), Cmd_none()];
            }
            else {
                const payload = result.fields[0];
                const relatedPbsData = new RelatedPbsData_1(payload.ParentPbsId, payload.ProjectReference, payload.ProjectServiceId, payload.ProjectServiceModuleId, payload.UserInfo);
                let serviceDeskCreateO_1;
                if (rtUrO_1 == null) {
                    serviceDeskCreateO_1 = (void 0);
                }
                else {
                    const urgency_1 = rtUrO_1[1] | 0;
                    const requestType_1 = rtUrO_1[0] | 0;
                    const sdtcr_1 = new ServiceDeskToCreate(newGuid(), "", "", requestType_1, urgency_1, relatedPbsData.ParentPbsId, relatedPbsData.Project, relatedPbsData.ProjectServiceId, relatedPbsData.ProjectServiceModuleId, relatedPbsData.UserInfo.ParentCustomerReference, new XrmEntityReference(relatedPbsData.UserInfo.Id, toText(interpolate("%P() %P()", [relatedPbsData.UserInfo.FirstName, relatedPbsData.UserInfo.LastName]))));
                    serviceDeskCreateO_1 = sdtcr_1;
                }
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(0), relatedPbsData, payload.ServiceDeskRequestList, state.SelectedSeriveDeskId, serviceDeskCreateO_1), Cmd_none()];
            }
        }
        case 3: {
            const serviceDeskId = msg.fields[2];
            const projectId_1 = msg.fields[0];
            const pbsId = msg.fields[1];
            return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, serviceDeskId, state.ServiceDeskCreate), (proIdStr_1 = projectId_1, (pbsIdStr_1 = pbsId, (sedIdStr_1 = serviceDeskId, (xs_4 = ["en", "project", proIdStr_1, pbsIdStr_1, "servicedesk", sedIdStr_1, "detail"], singleton((_arg5_2) => {
                RouterModule_nav(ofArray(xs_4), 1, 1);
            })))))];
        }
        case 4: {
            const projectId_2 = msg.fields[0];
            const pbsId_1 = msg.fields[1];
            return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, void 0, state.ServiceDeskCreate), Cmd_none()];
        }
        case 5: {
            const urgency_2 = msg.fields[3] | 0;
            const requestType_2 = msg.fields[2] | 0;
            const projectId_3 = msg.fields[0];
            const pbsId_2 = msg.fields[1];
            const proIdStr_2 = projectId_3;
            const pbsIdStr_2 = pbsId_2;
            const rqtStr = int32ToString(requestType_2);
            const urgStr = int32ToString(urgency_2);
            return [state, (xs_6 = ["en", "project", proIdStr_2, pbsIdStr_2, "servicedesk", "create", rqtStr, urgStr], singleton((_arg5_3) => {
                RouterModule_nav(ofArray(xs_6), 1, 1);
            }))];
        }
        case 6: {
            const urgency_3 = msg.fields[3] | 0;
            const requestType_3 = msg.fields[2] | 0;
            const projectId_4 = msg.fields[0];
            const pbsId_3 = msg.fields[1];
            const matchValue_2 = state.RelatedPbsData;
            if (matchValue_2 == null) {
                return [state, empty()];
            }
            else {
                const rd = matchValue_2;
                const sdtcr_2 = new ServiceDeskToCreate(newGuid(), "", "", requestType_3, urgency_3, rd.ParentPbsId, rd.Project, rd.ProjectServiceId, rd.ProjectServiceModuleId, rd.UserInfo.ParentCustomerReference, new XrmEntityReference(rd.UserInfo.Id, toText(interpolate("%P() %P()", [rd.UserInfo.FirstName, rd.UserInfo.LastName]))));
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, sdtcr_2), empty()];
            }
        }
        case 8: {
            const title = msg.fields[0];
            const matchValue_3 = state.ServiceDeskCreate;
            if (matchValue_3 != null) {
                const sdtcr_3 = matchValue_3;
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, new ServiceDeskToCreate(sdtcr_3.Id, sdtcr_3.Description, title, sdtcr_3.RequestType, sdtcr_3.Urgency, sdtcr_3.ParentPbsId, sdtcr_3.ProjectId, sdtcr_3.ProjectServiceId, sdtcr_3.ProjectServiceModuleId, sdtcr_3.AccountId, sdtcr_3.ContactId)), empty()];
            }
            else {
                return [state, empty()];
            }
        }
        case 9: {
            const description = msg.fields[0];
            const matchValue_4 = state.ServiceDeskCreate;
            if (matchValue_4 != null) {
                const sdtcr_4 = matchValue_4;
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, new ServiceDeskToCreate(sdtcr_4.Id, description, sdtcr_4.Title, sdtcr_4.RequestType, sdtcr_4.Urgency, sdtcr_4.ParentPbsId, sdtcr_4.ProjectId, sdtcr_4.ProjectServiceId, sdtcr_4.ProjectServiceModuleId, sdtcr_4.AccountId, sdtcr_4.ContactId)), empty()];
            }
            else {
                return [state, empty()];
            }
        }
        case 10: {
            const urgency_4 = msg.fields[3] | 0;
            const requestType_4 = msg.fields[2] | 0;
            const projectId_5 = msg.fields[0];
            const pbsId_4 = msg.fields[1];
            const proIdStr_3 = projectId_5;
            const pbsIdStr_3 = pbsId_4;
            const rqtStr_1 = int32ToString(requestType_4);
            const urgStr_1 = int32ToString(urgency_4);
            return [state, (xs_8 = ["en", "project", proIdStr_3, pbsIdStr_3, "servicedesk", "create", rqtStr_1, urgStr_1], singleton((_arg5_4) => {
                RouterModule_nav(ofArray(xs_8), 1, 1);
            }))];
        }
        case 11: {
            const urgency_5 = msg.fields[3] | 0;
            const requestType_5 = msg.fields[2] | 0;
            const projectId_6 = msg.fields[0];
            const pbsId_5 = msg.fields[1];
            const proIdStr_4 = projectId_6;
            const pbsIdStr_4 = pbsId_5;
            const rqtStr_2 = int32ToString(requestType_5);
            const urgStr_2 = int32ToString(urgency_5);
            return [state, (xs_10 = ["en", "project", proIdStr_4, pbsIdStr_4, "servicedesk", "create", rqtStr_2, urgStr_2], singleton((_arg5_5) => {
                RouterModule_nav(ofArray(xs_10), 1, 1);
            }))];
        }
        case 7: {
            const projectId_7 = msg.fields[0];
            const pbsId_6 = msg.fields[1];
            const proIdStr_5 = projectId_7;
            const pbsIdStr_5 = pbsId_6;
            return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, void 0), (xs_12 = ["en", "project", proIdStr_5, pbsIdStr_5, "servicedesk"], singleton((_arg5_6) => {
                RouterModule_nav(ofArray(xs_12), 1, 1);
            }))];
        }
        case 12: {
            const serviceDeskToCreate = msg.fields[0];
            const validateDescription = (d) => {
                let d_1, d_3, d_5;
                if (d_1 = d, d_1.length > 20000) {
                    const d_2 = d;
                    return new FSharpResult$2(1, "Description can not be longer than 20 000 characters");
                }
                else if (d_3 = d, isNullOrEmpty(d_3)) {
                    const d_4 = d;
                    return new FSharpResult$2(1, "Description can not be empty");
                }
                else if (d_5 = d, isNullOrWhiteSpace(d_5)) {
                    const d_6 = d;
                    return new FSharpResult$2(1, "Description can not be whitespace");
                }
                else {
                    return new FSharpResult$2(0, d);
                }
            };
            const validateTitle = (d_7) => {
                let d_8, d_10, d_12;
                if (d_8 = d_7, d_8.length > 200) {
                    const d_9 = d_7;
                    return new FSharpResult$2(1, "Title can not be longer than 20 000 characters");
                }
                else if (d_10 = d_7, isNullOrEmpty(d_10)) {
                    const d_11 = d_7;
                    return new FSharpResult$2(1, "Title can not be empty");
                }
                else if (d_12 = d_7, isNullOrWhiteSpace(d_12)) {
                    const d_13 = d_7;
                    return new FSharpResult$2(1, "Title can not be whitespace");
                }
                else {
                    return new FSharpResult$2(0, d_7);
                }
            };
            const validatedServiceDesk = (sdtcr_5) => ValidationCE_ValidationBuilder__Run_Z29103BAD(ValidationCE_validation, ValidationCE_ValidationBuilder__Delay_Z29103BAD(ValidationCE_validation, () => {
                let t1, t2;
                return Result_Map((_arg1) => {
                    const vTitle = _arg1[0];
                    const vDescription = _arg1[1];
                    return sdtcr_5;
                }, (t1 = validateTitle(sdtcr_5.Title), (t2 = validateDescription(sdtcr_5.Description), Validation_zip(Validation_ofResult(t1), Validation_ofResult(t2)))));
            }));
            const matchValue_5 = validatedServiceDesk(serviceDeskToCreate);
            if (matchValue_5.tag === 0) {
                const sdtcr_6 = matchValue_5.fields[0];
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(1), state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, state.ServiceDeskCreate), createIncident(state.ServiceDeskRestAddresses.CreateIncidentAddress, serviceDeskToCreate)];
            }
            else {
                const errorList = matchValue_5.fields[0];
                return [state, Cmd_OfFunc_result(new ServiceDeskMsg(14, errorList))];
            }
        }
        case 13: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 1) {
                const message_1 = result_1.fields[0];
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(2, singleton(message_1)), state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, state.ServiceDeskCreate), Cmd_none()];
            }
            else {
                const serviceDeskId_1 = result_1.fields[0][2];
                const projectId_8 = result_1.fields[0][0];
                const pbsId_7 = result_1.fields[0][1];
                return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, state.ComponentState, state.RelatedPbsData, state.ServiceDesksList, serviceDeskId_1, void 0), fetchIncidentsRelatedToProcess(state.ServiceDeskRestAddresses.FetchIncidentsRelatedToProcessAddress, void 0, pbsId_7)];
            }
        }
        case 16: {
            const exn = msg.fields[0];
            return [state, Cmd_OfFunc_result(new ServiceDeskMsg(14, singleton(exn.message)))];
        }
        case 14: {
            const errorMessages = msg.fields[0];
            return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(2, errorMessages), state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, state.ServiceDeskCreate), Cmd_none()];
        }
        case 15: {
            return [new ServiceDeskComponentState(state.ServiceDeskRestAddresses, new ComponentState_8(0), state.RelatedPbsData, state.ServiceDesksList, state.SelectedSeriveDeskId, state.ServiceDeskCreate), Cmd_none()];
        }
        default: {
            const selectedServiceDeskId = msg.fields[2];
            const projectId = msg.fields[0];
            const parentPbsId = msg.fields[1];
            return [state, (proIdStr = projectId, (pbsIdStr = parentPbsId, (selectedServiceDeskId == null) ? (xs_2 = ["en", "project", proIdStr, pbsIdStr, "servicedesk"], singleton((_arg5_1) => {
                RouterModule_nav(ofArray(xs_2), 1, 1);
            })) : (id = selectedServiceDeskId, (sedIdStr = id, (xs = ["en", "project", proIdStr, pbsIdStr, "servicedesk", sedIdStr, "detail"], singleton((_arg5) => {
                RouterModule_nav(ofArray(xs), 1, 1);
            }))))))];
        }
    }
}

