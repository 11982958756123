import { class_type } from "../fable-library.3.0.0/Reflection.js";
import { Validation_bind, Validation_ok } from "./Validation.fs.js";
import { getEnumerator } from "../fable-library.3.0.0/Seq.js";

export class ValidationCE_ValidationBuilder {
    constructor() {
    }
}

export function ValidationCE_ValidationBuilder$reflection() {
    return class_type("FsToolkit.ErrorHandling.ValidationCE.ValidationBuilder", void 0, ValidationCE_ValidationBuilder);
}

export function ValidationCE_ValidationBuilder_$ctor() {
    return new ValidationCE_ValidationBuilder();
}

export function ValidationCE_ValidationBuilder__Return_1505(__, value) {
    return Validation_ok(value);
}

export function ValidationCE_ValidationBuilder__Zero(this$) {
    return ValidationCE_ValidationBuilder__Return_1505(this$);
}

export function ValidationCE_ValidationBuilder__Delay_Z29103BAD(__, generator) {
    return generator;
}

export function ValidationCE_ValidationBuilder__Run_Z29103BAD(__, generator) {
    return generator();
}

export function ValidationCE_ValidationBuilder__Combine_68A899F3(this$, result, binder) {
    return Validation_bind(binder, result);
}

export function ValidationCE_ValidationBuilder__TryWith_69603625(this$, generator, handler) {
    try {
        return ValidationCE_ValidationBuilder__Run_Z29103BAD(this$, generator);
    }
    catch (e) {
        return handler(e);
    }
}

export function ValidationCE_ValidationBuilder__TryFinally_Z714CD51B(this$, generator, compensation) {
    try {
        return ValidationCE_ValidationBuilder__Run_Z29103BAD(this$, generator);
    }
    finally {
        compensation();
    }
}

export function ValidationCE_ValidationBuilder__Using_Z79930DF1(this$, resource, binder) {
    return ValidationCE_ValidationBuilder__TryFinally_Z714CD51B(this$, () => binder(resource), () => {
        if (!(resource === null)) {
            let copyOfStruct = resource;
            copyOfStruct.Dispose();
        }
    });
}

export function ValidationCE_ValidationBuilder__While_Z33164FF6(this$, guard, generator) {
    if (!guard()) {
        return ValidationCE_ValidationBuilder__Zero(this$);
    }
    else {
        return Validation_bind(() => ValidationCE_ValidationBuilder__While_Z33164FF6(this$, guard, generator), ValidationCE_ValidationBuilder__Run_Z29103BAD(this$, generator));
    }
}

export function ValidationCE_ValidationBuilder__For_2935693B(this$, sequence, binder) {
    let copyOfStruct;
    return ValidationCE_ValidationBuilder__Using_Z79930DF1(this$, (copyOfStruct = sequence, getEnumerator(copyOfStruct)), (enum$) => ValidationCE_ValidationBuilder__While_Z33164FF6(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), ValidationCE_ValidationBuilder__Delay_Z29103BAD(this$, () => binder(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export const ValidationCE_validation = ValidationCE_ValidationBuilder_$ctor();

