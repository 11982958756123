import { PbsTreeMenuMsg } from "./Types.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { sortBy, map } from "../../../.fable/fable-library.3.0.0/List.js";
import { comparePrimitives } from "../../../.fable/fable-library.3.0.0/Util.js";

export function render(productModules, selectedServiceModuleRef, dispatch) {
    const onModuleSelect = (id) => {
        dispatch(new PbsTreeMenuMsg(7, id));
    };
    const onModuleDeselect = (_arg1) => {
        dispatch(new PbsTreeMenuMsg(8));
    };
    const productModuleButton = (productModule) => {
        let patternInput;
        if (selectedServiceModuleRef != null) {
            const moduleReference = selectedServiceModuleRef;
            patternInput = ((moduleReference.Id === productModule.Id) ? ["btn btn-margin-1 btn-sm btn-secondary", (_arg3) => {
                onModuleDeselect();
            }] : ["btn btn-margin-1 btn-sm btn-outline-secondary", (_arg4) => {
                onModuleSelect(productModule);
            }]);
        }
        else {
            patternInput = ["btn btn-margin-1 btn-sm btn-outline-secondary", (_arg2) => {
                onModuleSelect(productModule);
            }];
        }
        const onClickFn = patternInput[1];
        const css = patternInput[0];
        return createElement("button", {
            className: css,
            onClick: onClickFn,
            children: Interop_reactApi.Children.toArray([createElement("a", {
                children: productModule.Name,
            })]),
        });
    };
    return createElement("div", {
        className: "",
        children: Interop_reactApi.Children.toArray(Array.from(map(productModuleButton, sortBy((pm) => pm.Name, productModules, {
            Compare: comparePrimitives,
        })))),
    });
}

