import { Msg, State, Route } from "./Types.js";
import { Route_$007CInt$007C_$007C, Route_$007CGuid$007C_$007C } from "../.fable/Feliz.Router.3.7.0/Router.fs.js";
import { Cmd_none, Cmd_batch, Cmd_OfFunc_result } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsTreeMenuMsg } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { PbsListMsg } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { ofArray } from "../.fable/fable-library.3.0.0/List.js";
import { CookieSessionMsg } from "../Aimtec.Components.React/Components/CookieSession/Types.js";
import { ServiceDeskMsg } from "../Aimtec.Components.React/Components/ServiceDesk/Types.js";

export function parseRoute(_arg1) {
    let pattern_matching_result, projectId;
    if (_arg1.tail != null) {
        if (_arg1.head === "en") {
            if (_arg1.tail.tail != null) {
                if (_arg1.tail.head === "projects") {
                    if (_arg1.tail.tail.tail == null) {
                        pattern_matching_result = 1;
                    }
                    else {
                        pattern_matching_result = 3;
                    }
                }
                else if (_arg1.tail.head === "project") {
                    if (_arg1.tail.tail.tail != null) {
                        const activePatternResult15978 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.head);
                        if (activePatternResult15978 != null) {
                            if (_arg1.tail.tail.tail.tail == null) {
                                pattern_matching_result = 2;
                                projectId = activePatternResult15978;
                            }
                            else {
                                pattern_matching_result = 3;
                            }
                        }
                        else {
                            pattern_matching_result = 3;
                        }
                    }
                    else {
                        pattern_matching_result = 3;
                    }
                }
                else {
                    pattern_matching_result = 3;
                }
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Route(0);
        }
        case 1: {
            return new Route(0);
        }
        case 2: {
            return new Route(1, projectId);
        }
        case 3: {
            let pattern_matching_result_1, projectId_1, selectedProcessId;
            if (_arg1.tail != null) {
                if (_arg1.head === "en") {
                    if (_arg1.tail.tail != null) {
                        if (_arg1.tail.head === "project") {
                            if (_arg1.tail.tail.tail != null) {
                                const activePatternResult15976 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.head);
                                if (activePatternResult15976 != null) {
                                    if (_arg1.tail.tail.tail.tail != null) {
                                        const activePatternResult15977 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.tail.head);
                                        if (activePatternResult15977 != null) {
                                            if (_arg1.tail.tail.tail.tail.tail == null) {
                                                pattern_matching_result_1 = 0;
                                                projectId_1 = activePatternResult15976;
                                                selectedProcessId = activePatternResult15977;
                                            }
                                            else {
                                                pattern_matching_result_1 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_1 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return new Route(2, projectId_1, selectedProcessId);
                }
                case 1: {
                    let pattern_matching_result_2, projectId_2, selectedProcessId_1;
                    if (_arg1.tail != null) {
                        if (_arg1.head === "en") {
                            if (_arg1.tail.tail != null) {
                                if (_arg1.tail.head === "project") {
                                    if (_arg1.tail.tail.tail != null) {
                                        const activePatternResult15974 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.head);
                                        if (activePatternResult15974 != null) {
                                            if (_arg1.tail.tail.tail.tail != null) {
                                                const activePatternResult15975 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.tail.head);
                                                if (activePatternResult15975 != null) {
                                                    if (_arg1.tail.tail.tail.tail.tail != null) {
                                                        if (_arg1.tail.tail.tail.tail.head === "servicedesk") {
                                                            if (_arg1.tail.tail.tail.tail.tail.tail == null) {
                                                                pattern_matching_result_2 = 0;
                                                                projectId_2 = activePatternResult15974;
                                                                selectedProcessId_1 = activePatternResult15975;
                                                            }
                                                            else {
                                                                pattern_matching_result_2 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_2 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_2 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_2 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_2 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_2 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return new Route(3, projectId_2, selectedProcessId_1);
                        }
                        case 1: {
                            let pattern_matching_result_3, projectId_3, selectedProcessId_2, selectedServiceDeskId;
                            if (_arg1.tail != null) {
                                if (_arg1.head === "en") {
                                    if (_arg1.tail.tail != null) {
                                        if (_arg1.tail.head === "project") {
                                            if (_arg1.tail.tail.tail != null) {
                                                const activePatternResult15971 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.head);
                                                if (activePatternResult15971 != null) {
                                                    if (_arg1.tail.tail.tail.tail != null) {
                                                        const activePatternResult15972 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.tail.head);
                                                        if (activePatternResult15972 != null) {
                                                            if (_arg1.tail.tail.tail.tail.tail != null) {
                                                                if (_arg1.tail.tail.tail.tail.head === "servicedesk") {
                                                                    if (_arg1.tail.tail.tail.tail.tail.tail != null) {
                                                                        const activePatternResult15973 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.tail.tail.tail.head);
                                                                        if (activePatternResult15973 != null) {
                                                                            if (_arg1.tail.tail.tail.tail.tail.tail.tail != null) {
                                                                                if (_arg1.tail.tail.tail.tail.tail.tail.head === "detail") {
                                                                                    if (_arg1.tail.tail.tail.tail.tail.tail.tail.tail == null) {
                                                                                        pattern_matching_result_3 = 0;
                                                                                        projectId_3 = activePatternResult15971;
                                                                                        selectedProcessId_2 = activePatternResult15972;
                                                                                        selectedServiceDeskId = activePatternResult15973;
                                                                                    }
                                                                                    else {
                                                                                        pattern_matching_result_3 = 1;
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    pattern_matching_result_3 = 1;
                                                                                }
                                                                            }
                                                                            else {
                                                                                pattern_matching_result_3 = 1;
                                                                            }
                                                                        }
                                                                        else {
                                                                            pattern_matching_result_3 = 1;
                                                                        }
                                                                    }
                                                                    else {
                                                                        pattern_matching_result_3 = 1;
                                                                    }
                                                                }
                                                                else {
                                                                    pattern_matching_result_3 = 1;
                                                                }
                                                            }
                                                            else {
                                                                pattern_matching_result_3 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_3 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_3 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_3 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_3 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    return new Route(4, projectId_3, selectedProcessId_2, selectedServiceDeskId);
                                }
                                case 1: {
                                    let pattern_matching_result_4, projectId_4, requestType, selectedProcessId_3, urgency;
                                    if (_arg1.tail != null) {
                                        if (_arg1.head === "en") {
                                            if (_arg1.tail.tail != null) {
                                                if (_arg1.tail.head === "project") {
                                                    if (_arg1.tail.tail.tail != null) {
                                                        const activePatternResult15967 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.head);
                                                        if (activePatternResult15967 != null) {
                                                            if (_arg1.tail.tail.tail.tail != null) {
                                                                const activePatternResult15968 = Route_$007CGuid$007C_$007C(_arg1.tail.tail.tail.head);
                                                                if (activePatternResult15968 != null) {
                                                                    if (_arg1.tail.tail.tail.tail.tail != null) {
                                                                        if (_arg1.tail.tail.tail.tail.head === "servicedesk") {
                                                                            if (_arg1.tail.tail.tail.tail.tail.tail != null) {
                                                                                if (_arg1.tail.tail.tail.tail.tail.head === "create") {
                                                                                    if (_arg1.tail.tail.tail.tail.tail.tail.tail != null) {
                                                                                        const activePatternResult15969 = Route_$007CInt$007C_$007C(_arg1.tail.tail.tail.tail.tail.tail.head);
                                                                                        if (activePatternResult15969 != null) {
                                                                                            if (_arg1.tail.tail.tail.tail.tail.tail.tail.tail != null) {
                                                                                                const activePatternResult15970 = Route_$007CInt$007C_$007C(_arg1.tail.tail.tail.tail.tail.tail.tail.head);
                                                                                                if (activePatternResult15970 != null) {
                                                                                                    if (_arg1.tail.tail.tail.tail.tail.tail.tail.tail.tail == null) {
                                                                                                        pattern_matching_result_4 = 0;
                                                                                                        projectId_4 = activePatternResult15967;
                                                                                                        requestType = activePatternResult15969;
                                                                                                        selectedProcessId_3 = activePatternResult15968;
                                                                                                        urgency = activePatternResult15970;
                                                                                                    }
                                                                                                    else {
                                                                                                        pattern_matching_result_4 = 1;
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    pattern_matching_result_4 = 1;
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                pattern_matching_result_4 = 1;
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            pattern_matching_result_4 = 1;
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        pattern_matching_result_4 = 1;
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    pattern_matching_result_4 = 1;
                                                                                }
                                                                            }
                                                                            else {
                                                                                pattern_matching_result_4 = 1;
                                                                            }
                                                                        }
                                                                        else {
                                                                            pattern_matching_result_4 = 1;
                                                                        }
                                                                    }
                                                                    else {
                                                                        pattern_matching_result_4 = 1;
                                                                    }
                                                                }
                                                                else {
                                                                    pattern_matching_result_4 = 1;
                                                                }
                                                            }
                                                            else {
                                                                pattern_matching_result_4 = 1;
                                                            }
                                                        }
                                                        else {
                                                            pattern_matching_result_4 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_4 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_4 = 1;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_4 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_4 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                    switch (pattern_matching_result_4) {
                                        case 0: {
                                            return new Route(5, projectId_4, selectedProcessId_3, requestType, urgency);
                                        }
                                        case 1: {
                                            return new Route(6);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export function routeCommander(route, state) {
    let startMenuCmd, tupledArg, startPbsCmd, tupledArg_1, commandsBatch, matchValue_1, s_1, startMenuCmd_1, tupledArg_2, startPbsCmd_1, tupledArg_3, commandsBatch_1, matchValue_2, s_2, startMenuCmd_2, tupledArg_4, startPbsCmd_2, tupledArg_5, startSedCmd, tupledArg_6, commandsBatch_2, matchValue_3, s_3, startMenuCmd_3, tupledArg_7, startPbsCmd_3, tupledArg_8, startSedCmd_1, tupledArg_9, commandsBatch_3, matchValue_4, s_4, startMenuCmd_4, tupledArg_10, startPbsCmd_4, tupledArg_11, rt, ur, startSedCmd_2, tupledArg_12, commandsBatch_4, matchValue_5, s_5, matchValue, s;
    switch (route.tag) {
        case 1: {
            const projectId = route.fields[0];
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (startMenuCmd = Cmd_OfFunc_result(new Msg(10, (tupledArg = [projectId, void 0], new PbsTreeMenuMsg(2, tupledArg[0], tupledArg[1])))), (startPbsCmd = Cmd_OfFunc_result(new Msg(14, (tupledArg_1 = [projectId, void 0], new PbsListMsg(2, tupledArg_1[0], tupledArg_1[1])))), (commandsBatch = Cmd_batch(ofArray([startMenuCmd, startPbsCmd])), (matchValue_1 = state.CookieSessionState, (matchValue_1 == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s_1 = matchValue_1, commandsBatch)))))];
        }
        case 2: {
            const selectedProcessId = route.fields[1];
            const projectId_1 = route.fields[0];
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (startMenuCmd_1 = Cmd_OfFunc_result(new Msg(10, (tupledArg_2 = [projectId_1, selectedProcessId], new PbsTreeMenuMsg(2, tupledArg_2[0], tupledArg_2[1])))), (startPbsCmd_1 = Cmd_OfFunc_result(new Msg(14, (tupledArg_3 = [projectId_1, selectedProcessId], new PbsListMsg(2, tupledArg_3[0], tupledArg_3[1])))), (commandsBatch_1 = Cmd_batch(ofArray([startMenuCmd_1, startPbsCmd_1])), (matchValue_2 = state.CookieSessionState, (matchValue_2 == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s_2 = matchValue_2, commandsBatch_1)))))];
        }
        case 3: {
            const selectedProcessId_1 = route.fields[1];
            const projectId_2 = route.fields[0];
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (startMenuCmd_2 = Cmd_OfFunc_result(new Msg(10, (tupledArg_4 = [projectId_2, selectedProcessId_1], new PbsTreeMenuMsg(2, tupledArg_4[0], tupledArg_4[1])))), (startPbsCmd_2 = Cmd_OfFunc_result(new Msg(14, (tupledArg_5 = [projectId_2, selectedProcessId_1], new PbsListMsg(2, tupledArg_5[0], tupledArg_5[1])))), (startSedCmd = Cmd_OfFunc_result(new Msg(12, (tupledArg_6 = [selectedProcessId_1, void 0, void 0], new ServiceDeskMsg(1, tupledArg_6[0], tupledArg_6[1], tupledArg_6[2])))), (commandsBatch_2 = Cmd_batch(ofArray([startMenuCmd_2, startPbsCmd_2, startSedCmd])), (matchValue_3 = state.CookieSessionState, (matchValue_3 == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s_3 = matchValue_3, commandsBatch_2))))))];
        }
        case 4: {
            const selectedServiceDeskId = route.fields[2];
            const selectedProcessId_2 = route.fields[1];
            const projectId_3 = route.fields[0];
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (startMenuCmd_3 = Cmd_OfFunc_result(new Msg(10, (tupledArg_7 = [projectId_3, selectedProcessId_2], new PbsTreeMenuMsg(2, tupledArg_7[0], tupledArg_7[1])))), (startPbsCmd_3 = Cmd_OfFunc_result(new Msg(14, (tupledArg_8 = [projectId_3, selectedProcessId_2], new PbsListMsg(2, tupledArg_8[0], tupledArg_8[1])))), (startSedCmd_1 = Cmd_OfFunc_result(new Msg(12, (tupledArg_9 = [selectedProcessId_2, selectedServiceDeskId, void 0], new ServiceDeskMsg(1, tupledArg_9[0], tupledArg_9[1], tupledArg_9[2])))), (commandsBatch_3 = Cmd_batch(ofArray([startMenuCmd_3, startPbsCmd_3, startSedCmd_1])), (matchValue_4 = state.CookieSessionState, (matchValue_4 == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s_4 = matchValue_4, commandsBatch_3))))))];
        }
        case 5: {
            const urgency = route.fields[3] | 0;
            const selectedProcessId_3 = route.fields[1];
            const requestType = route.fields[2] | 0;
            const projectId_4 = route.fields[0];
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (startMenuCmd_4 = Cmd_OfFunc_result(new Msg(10, (tupledArg_10 = [projectId_4, selectedProcessId_3], new PbsTreeMenuMsg(2, tupledArg_10[0], tupledArg_10[1])))), (startPbsCmd_4 = Cmd_OfFunc_result(new Msg(14, (tupledArg_11 = [projectId_4, selectedProcessId_3], new PbsListMsg(2, tupledArg_11[0], tupledArg_11[1])))), (rt = (requestType | 0), (ur = (urgency | 0), (startSedCmd_2 = Cmd_OfFunc_result(new Msg(12, (tupledArg_12 = [selectedProcessId_3, void 0, [rt, ur]], new ServiceDeskMsg(1, tupledArg_12[0], tupledArg_12[1], tupledArg_12[2])))), (commandsBatch_4 = Cmd_batch(ofArray([startMenuCmd_4, startPbsCmd_4, startSedCmd_2])), (matchValue_5 = state.CookieSessionState, (matchValue_5 == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s_5 = matchValue_5, commandsBatch_4))))))))];
        }
        case 6: {
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), Cmd_none()];
        }
        default: {
            return [new State(route, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (matchValue = state.CookieSessionState, (matchValue == null) ? Cmd_OfFunc_result(new Msg(9, new CookieSessionMsg(0))) : (s = matchValue, Cmd_none()))];
        }
    }
}

