import { routeCommander, parseRoute } from "./Router.js";
import { RouterModule_nav, RouterModule_urlSegments } from "../.fable/Feliz.Router.3.7.0/Router.fs.js";
import { Msg, State, State_get_Empty } from "./Types.js";
import { find, empty, singleton, ofArray } from "../.fable/fable-library.3.0.0/List.js";
import { map } from "../.fable/fable-library.3.0.0/Option.js";
import { PbsTreeMenuMsg, PbsMenuRestAddresses as PbsMenuRestAddresses_1, PbsTreeMenuState as PbsTreeMenuState_5 } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { PbsListRestAddresses, AccessToFunctions as AccessToFunctions_1, PbsListMsg, PbsListComponentState } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { Navigation_modifyUrl } from "../.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.0.0/String.js";
import { ServiceDeskApiAddress_serviceDeskUploadAddress, ServiceDeskApiAddress_createIncidentAddress, ServiceDeskApiAddress_fetchIncidentsRelatedToProcessAddress, PbsListApiAddress_postResetPbsCustomerDescriptionAddress, PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress, PbsListApiAddress_postUpdatePbsAddress, PbsListApiAddress_fetchPbsTranslationsAddress, PbsListApiAddress_fetchPbsesAddress, PbsMenuApiAddress_fetchPbsChildrenAddress, PbsMenuApiAddress_fetchProjectAddress, SessionApiAddress_fetchSessionAddress, SessionApiAddress_serviceDeskUrl } from "../Configuration.js";
import { CookieSessionState as CookieSessionState_5, CookieSessionAddresses } from "../Aimtec.Components.React/Components/CookieSession/Types.js";
import { ComponentState as ComponentState_3 } from "../Aimtec.Components.React/Types.js";
import { update as update_1 } from "../Aimtec.Components.React/Components/CookieSession/State.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch, Cmd_map } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProjectAccessModule_hasAcessToServiceDesk } from "../AIM/Aimtec.D365.Domain/Aimtec.D365.Pas.Types.js";
import { update as update_2 } from "../Aimtec.Components.React/Components/PbsTreeMenu/State.js";
import { update as update_3 } from "../Aimtec.Components.React/Components/PbsList/State.js";
import { ServiceDeskComponentState, ServiceDeskRestAddresses as ServiceDeskRestAddresses_1 } from "../Aimtec.Components.React/Components/ServiceDesk/Types.js";
import { update as update_4 } from "../Aimtec.Components.React/Components/ServiceDesk/State.js";

export function init() {
    const initialRoute = parseRoute(RouterModule_urlSegments(window.location.hash, 1));
    let initialState;
    const inputRecord = State_get_Empty();
    initialState = (new State(initialRoute, inputRecord.CookieSessionState, inputRecord.ServiceDeskState, inputRecord.PbsTreeMenuState, inputRecord.PbsesListState));
    const patternInput = routeCommander(initialRoute, initialState);
    const state = patternInput[0];
    const initialCommand = patternInput[1];
    return [state, initialCommand];
}

export function update(msg, state) {
    let tupledArg, xs, tupledArg_1, xs_2, tupledArg_2, xs_4, tupledArg_3, xs_6, patternInput_3, state_2, cmd_1, patternInput_1, state_1, cmd, extraMsg, xs_8;
    switch (msg.tag) {
        case 1: {
            return [new State(state.CurrentRoute, state.CookieSessionState, void 0, void 0, void 0), (tupledArg = ["en", "home"], (xs = [tupledArg[0], tupledArg[1]], singleton((_arg5) => {
                RouterModule_nav(ofArray(xs), 1, 1);
            })))];
        }
        case 2: {
            return [new State(state.CurrentRoute, state.CookieSessionState, void 0, void 0, void 0), (tupledArg_1 = ["en", "projects"], (xs_2 = [tupledArg_1[0], tupledArg_1[1]], singleton((_arg5_1) => {
                RouterModule_nav(ofArray(xs_2), 1, 1);
            })))];
        }
        case 3: {
            const proId = msg.fields[0];
            const proIdStr = proId;
            return [new State(state.CurrentRoute, state.CookieSessionState, void 0, state.PbsTreeMenuState, state.PbsesListState), (tupledArg_2 = ["en", "project", proIdStr], (xs_4 = [tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]], singleton((_arg5_2) => {
                RouterModule_nav(ofArray(xs_4), 1, 1);
            })))];
        }
        case 4: {
            const projectRef = msg.fields[0];
            const projectIdStr = projectRef.Id;
            const updatedPbsTreeMenuState = map((s) => (new PbsTreeMenuState_5(s.ComponentState, s.PbsMenuRestAddresses, s.OnlineDocumentation, projectRef, s.SelectedPbsId, s.SelectedService, s.SelectedServiceModule, s.ExpandedParentPbsesId, s.FilteredMenu)), state.PbsTreeMenuState);
            const updatedPbsListState = map((s_1) => (new PbsListComponentState(s_1.RestAddresses, s_1.AccessToFunctions, s_1.ComponentState, s_1.Pbses, s_1.SelectedPbs, projectRef, s_1.SelectedService, s_1.SelectedServiceModule, s_1.SelectedLanguage, s_1.TranslationLanguages, s_1.TranslationSelectedLanguage, s_1.PbsesEdited, s_1.PbsesSaved, s_1.PbsesError, s_1.FilteredPbses, s_1.CustomerDescriptionsEdited, s_1.CustomerDescriptionsSaved, s_1.CustomerDescriptionError)), state.PbsesListState);
            return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, updatedPbsTreeMenuState, updatedPbsListState), (tupledArg_3 = ["en", "project", projectIdStr], (xs_6 = [tupledArg_3[0], tupledArg_3[1], tupledArg_3[2]], singleton((_arg5_3) => {
                RouterModule_nav(ofArray(xs_6), 1, 1);
            })))];
        }
        case 5: {
            const projectRef_1 = msg.fields[0];
            const pbsRef = msg.fields[1];
            const projectIdStr_1 = projectRef_1.Id;
            const pbsIdStr = pbsRef.Id;
            return [state, Navigation_modifyUrl(toText(interpolate("#/en/project/%P()/%P()", [projectIdStr_1, pbsIdStr])))];
        }
        case 6: {
            const serviceReference = msg.fields[0];
            const updatedPbsTreeMenuState_1 = map((s_2) => (new PbsTreeMenuState_5(s_2.ComponentState, s_2.PbsMenuRestAddresses, s_2.OnlineDocumentation, s_2.SelectedProjectId, s_2.SelectedPbsId, serviceReference, s_2.SelectedServiceModule, s_2.ExpandedParentPbsesId, s_2.FilteredMenu)), state.PbsTreeMenuState);
            const updatedPbsListState_1 = map((s_3) => (new PbsListComponentState(s_3.RestAddresses, s_3.AccessToFunctions, s_3.ComponentState, s_3.Pbses, s_3.SelectedPbs, s_3.SelectedProjectId, serviceReference, s_3.SelectedServiceModule, s_3.SelectedLanguage, s_3.TranslationLanguages, s_3.TranslationSelectedLanguage, s_3.PbsesEdited, s_3.PbsesSaved, s_3.PbsesError, s_3.FilteredPbses, s_3.CustomerDescriptionsEdited, s_3.CustomerDescriptionsSaved, s_3.CustomerDescriptionError)), state.PbsesListState);
            return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, updatedPbsTreeMenuState_1, updatedPbsListState_1), empty()];
        }
        case 7: {
            const serviceModuleReference = msg.fields[0];
            const updatedPbsTreeMenuState_2 = map((s_4) => (new PbsTreeMenuState_5(s_4.ComponentState, s_4.PbsMenuRestAddresses, s_4.OnlineDocumentation, s_4.SelectedProjectId, s_4.SelectedPbsId, s_4.SelectedService, serviceModuleReference, s_4.ExpandedParentPbsesId, s_4.FilteredMenu)), state.PbsTreeMenuState);
            const updatedPbsListState_2 = map((s_5) => (new PbsListComponentState(s_5.RestAddresses, s_5.AccessToFunctions, s_5.ComponentState, s_5.Pbses, s_5.SelectedPbs, s_5.SelectedProjectId, s_5.SelectedService, serviceModuleReference, s_5.SelectedLanguage, s_5.TranslationLanguages, s_5.TranslationSelectedLanguage, s_5.PbsesEdited, s_5.PbsesSaved, s_5.PbsesError, s_5.FilteredPbses, s_5.CustomerDescriptionsEdited, s_5.CustomerDescriptionsSaved, s_5.CustomerDescriptionError)), state.PbsesListState);
            return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, updatedPbsTreeMenuState_2, updatedPbsListState_2), empty()];
        }
        case 8: {
            const updatedPbsTreeMenuState_3 = map((s_6) => (new PbsTreeMenuState_5(s_6.ComponentState, s_6.PbsMenuRestAddresses, s_6.OnlineDocumentation, s_6.SelectedProjectId, s_6.SelectedPbsId, void 0, void 0, s_6.ExpandedParentPbsesId, void 0)), state.PbsTreeMenuState);
            const updatedPbsListState_3 = map((s_7) => (new PbsListComponentState(s_7.RestAddresses, s_7.AccessToFunctions, s_7.ComponentState, s_7.Pbses, s_7.SelectedPbs, s_7.SelectedProjectId, void 0, void 0, s_7.SelectedLanguage, s_7.TranslationLanguages, s_7.TranslationSelectedLanguage, s_7.PbsesEdited, s_7.PbsesSaved, s_7.PbsesError, empty(), s_7.CustomerDescriptionsEdited, s_7.CustomerDescriptionsSaved, s_7.CustomerDescriptionError)), state.PbsesListState);
            return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, updatedPbsTreeMenuState_3, updatedPbsListState_3), empty()];
        }
        case 17: {
            const exn = msg.fields[0];
            return [state, empty()];
        }
        case 9: {
            const sessionMsg = msg.fields[0];
            const matchValue = state.CookieSessionState;
            if (matchValue == null) {
                const sedState = new CookieSessionState_5(new CookieSessionAddresses(SessionApiAddress_serviceDeskUrl, SessionApiAddress_fetchSessionAddress), new ComponentState_3(1), void 0);
                const patternInput_2 = update_1(sessionMsg, sedState);
                const sessionExtraMsg_1 = patternInput_2[2];
                const nextSessionState_1 = patternInput_2[0];
                const nextSessionMsg_1 = patternInput_2[1];
                if (sessionExtraMsg_1.tag === 1) {
                    const session_1 = sessionExtraMsg_1.fields[0];
                    return [new State(state.CurrentRoute, nextSessionState_1, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (patternInput_3 = routeCommander(state.CurrentRoute, state), (state_2 = patternInput_3[0], (cmd_1 = patternInput_3[1], cmd_1)))];
                }
                else {
                    return [new State(state.CurrentRoute, nextSessionState_1, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0_1) => (new Msg(9, arg0_1)), nextSessionMsg_1)];
                }
            }
            else {
                const sessionState = matchValue;
                const patternInput = update_1(sessionMsg, sessionState);
                const sessionExtraMsg = patternInput[2];
                const nextSessionState = patternInput[0];
                const nextSessionMsg = patternInput[1];
                if (sessionExtraMsg.tag === 1) {
                    const session = sessionExtraMsg.fields[0];
                    return [new State(state.CurrentRoute, nextSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), (patternInput_1 = routeCommander(state.CurrentRoute, state), (state_1 = patternInput_1[0], (cmd = patternInput_1[1], cmd)))];
                }
                else {
                    return [new State(state.CurrentRoute, nextSessionState, state.ServiceDeskState, state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0) => (new Msg(9, arg0)), nextSessionMsg)];
                }
            }
        }
        case 10: {
            const menuMsg = msg.fields[0];
            const extraMessage = (pbsTreeMenuExtraCmd) => {
                let tupledArg_4, tupledArg_5;
                switch (pbsTreeMenuExtraCmd.tag) {
                    case 1: {
                        const projectReference = pbsTreeMenuExtraCmd.fields[0];
                        const pbsReference = pbsTreeMenuExtraCmd.fields[1];
                        return Cmd_batch(ofArray([Cmd_map((arg0_2) => (new Msg(14, arg0_2)), Cmd_OfFunc_result((tupledArg_4 = [projectReference, pbsReference], new PbsListMsg(4, tupledArg_4[0], tupledArg_4[1])))), Cmd_OfFunc_result((tupledArg_5 = [projectReference, pbsReference], new Msg(5, tupledArg_5[0], tupledArg_5[1])))]));
                    }
                    case 2: {
                        const serviceId = pbsTreeMenuExtraCmd.fields[0];
                        return Cmd_map((arg0_5) => (new Msg(14, arg0_5)), Cmd_OfFunc_result(new PbsListMsg(10, serviceId)));
                    }
                    case 3: {
                        const serviceModuleReference_1 = pbsTreeMenuExtraCmd.fields[0];
                        return Cmd_batch(ofArray([Cmd_map((arg0_7) => (new Msg(14, arg0_7)), Cmd_OfFunc_result(new PbsListMsg(11, serviceModuleReference_1))), Cmd_OfFunc_result(new Msg(7, serviceModuleReference_1))]));
                    }
                    case 4: {
                        const projectReference_1 = pbsTreeMenuExtraCmd.fields[0];
                        let accessLevel;
                        const matchValue_1 = state.CookieSessionState;
                        if (matchValue_1 != null) {
                            const sessionState_1 = matchValue_1;
                            const matchValue_2 = sessionState_1.OnlineDocumentationSession;
                            if (matchValue_2 != null) {
                                const ss = matchValue_2;
                                const project = find((pa) => (pa.Project.Id === projectReference_1.Project.Id), ss.ProjectAccesses);
                                const canEditCustomerDescription = project.EditCustomerDescription;
                                const hasAccessToServiceDesk = ProjectAccessModule_hasAcessToServiceDesk(project.AccessRights);
                                const matchValue_3 = [hasAccessToServiceDesk, canEditCustomerDescription];
                                accessLevel = (matchValue_3[0] ? (matchValue_3[1] ? (new AccessToFunctions_1(3)) : (new AccessToFunctions_1(1))) : (matchValue_3[1] ? (new AccessToFunctions_1(0)) : (new AccessToFunctions_1(0))));
                            }
                            else {
                                accessLevel = (new AccessToFunctions_1(0));
                            }
                        }
                        else {
                            accessLevel = (new AccessToFunctions_1(0));
                        }
                        return Cmd_map((arg0_9) => (new Msg(14, arg0_9)), Cmd_OfFunc_result(new PbsListMsg(9, accessLevel)));
                    }
                    case 5: {
                        return Cmd_OfFunc_result(new Msg(8));
                    }
                    default: {
                        return Cmd_none();
                    }
                }
            };
            const matchValue_4 = state.PbsTreeMenuState;
            if (matchValue_4 == null) {
                const menuState = new PbsTreeMenuState_5(new ComponentState_3(1), new PbsMenuRestAddresses_1(PbsMenuApiAddress_fetchProjectAddress, PbsMenuApiAddress_fetchPbsChildrenAddress), void 0, void 0, void 0, void 0, void 0, empty(), void 0);
                const patternInput_5 = update_2(void 0, menuMsg, menuState);
                const pbsTreeMenuExtraCmd_2 = patternInput_5[2];
                const nextMenuState_1 = patternInput_5[0];
                const nextMenuMsg_1 = patternInput_5[1];
                const extraMsg_1 = extraMessage(pbsTreeMenuExtraCmd_2);
                return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, nextMenuState_1, state.PbsesListState), Cmd_batch(ofArray([Cmd_map((arg0_12) => (new Msg(10, arg0_12)), nextMenuMsg_1), extraMsg_1]))];
            }
            else {
                const sedState_1 = matchValue_4;
                const patternInput_4 = update_2(void 0, menuMsg, sedState_1);
                const pbsTreeMenuExtraCmd_1 = patternInput_4[2];
                const nextMenuState = patternInput_4[0];
                const nextMenuMsg = patternInput_4[1];
                return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, nextMenuState, state.PbsesListState), (extraMsg = extraMessage(pbsTreeMenuExtraCmd_1), Cmd_batch(ofArray([Cmd_map((arg0_11) => (new Msg(10, arg0_11)), nextMenuMsg), extraMsg])))];
            }
        }
        case 11: {
            return [new State(state.CurrentRoute, state.CookieSessionState, void 0, state.PbsTreeMenuState, state.PbsesListState), empty()];
        }
        case 14: {
            const pbsMsg = msg.fields[0];
            const extraMessage_1 = (pbsListExtraCmd) => {
                let tupledArg_6, tupledArg_7;
                switch (pbsListExtraCmd.tag) {
                    case 1: {
                        const projectReference_2 = pbsListExtraCmd.fields[0];
                        const pbsReference_1 = pbsListExtraCmd.fields[1];
                        return Cmd_batch(ofArray([Cmd_map((arg0_13) => (new Msg(10, arg0_13)), Cmd_OfFunc_result((tupledArg_6 = [projectReference_2, pbsReference_1], new PbsTreeMenuMsg(5, tupledArg_6[0], tupledArg_6[1])))), Cmd_OfFunc_result((tupledArg_7 = [projectReference_2, pbsReference_1], new Msg(5, tupledArg_7[0], tupledArg_7[1])))]));
                    }
                    case 2: {
                        return Cmd_OfFunc_result(new Msg(8));
                    }
                    default: {
                        return Cmd_none();
                    }
                }
            };
            const matchValue_5 = state.PbsesListState;
            if (matchValue_5 == null) {
                const pbsState_1 = new PbsListComponentState(new PbsListRestAddresses(PbsListApiAddress_fetchPbsesAddress, PbsListApiAddress_fetchPbsTranslationsAddress, PbsListApiAddress_postUpdatePbsAddress, () => "TODO", () => "TODO", PbsListApiAddress_postUpdatePbsCustomerDescriptionAddress, PbsListApiAddress_postResetPbsCustomerDescriptionAddress), new AccessToFunctions_1(0), new ComponentState_3(1), empty(), void 0, void 0, void 0, void 0, void 0, empty(), void 0, empty(), empty(), empty(), empty(), empty(), empty(), empty());
                const patternInput_7 = update_3(void 0, pbsMsg, pbsState_1);
                const pbsExtraCmd_1 = patternInput_7[2];
                const nextPbsState_1 = patternInput_7[0];
                const nextPbsMsg_1 = patternInput_7[1];
                const extraMsg_3 = extraMessage_1(pbsExtraCmd_1);
                return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, nextPbsState_1), Cmd_batch(ofArray([Cmd_map((arg0_17) => (new Msg(14, arg0_17)), nextPbsMsg_1), extraMsg_3]))];
            }
            else {
                const pbsState = matchValue_5;
                const patternInput_6 = update_3(void 0, pbsMsg, pbsState);
                const pbsExtraCmd = patternInput_6[2];
                const nextPbsState = patternInput_6[0];
                const nextPbsMsg = patternInput_6[1];
                const extraMsg_2 = extraMessage_1(pbsExtraCmd);
                return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, nextPbsState), Cmd_batch(ofArray([Cmd_map((arg0_16) => (new Msg(14, arg0_16)), nextPbsMsg), extraMsg_2]))];
            }
        }
        case 15: {
            return [new State(state.CurrentRoute, state.CookieSessionState, state.ServiceDeskState, state.PbsTreeMenuState, void 0), empty()];
        }
        case 12: {
            const sedMsg = msg.fields[0];
            const matchValue_6 = state.ServiceDeskState;
            if (matchValue_6 == null) {
                const sedState_3 = new ServiceDeskComponentState(new ServiceDeskRestAddresses_1(ServiceDeskApiAddress_fetchIncidentsRelatedToProcessAddress, ServiceDeskApiAddress_createIncidentAddress, ServiceDeskApiAddress_serviceDeskUploadAddress), new ComponentState_3(1), void 0, empty(), void 0, void 0);
                const patternInput_9 = update_4(sedMsg, sedState_3);
                const nextSedState_1 = patternInput_9[0];
                const nextSedMsg_1 = patternInput_9[1];
                return [new State(state.CurrentRoute, state.CookieSessionState, nextSedState_1, state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0_19) => (new Msg(12, arg0_19)), nextSedMsg_1)];
            }
            else {
                const sedState_2 = matchValue_6;
                const patternInput_8 = update_4(sedMsg, sedState_2);
                const nextSedState = patternInput_8[0];
                const nextSedMsg = patternInput_8[1];
                return [new State(state.CurrentRoute, state.CookieSessionState, nextSedState, state.PbsTreeMenuState, state.PbsesListState), Cmd_map((arg0_18) => (new Msg(12, arg0_18)), nextSedMsg)];
            }
        }
        case 13: {
            const projectId_3 = msg.fields[0];
            const pbsId_1 = msg.fields[1];
            const projectIdStr_2 = projectId_3;
            const pbsIdStr_1 = pbsId_1;
            return [new State(state.CurrentRoute, state.CookieSessionState, void 0, state.PbsTreeMenuState, state.PbsesListState), (xs_8 = ["en", "project", projectIdStr_2, pbsIdStr_1], singleton((_arg5_4) => {
                RouterModule_nav(ofArray(xs_8), 1, 1);
            }))];
        }
        case 16: {
            return [new State(state.CurrentRoute, void 0, void 0, void 0, void 0), singleton((_arg59) => {
                RouterModule_nav(singleton("#"), 1, 1);
            })];
        }
        default: {
            const newRoute = msg.fields[0];
            return routeCommander(newRoute, state);
        }
    }
}

