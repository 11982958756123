import * as style from "../../../../../../Aimtec.Components.React/Components/FineUploader/style.scss";
import { initFineUploader as initFineUploader_1 } from "../../../../../../Aimtec.Components.React/Components/FineUploader/FineUploaderComponent.js";
import { Record } from "../../../.fable/fable-library.3.0.0/Types.js";
import { obj_type, record_type, string_type, class_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { Component } from "react";
import * as react from "react";


export const initFineUploader = initFineUploader_1;

export class FineUploaderModel extends Record {
    constructor(IncidentId, Endpoint) {
        super();
        this.IncidentId = IncidentId;
        this.Endpoint = Endpoint;
    }
}

export function FineUploaderModel$reflection() {
    return record_type("FineUploader.FineUploaderModel", [], FineUploaderModel, () => [["IncidentId", class_type("System.Guid")], ["Endpoint", string_type]]);
}

export class FineUploaderComponent extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    componentDidMount() {
        const __ = this;
        initFineUploader((__.props).Endpoint, __.props.IncidentId);
    }
    render() {
        return react.createElement("div", {
            id: "fine-uploader",
        });
    }
    componentWillUnmount() {
    }
}

export function FineUploaderComponent$reflection() {
    return class_type("FineUploader.FineUploaderComponent", void 0, FineUploaderComponent, class_type("Fable.React.Component`2", [FineUploaderModel$reflection(), obj_type]));
}

export function FineUploaderComponent_$ctor_Z6185ED9C(props) {
    return new FineUploaderComponent(props);
}

