import { fetch$, Types_RequestProperties } from "../../../.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { singleton } from "../../../.fable/fable-library.3.0.0/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../.fable/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../.fable/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { SimpleJson_tryParse } from "../../../.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { createTypeInfo } from "../../../.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { OnlineDocumentationSession$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import { union_type, string_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { Convert_fromJson } from "../../../.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";
import { Cmd_OfPromise_either } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { CookieSessionMsg } from "./Types.js";

export const requestProps = singleton(new Types_RequestProperties(4, "include"));

export function fetchSession(address) {
    const request = () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(address(), requestProps).then(((_arg1) => {
        const res = _arg1;
        return res.text().then(((_arg2) => {
            let matchValue, inputJson, typeInfo;
            const txt = _arg2;
            return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentationSession$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentationSession$reflection()]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })()));
        }));
    })))));
    return Cmd_OfPromise_either(request, void 0, (arg0) => (new CookieSessionMsg(1, arg0)), (arg0_1) => (new CookieSessionMsg(4, arg0_1)));
}

