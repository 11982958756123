import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { toString, Record } from "../../../../.fable/fable-library.3.0.0/Types.js";
import { record_type, bool_type, lambda_type, unit_type, tuple_type, string_type, class_type } from "../../../../.fable/fable-library.3.0.0/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { CKEditorComponent, EditorProps } from "../../CKEDITOR/View.js";
import { Interop_reactApi } from "../../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { interpolate, toText, join } from "../../../../.fable/fable-library.3.0.0/String.js";
import { singleton } from "../../../../.fable/fable-library.3.0.0/List.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

export class Props extends Record {
    constructor(Id, Name, Description, OnNameChange, OnDescriptionChange, OnEditCancel, OnSave, IsTranslation, OnCreateTranslation) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.OnNameChange = OnNameChange;
        this.OnDescriptionChange = OnDescriptionChange;
        this.OnEditCancel = OnEditCancel;
        this.OnSave = OnSave;
        this.IsTranslation = IsTranslation;
        this.OnCreateTranslation = OnCreateTranslation;
    }
}

export function Props$reflection() {
    return record_type("PbsEdit.Props", [], Props, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["OnNameChange", lambda_type(tuple_type(class_type("System.Guid"), string_type), unit_type)], ["OnDescriptionChange", lambda_type(tuple_type(class_type("System.Guid"), string_type), unit_type)], ["OnEditCancel", lambda_type(class_type("System.Guid"), unit_type)], ["OnSave", lambda_type(tuple_type(bool_type, class_type("System.Guid")), unit_type)], ["IsTranslation", bool_type], ["OnCreateTranslation", lambda_type(tuple_type(class_type("System.Guid"), class_type("System.Guid")), unit_type)]]);
}

function editPbsNameInput(onNameChange, name) {
    return createElement("input", {
        className: "form-control",
        type: "Text",
        defaultValue: name,
        onChange: (e) => {
            onNameChange(toString(op_Dynamic()(e.currentTarget)("value")));
        },
    });
}

function ckeditorCmp(onDescriptionChange, id, description) {
    let editorProps;
    const relatedItemId = toString(id);
    editorProps = (new EditorProps(toString(id), "pbs_ckeditor", description, (editorData) => {
        onDescriptionChange(editorData);
    }, relatedItemId));
    return react.createElement(CKEditorComponent, editorProps);
}

function cancelPbsEditBtn(onEditCancel, pbsId) {
    return createElement("button", {
        className: "btn btn-warning",
        children: "Close",
        onClick: (_arg1) => {
            onEditCancel(pbsId);
        },
    });
}

function savePbsEditBtn(onSave, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Save",
        onClick: (_arg1) => {
            onSave([false, pbsId]);
        },
    });
}

function saveAndCloseBtn(onSave, pbsId) {
    return createElement("button", {
        className: "btn btn-warning",
        children: "Save and Close",
        onClick: (_arg1) => {
            onSave([true, pbsId]);
        },
    });
}

function createTranslationButtonOrFrag(pbsId, onCreateTranslation) {
    let children, children_2;
    return createElement("div", {
        className: "dropdown",
        children: Interop_reactApi.Children.toArray([createElement("button", {
            className: "btn btn-secondary dropdown-toggle",
            type: "button",
            id: "exportButton",
            ["data-bs-toggle"]: "dropdown",
            ["aria-expanded"]: false,
            children: "Create Translation",
        }), createElement("ul", {
            className: "dropdown-menu",
            ["aria-labelledby"]: join(" ", ["exportButton"]),
            children: Interop_reactApi.Children.toArray([(children = singleton(createElement("a", {
                className: "dropdown-item",
                onClick: (_arg1) => {
                    onCreateTranslation([pbsId, "177f04f4-017c-e211-83e6-005056975d80"]);
                },
                children: "English",
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = singleton(createElement("a", {
                className: "dropdown-item",
                children: "German",
                onClick: (_arg2) => {
                    onCreateTranslation([pbsId, "f414f7eb-017c-e211-83e6-005056975d80"]);
                },
            })), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))]),
        })]),
    });
}

export function Render(props) {
    return createElement("div", {
        id: toText(interpolate("%A%P()", [props.Id])),
        className: "edit-border",
        children: Interop_reactApi.Children.toArray([editPbsNameInput((name) => {
            props.OnNameChange([props.Id, name]);
        }, props.Name), ckeditorCmp((description) => {
            props.OnDescriptionChange([props.Id, description]);
        }, props.Id, props.Description), createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "btn-group",
                children: Interop_reactApi.Children.toArray([savePbsEditBtn(props.OnSave, props.Id), saveAndCloseBtn(props.OnSave, props.Id), cancelPbsEditBtn(props.OnEditCancel, props.Id), createTranslationButtonOrFrag(props.Id, props.OnCreateTranslation)]),
            })]),
        })]),
    });
}

