import { render as render_1 } from "../ErrorPage/ErrorPage.js";
import * as style from "../../../../../../Aimtec.Components.React/Components/PbsTreeMenu/style.scss";
import { render as render_2 } from "./ModuleFilter.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../../.fable/Feliz.1.40.0/React.fs.js";
import { map, length, empty, ofSeq, contains, singleton, append, distinct } from "../../../.fable/fable-library.3.0.0/List.js";
import { createObj, stringHash } from "../../../.fable/fable-library.3.0.0/Util.js";
import { List_remove } from "../../../AIM/Aimtec.D365.Domain/CollectionExtensions.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { singleton as singleton_1, append as append_1, delay } from "../../../.fable/fable-library.3.0.0/Seq.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { PbsTreeMenuMsg } from "./Types.js";
import { toString } from "../../../.fable/fable-library.3.0.0/Types.js";
import { printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { render as render_3 } from "../Preloader/Preloader.js";

export function render(renderInputProps) {
    const onExportChildren = renderInputProps.onExportChildren;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const matchValue = state.ComponentState;
    switch (matchValue.tag) {
        case 2: {
            const errorMesssages = matchValue.fields[0];
            return render_1(errorMesssages, void 0);
        }
        case 0: {
            const matchValue_1 = state.OnlineDocumentation;
            if (matchValue_1 != null) {
                const odc = matchValue_1;
                const op_Dynamic = (o, prop) => o[prop];
                const menu = odc.Menu;
                const expandedParentPbsesIds = state.ExpandedParentPbsesId;
                const projectReference = odc.Project;
                const pbsReference = state.SelectedPbsId;
                const moduleFilterCmp = render_2(odc.ServiceModules, state.SelectedServiceModule, dispatch);
                const patternInput = useFeliz_React__React_useState_Static_1505(expandedParentPbsesIds);
                const setExpandedPbsesIds = patternInput[1];
                const expandedPbsesIds = patternInput[0];
                const onProcessExpand = (id) => {
                    setExpandedPbsesIds(distinct(append(expandedPbsesIds, singleton(id)), {
                        Equals: (x, y) => (x === y),
                        GetHashCode: stringHash,
                    }));
                };
                const onProcessCollapse = (id_1) => {
                    setExpandedPbsesIds(distinct(List_remove(id_1, expandedPbsesIds), {
                        Equals: (x_1, y_1) => (x_1 === y_1),
                        GetHashCode: stringHash,
                    }));
                };
                const isExpanded = (id_2) => contains(id_2, expandedPbsesIds, {
                    Equals: (x_2, y_2) => (x_2 === y_2),
                    GetHashCode: stringHash,
                });
                const isSelected = (id_3) => {
                    if (pbsReference == null) {
                        return false;
                    }
                    else {
                        const pbsReference_1 = pbsReference;
                        return id_3 === pbsReference_1.Id;
                    }
                };
                const processesMenuItem = (pbsReference_2, hasChildren, openRequestCount, totalRequestCount) => createElement("li", {
                    className: isSelected(pbsReference_2.Id) ? "menu-vertical-li-selected" : "",
                    children: Interop_reactApi.Children.toArray(Array.from(ofSeq(delay(() => append_1(singleton_1(createElement("a", {
                        className: "expander-collapser",
                        onClick: (_arg1) => {
                            if (isExpanded(pbsReference_2.Id)) {
                                onProcessCollapse(pbsReference_2.Id);
                            }
                            else {
                                onProcessExpand(pbsReference_2.Id);
                            }
                        },
                        children: hasChildren ? (isExpanded(pbsReference_2.Id) ? "-" : "+") : "",
                    })), delay(() => {
                        let arg30, arg20;
                        return append_1(singleton_1(createElement("a", {
                            style: createObj(isSelected(pbsReference_2.Id) ? singleton(["textDecoration", "underline"]) : empty()),
                            className: "",
                            onClick: (_arg2) => {
                                let tupledArg;
                                dispatch((tupledArg = [new XrmEntityReference(projectReference.Id, projectReference.Name), pbsReference_2], new PbsTreeMenuMsg(5, tupledArg[0], tupledArg[1])));
                            },
                            children: (arg30 = toString(totalRequestCount), (arg20 = toString(openRequestCount), toText(printf("%s (%s/%s)"))(pbsReference_2.Name)(arg20)(arg30))),
                        })), delay(() => {
                            if (onExportChildren != null) {
                                const fn = onExportChildren;
                                return singleton_1(createElement("button", {
                                    className: "btn btn-sm btn-right",
                                    onClick: (_arg3) => {
                                        fn(pbsReference_2.Id);
                                    },
                                    children: "exp",
                                }));
                            }
                            else {
                                return singleton_1(react.createElement(react.Fragment, {}));
                            }
                        }));
                    })))))),
                });
                const createMenu = (menu_1) => {
                    let menu_2;
                    if (menu_2 = menu_1, length(menu_2.Children) === 0) {
                        const menu_3 = menu_1;
                        return processesMenuItem(new XrmEntityReference(menu_3.Parent.Id, menu_3.Parent.Name), false, menu_3.Parent.OpenRequestsCount, menu_3.Parent.TotalRequestsCount);
                    }
                    else {
                        const menu_4 = menu_1;
                        return react.createElement(react.Fragment, {}, processesMenuItem(new XrmEntityReference(menu_4.Parent.Id, menu_4.Parent.Name), true, menu_4.Parent.OpenRequestsCount, menu_4.Parent.TotalRequestsCount), createElement("ul", {
                            style: createObj(isExpanded(menu_4.Parent.Id) ? singleton(["display", "contents"]) : singleton(["display", "none"])),
                            children: Interop_reactApi.Children.toArray(Array.from(map(createMenu, ofSeq(menu_4.Children)))),
                        }));
                    }
                };
                const children_2 = ofSeq(delay(() => append_1(singleton_1(moduleFilterCmp), delay(() => {
                    let menuOrFilteredMenu;
                    const matchValue_4 = state.FilteredMenu;
                    if (matchValue_4 == null) {
                        menuOrFilteredMenu = map(createMenu, ofSeq(menu.Children));
                    }
                    else {
                        const fMenu = matchValue_4;
                        menuOrFilteredMenu = map(createMenu, ofSeq(fMenu.Children));
                    }
                    return singleton_1(createElement("nav", {
                        className: "menu-vertical",
                        children: Interop_reactApi.Children.toArray([createElement("ul", {
                            children: Interop_reactApi.Children.toArray(Array.from(menuOrFilteredMenu)),
                        })]),
                    }));
                }))));
                return createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                });
            }
            else {
                return createElement("h1", {
                    children: ["Unitialised menu"],
                });
            }
        }
        default: {
            return render_3("");
        }
    }
}

