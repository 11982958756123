import { render as render_1 } from "../About/AboutPage.js";
import { map, ofSeq } from "../../../.fable/fable-library.3.0.0/List.js";
import { singleton, delay } from "../../../.fable/fable-library.3.0.0/Seq.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { Msg } from "../../Types.js";

export function render(projects, dispatch) {
    if (projects.tail == null) {
        return render_1(void 0);
    }
    else {
        const children = ofSeq(delay(() => {
            const projectsElement = createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(map((er) => createElement("div", {
                    className: "row",
                    children: Interop_reactApi.Children.toArray([createElement("button", {
                        children: er.Name,
                        className: "btn btn-xl btn-warning",
                        onClick: (_arg1) => {
                            dispatch(new Msg(3, er.Id));
                        },
                    }), createElement("hr", {})]),
                }), projects))),
            });
            return singleton(createElement("div", {
                className: "container",
                children: Interop_reactApi.Children.toArray([createElement("h2", {
                    children: "Projects",
                }), projectsElement]),
            }));
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

