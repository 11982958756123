import { Record, Union } from "../.fable/fable-library.3.0.0/Types.js";
import { record_type, option_type, union_type, int32_type, class_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { CookieSessionMsg$reflection, CookieSessionState$reflection } from "../Aimtec.Components.React/Components/CookieSession/Types.js";
import { ServiceDeskMsg$reflection, ServiceDeskComponentState$reflection } from "../Aimtec.Components.React/Components/ServiceDesk/Types.js";
import { PbsTreeMenuMsg$reflection, PbsTreeMenuState$reflection } from "../Aimtec.Components.React/Components/PbsTreeMenu/Types.js";
import { PbsListMsg$reflection, PbsListComponentState$reflection } from "../Aimtec.Components.React/Components/PbsList/Types.js";
import { XrmEntityReference$reflection } from "../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Projects", "Project", "ProjectProcess", "ProjectProcessServiceDesk", "ProjectProcessServiceDeskDetail", "ProjectProcessServiceDeskCreate", "PageNotFound"];
    }
}

export function Route$reflection() {
    return union_type("Types.Route", [], Route, () => [[], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")], ["Item3", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")], ["Item3", int32_type], ["Item4", int32_type]], []]);
}

export class State extends Record {
    constructor(CurrentRoute, CookieSessionState, ServiceDeskState, PbsTreeMenuState, PbsesListState) {
        super();
        this.CurrentRoute = CurrentRoute;
        this.CookieSessionState = CookieSessionState;
        this.ServiceDeskState = ServiceDeskState;
        this.PbsTreeMenuState = PbsTreeMenuState;
        this.PbsesListState = PbsesListState;
    }
}

export function State$reflection() {
    return record_type("Types.State", [], State, () => [["CurrentRoute", Route$reflection()], ["CookieSessionState", option_type(CookieSessionState$reflection())], ["ServiceDeskState", option_type(ServiceDeskComponentState$reflection())], ["PbsTreeMenuState", option_type(PbsTreeMenuState$reflection())], ["PbsesListState", option_type(PbsListComponentState$reflection())]]);
}

export function State_get_Empty() {
    return new State(new Route(0), void 0, void 0, void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RouteChanged", "NavigateHome", "NavigageProjectsHome", "NavigateProjectHome", "SelectProject", "SelectPbs", "SelectService", "SelectServiceModule", "QuitServiceFilter", "SessionMessages", "MenuMessages", "QuitMenu", "SEDMessages", "QuitSED", "PbsMessages", "QuitPbs", "Logout", "RestError"];
    }
}

export function Msg$reflection() {
    return union_type("Types.Msg", [], Msg, () => [[["Item", Route$reflection()]], [], [], [["Item", class_type("System.Guid")]], [["Item", XrmEntityReference$reflection()]], [["Item1", XrmEntityReference$reflection()], ["Item2", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [["Item", XrmEntityReference$reflection()]], [], [["Item", CookieSessionMsg$reflection()]], [["Item", PbsTreeMenuMsg$reflection()]], [], [["Item", ServiceDeskMsg$reflection()]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item", PbsListMsg$reflection()]], [], [], [["Item", class_type("System.Exception")]]]);
}

