import { singleton, ofArray } from "../../../.fable/fable-library.3.0.0/List.js";
import { SilentRequest, RedirectRequest } from "../Fable.Msal/Fable.Msal.Types.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../.fable/Fable.Promise.2.2.0/Promise.fs.js";
import { promise } from "../../../.fable/Fable.Promise.2.2.0/PromiseImpl.fs.js";
import { Cmd_OfPromise_either } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsListMsg } from "./Types.js";
import { fetch$, Types_RequestProperties, Types_HttpRequestHeaders } from "../../../.fable/Fable.Fetch.2.2.0/Fetch.fs.js";
import { interpolate, printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { keyValueList } from "../../../.fable/fable-library.3.0.0/MapUtil.js";
import { handleRedirectPromise } from "../Fable.Msal/Fable.Msal.js";
import { SimpleJson_tryParse } from "../../../.fable/Fable.SimpleJson.3.19.0/SimpleJson.fs.js";
import { createTypeInfo } from "../../../.fable/Fable.SimpleJson.3.19.0/TypeInfo.Converter.fs.js";
import { CreatePbsTranslationPostParams$reflection, PbsToEdit$reflection, Pbs$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";
import { class_type, option_type, union_type, string_type, tuple_type, list_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { Convert_serialize, Convert_fromJson } from "../../../.fable/Fable.SimpleJson.3.19.0/Json.Converter.fs.js";

export function loginRedirect(pci) {
    const redirectRequest = new RedirectRequest(ofArray(["openid", "profile"]));
    const request = () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (pci.loginRedirect(redirectRequest).then((() => {
        const authResponse = void 0;
        return Promise.resolve("OK");
    })))));
    return Cmd_OfPromise_either(request, void 0, (arg0) => (new PbsListMsg(1, arg0)), (arg0_1) => (new PbsListMsg(30, arg0_1)));
}

export function reqHeads(token) {
    let headers;
    return singleton((headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))));
}

export function fetchPbses(pci, address, projectId) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1);
        return handleRedirectPromise(pci).then((() => {
            const hrp = void 0;
            return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
                const authResponse = _arg2;
                const idToken = authResponse.idToken;
                return fetch$(address_1, reqHeads(idToken)).then(((_arg3) => {
                    const res = _arg3;
                    return res.text().then(((_arg4) => {
                        let matchValue, inputJson, typeInfo;
                        const txt = _arg4;
                        return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Pbs$reflection()), list_type(XrmEntityReference$reflection()))]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                        })()));
                    }));
                }));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, projectId, (arg0) => (new PbsListMsg(3, arg0)), (arg0_1) => (new PbsListMsg(30, arg0_1)));
}

export function fetchTranslations(pci, address, projectId, languageId) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (projectId_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const address_1 = address(projectId_1, languageId);
        return handleRedirectPromise(pci).then((() => {
            const hrp = void 0;
            return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
                const authResponse = _arg2;
                const idToken = authResponse.idToken;
                return fetch$(address_1, reqHeads(idToken)).then(((_arg3) => {
                    const res = _arg3;
                    return res.text().then(((_arg4) => {
                        let matchValue, inputJson, typeInfo;
                        const txt = _arg4;
                        return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(tuple_type(Pbs$reflection(), option_type(Pbs$reflection()))), class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(tuple_type(Pbs$reflection(), option_type(Pbs$reflection()))), class_type("System.Guid"))]], [["ErrorValue", string_type]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                        })()));
                    }));
                }));
            }));
        }));
    }));
    return Cmd_OfPromise_either(request, projectId, (arg0) => (new PbsListMsg(7, arg0)), (arg0_1) => (new PbsListMsg(30, arg0_1)));
}

export function pbsEditPostRequestProps(token, jsonObject) {
    let headers;
    let serializedObj;
    const typeInfo = createTypeInfo(PbsToEdit$reflection());
    serializedObj = Convert_serialize(jsonObject, typeInfo);
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function translationCreatePostRequestProps(token, jsonObject) {
    let headers;
    let serializedObj;
    const typeInfo = createTypeInfo(CreatePbsTranslationPostParams$reflection());
    serializedObj = Convert_serialize(jsonObject, typeInfo);
    return ofArray([(headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(2, serializedObj)]);
}

export function postCreatePbsTranslation(pci, address, createTranslationParams) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (createTranslationParams_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            const address_1 = address();
            return fetch$(address_1, translationCreatePostRequestProps(idToken, createTranslationParams_1)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(Pbs$reflection(), class_type("System.Guid"), class_type("System.Guid"), class_type("System.Guid")), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(Pbs$reflection(), class_type("System.Guid"), class_type("System.Guid"), class_type("System.Guid"))]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, createTranslationParams, (arg0) => (new PbsListMsg(27, arg0)), (exn) => (new PbsListMsg(31, createTranslationParams.ParentPbsId, toText(interpolate("Error creating PBS translation %P()", [exn.message])))));
}

export function postUpdatePbs(pci, address, saveAndClose, pbsToUpdate) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            const address_1 = address();
            return fetch$(address_1, pbsEditPostRequestProps(idToken, pbsToUpdate_1)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(25, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsTranslation(pci, address, saveAndClose, pbsToUpdate) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            const address_1 = address();
            return fetch$(address_1, pbsEditPostRequestProps(idToken, pbsToUpdate_1)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, pbsToUpdate, (result) => {
        const tupledArg = [saveAndClose, result];
        return new PbsListMsg(29, tupledArg[0], tupledArg[1]);
    }, (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

export function postUpdatePbsCustomerDescription(pci, address, pbsToUpdate) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            const address_1 = address();
            return fetch$(address_1, pbsEditPostRequestProps(idToken, pbsToUpdate_1)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, pbsToUpdate, (arg0) => (new PbsListMsg(17, arg0)), (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

export function postResetCustomerDescription(pci, address, pbsToUpdate) {
    const silentRequest = new SilentRequest(pci.getAllAccounts()[0], ofArray(["openid", "profile"]));
    const request = (pbsToUpdate_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (handleRedirectPromise(pci).then((() => {
        const hrp = void 0;
        return pci.acquireTokenSilent(silentRequest).then(((_arg2) => {
            const authResponse = _arg2;
            const idToken = authResponse.idToken;
            const address_1 = address();
            return fetch$(address_1, pbsEditPostRequestProps(idToken, pbsToUpdate_1)).then(((_arg3) => {
                const res = _arg3;
                return res.text().then(((_arg4) => {
                    let matchValue, inputJson, typeInfo;
                    const txt = _arg4;
                    return Promise.resolve((matchValue = SimpleJson_tryParse(txt), (matchValue != null) ? (inputJson = matchValue, (typeInfo = createTypeInfo(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Pbs$reflection(), tuple_type(class_type("System.Guid"), string_type)], FSharpResult$2, () => [[["ResultValue", Pbs$reflection()]], [["ErrorValue", tuple_type(class_type("System.Guid"), string_type)]]])), Convert_fromJson(inputJson, typeInfo))) : (() => {
                        throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
                    })()));
                }));
            }));
        }));
    })))));
    return Cmd_OfPromise_either(request, pbsToUpdate, (arg0) => (new PbsListMsg(17, arg0)), (exn) => (new PbsListMsg(31, pbsToUpdate.Id, exn.message)));
}

