import * as msal from "@azure/msal-browser";

// https://www.npmjs.com/package/@azure/msal-browser
// https://stackoverflow.com/questions/63347299/unable-to-acquire-token-silently-or-via-redirect-using-msal-browser
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md

export var createMsalInstance = (clientId) => {
  const msalConfig = {
    auth: {
      clientId: clientId,
      authority: "https://login.microsoftonline.com/b3811028-ce6e-4b01-bcb0-db419328ffc5"
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };

  const msalInstance =
    new msal.PublicClientApplication(msalConfig);

  msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      if (!tokenResponse) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          // No user signed in
          msalInstance.loginRedirect();
        }
      } else {
        // Do something with the tokenResponse
      }
    })
    .catch((error) => {
    });


  return msalInstance
}

export var handleRedirectPromise = (pci) => {
  return pci.handleRedirectPromise()
}
    