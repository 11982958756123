export var initFineUploader = function (endpoint,incidentId) {
    var fu = new qq.FineUploader({
        debug: true,
        element: document.getElementById('fine-uploader'),
        template: 'qq-template',
        request: {
            endpoint: endpoint
        },
        validation: {
            allowedExtensions: ["7z", "bmp", "cfa", "cfd", "csv", "doc", "docx", "dsk", "gif", "gpc",
                                "ikm", "ini", "ipm", "jpeg", "jpg", "kpc", "log", "okm", "pdf", "png",
                                "pps", "ppsx", "ppt", "pptx", "rar", "ska", "ski", "sta", "txt", "xls",
                                "xlsx", "xml", "zip"],
            sizeLimit: 5*1024*1024
        },
        deleteFile: {
            enabled: true,
            endpoint: '/uploads'
        },
        retry: {
            enableAuto: true
        },
        callbacks: {
            onSubmit : function(id, fileName) {
                this.setParams({ incidentId: incidentId });
            }
        }
    })

    return fu
}