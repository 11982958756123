import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { AimtecServiceDesk_urgencyString } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Sed.Types.js";
import { printf, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { map } from "../../../.fable/fable-library.3.0.0/List.js";

export function render(serviceDesk, dispatch) {
    let arg10, matchValue, c, matchValue_1, c_1, children_1;
    const serviceDeskSideInfo = createElement("div", {
        className: (serviceDesk.Side === 100000001) ? "service-desk-detail-side-info side-info-customer" : "service-desk-detail-side-info side-info-supplier",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: "The request is currently being handled by the supplier.",
        })]),
    });
    const serviceDeskDescription = createElement("div", {
        className: "service-desk-detail-description-box",
        children: Interop_reactApi.Children.toArray([createElement("h3", {
            children: ["Description:"],
        }), createElement("p", {
            dangerouslySetInnerHTML: {
                __html: serviceDesk.Description,
            },
        })]),
    });
    const serviceDeskHeading = createElement("div", {
        className: "service-desk-heading",
        children: Interop_reactApi.Children.toArray([createElement("h4", {
            children: [serviceDesk.Title],
        })]),
    });
    const serviceDeskCode = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: serviceDesk.RequestNumber,
        })]),
    });
    const serviceDeskUrgency = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: (arg10 = AimtecServiceDesk_urgencyString(serviceDesk.Urgency), toText(printf("Urgency: %s"))(arg10)),
        })]),
    });
    const serviceDeskCustomer = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: (matchValue = serviceDesk.PrimaryContact, (matchValue == null) ? "N/A" : (c = matchValue, toText(printf("Customer: %s"))(c.Name))),
        })]),
    });
    const serviceDeskOwner = createElement("div", {
        className: "service-desk-text-field",
        children: Interop_reactApi.Children.toArray([createElement("label", {
            children: (matchValue_1 = serviceDesk.Owner, (matchValue_1 == null) ? "N/A" : (c_1 = matchValue_1, toText(printf("Owner: %s"))(c_1.Name))),
        })]),
    });
    const showCommentsButton = createElement("button", {
        className: "button stroke small blue pull-right",
        children: "Comments",
    });
    const historyButton = createElement("button", {
        className: "button stroke small blue pull-right",
        children: "History",
    });
    let attachments;
    const matchValue_2 = serviceDesk.Attachments;
    if (matchValue_2.tail != null) {
        const t = matchValue_2.tail;
        const h = matchValue_2.head;
        attachments = react.createElement(react.Fragment, {}, createElement("br", {}), createElement("label", {
            children: "File Attachments:",
        }), (children_1 = map((att) => createElement("li", {
            children: Interop_reactApi.Children.toArray([createElement("a", {
                href: "https://localhost:44377/api/SedCookie/download/" + att.Id,
                target: "_blank",
                children: att.Name,
            })]),
        }), serviceDesk.Attachments), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        })));
    }
    else {
        attachments = react.createElement(react.Fragment, {});
    }
    return createElement("div", {
        className: "service-desk-container",
        children: Interop_reactApi.Children.toArray([serviceDeskHeading, createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col-md-4",
                children: Interop_reactApi.Children.toArray([serviceDeskCode]),
            }), createElement("div", {
                className: "col-md-8",
                children: Interop_reactApi.Children.toArray([serviceDeskSideInfo]),
            })]),
        }), createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col-md-4",
                children: Interop_reactApi.Children.toArray([serviceDeskUrgency]),
            }), createElement("div", {
                className: "col-md-4",
                children: Interop_reactApi.Children.toArray([serviceDeskCustomer]),
            }), createElement("div", {
                className: "col-md-4",
                children: Interop_reactApi.Children.toArray([serviceDeskOwner]),
            })]),
        }), serviceDeskDescription, attachments]),
    });
}

