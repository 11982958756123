import * as style from "../../../../../../Aimtec.Components.React/Components/ErrorPage/style.scss";
import { createElement } from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";


export const render = createElement("div", {
    className: "container",
    children: Interop_reactApi.Children.toArray([createElement("h1", {
        children: ["Route not found"],
    })]),
});

