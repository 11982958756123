import { fetchPbsChildren, fetchProject } from "./RestCookie.js";
import { loginRedirect, fetchPbsChildrenUserAgentApplication, fetchProjectUserAgentApplication } from "./RestMsal.js";
import { Cmd_OfFunc_result, Cmd_none } from "../../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { PbsTreeMenuMsg, PbsTreeMenuState, PbsTreeMenuExtraMsg } from "./Types.js";
import { map } from "../../../.fable/fable-library.3.0.0/Option.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { ComponentState as ComponentState_5 } from "../../Types.js";
import { map as map_1, filter, singleton, empty } from "../../../.fable/fable-library.3.0.0/List.js";
import { PbsMenuItemModule_fromPbs, PbsOperations_createMenu, PbsOperations_createPBSHieararchy } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Pbs.Types.js";

export function update(pcio, msg, state) {
    let msg_1;
    let fetchProjectFunction;
    if (pcio == null) {
        fetchProjectFunction = ((address_1) => ((projectId_1) => fetchProject(address_1, projectId_1)));
    }
    else {
        const pci = pcio;
        fetchProjectFunction = ((address) => ((projectId) => fetchProjectUserAgentApplication(pci, address, projectId)));
    }
    let fetchPbsChildrenFunction;
    if (pcio == null) {
        fetchPbsChildrenFunction = ((address_3) => ((projectId_3) => fetchPbsChildren(address_3, projectId_3)));
    }
    else {
        const pci_1 = pcio;
        fetchPbsChildrenFunction = ((address_2) => ((projectId_2) => fetchPbsChildrenUserAgentApplication(pci_1, address_2, projectId_2)));
    }
    let loginRedirectFn;
    if (pcio == null) {
        loginRedirectFn = Cmd_none();
    }
    else {
        const pci_2 = pcio;
        loginRedirectFn = loginRedirect(pci_2);
    }
    switch (msg.tag) {
        case 1: {
            const response = msg.fields[0];
            return [state, Cmd_none(), new PbsTreeMenuExtraMsg(0)];
        }
        case 2: {
            const selectedPbsId = msg.fields[1];
            const projectId_4 = msg.fields[0];
            const selectedPbsReference = map((id) => (new XrmEntityReference(id, "")), selectedPbsId);
            const matchValue = state.SelectedProjectId;
            if (matchValue == null) {
                return [new PbsTreeMenuState(new ComponentState_5(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_4, ""), selectedPbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchProjectFunction(state.PbsMenuRestAddresses.FetchProjectAddress)(projectId_4), new PbsTreeMenuExtraMsg(0)];
            }
            else {
                const currentProjectId = matchValue;
                if (currentProjectId.Id === projectId_4) {
                    return [state, empty(), new PbsTreeMenuExtraMsg(0)];
                }
                else {
                    return [new PbsTreeMenuState(new ComponentState_5(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_4, ""), selectedPbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchProjectFunction(state.PbsMenuRestAddresses.FetchProjectAddress)(projectId_4), new PbsTreeMenuExtraMsg(0)];
                }
            }
        }
        case 3: {
            const selectedPbsId_1 = msg.fields[1];
            const projectId_5 = msg.fields[0];
            const selectedPbsReference_1 = map((id_1) => (new XrmEntityReference(id_1, "")), selectedPbsId_1);
            const matchValue_2 = state.SelectedProjectId;
            if (matchValue_2 == null) {
                return [new PbsTreeMenuState(new ComponentState_5(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_5, ""), selectedPbsReference_1, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchPbsChildrenFunction(state.PbsMenuRestAddresses.FetchPbsChildrenAddress)(projectId_5), new PbsTreeMenuExtraMsg(0)];
            }
            else {
                const currentProjectId_1 = matchValue_2;
                if (currentProjectId_1.Id === projectId_5) {
                    return [state, empty(), new PbsTreeMenuExtraMsg(0)];
                }
                else {
                    return [new PbsTreeMenuState(new ComponentState_5(1), state.PbsMenuRestAddresses, state.OnlineDocumentation, new XrmEntityReference(projectId_5, ""), selectedPbsReference_1, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), fetchPbsChildrenFunction(state.PbsMenuRestAddresses.FetchPbsChildrenAddress)(projectId_5), new PbsTreeMenuExtraMsg(0)];
                }
            }
        }
        case 4: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                const message = result.fields[0];
                return [new PbsTreeMenuState(new ComponentState_5(2, singleton(message)), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
            }
            else {
                const odc = result.fields[0];
                return [new PbsTreeMenuState(new ComponentState_5(0), state.PbsMenuRestAddresses, odc, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(4, odc)];
            }
        }
        case 5: {
            const projectReference = msg.fields[0];
            const pbsReference = msg.fields[1];
            let extraMessageOrNoop;
            const matchValue_4 = state.SelectedPbsId;
            if (matchValue_4 != null) {
                const selectedId = matchValue_4;
                if (selectedId.Id === pbsReference.Id) {
                    extraMessageOrNoop = (new PbsTreeMenuExtraMsg(0));
                }
                else {
                    const tupledArg_1 = [projectReference, pbsReference];
                    extraMessageOrNoop = (new PbsTreeMenuExtraMsg(1, tupledArg_1[0], tupledArg_1[1]));
                }
            }
            else {
                const tupledArg = [projectReference, pbsReference];
                extraMessageOrNoop = (new PbsTreeMenuExtraMsg(1, tupledArg[0], tupledArg[1]));
            }
            return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, projectReference, pbsReference, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), extraMessageOrNoop];
        }
        case 6: {
            const serviceRef = msg.fields[0];
            const serviceId = serviceRef.Id;
            const matchValue_5 = state.OnlineDocumentation;
            if (matchValue_5 != null) {
                const odc_1 = matchValue_5;
                const filteredPbses = PbsOperations_createPBSHieararchy(odc_1.Pbses, filter((pbs_1) => (pbs_1.ProjectServiceModule.Id === serviceId), filter((pbs) => (pbs.ProjectServiceModule != null), odc_1.Pbses)));
                const filteredMenu = PbsOperations_createMenu(map_1(PbsMenuItemModule_fromPbs, filteredPbses));
                return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, filteredMenu), empty(), new PbsTreeMenuExtraMsg(2, serviceRef)];
            }
            else {
                return [state, empty(), new PbsTreeMenuExtraMsg(2, serviceRef)];
            }
        }
        case 7: {
            const serviceModuleRef = msg.fields[0];
            const serviceModuleId = serviceModuleRef.Id;
            const matchValue_6 = state.OnlineDocumentation;
            if (matchValue_6 != null) {
                const odc_2 = matchValue_6;
                const filteredPbses_1 = PbsOperations_createPBSHieararchy(odc_2.Pbses, filter((pbs_4) => (pbs_4.ProjectServiceModule.Id === serviceModuleId), filter((pbs_3) => (pbs_3.ProjectServiceModule != null), odc_2.Pbses)));
                const filteredMenu_1 = PbsOperations_createMenu(map_1(PbsMenuItemModule_fromPbs, filteredPbses_1));
                return [new PbsTreeMenuState(state.ComponentState, state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, filteredMenu_1), empty(), new PbsTreeMenuExtraMsg(3, serviceModuleRef)];
            }
            else {
                return [state, empty(), new PbsTreeMenuExtraMsg(3, serviceModuleRef)];
            }
        }
        case 8: {
            return [state, empty(), new PbsTreeMenuExtraMsg(5)];
        }
        case 9: {
            const exn = msg.fields[0];
            const matchValue_7 = exn.message;
            if (msg_1 = matchValue_7, msg_1.indexOf("no_account_error") >= 0) {
                const msg_2 = matchValue_7;
                if (pcio == null) {
                    return [new PbsTreeMenuState(new ComponentState_5(2, singleton(exn.message)), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
                }
                else {
                    const ua = pcio;
                    return [state, Cmd_OfFunc_result(new PbsTreeMenuMsg(0)), new PbsTreeMenuExtraMsg(0)];
                }
            }
            else {
                return [new PbsTreeMenuState(new ComponentState_5(2, singleton(exn.message)), state.PbsMenuRestAddresses, state.OnlineDocumentation, state.SelectedProjectId, state.SelectedPbsId, state.SelectedService, state.SelectedServiceModule, state.ExpandedParentPbsesId, state.FilteredMenu), empty(), new PbsTreeMenuExtraMsg(0)];
            }
        }
        default: {
            return [state, loginRedirectFn, new PbsTreeMenuExtraMsg(0)];
        }
    }
}

