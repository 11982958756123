import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { Record } from "../../../../.fable/fable-library.3.0.0/Types.js";
import { record_type, lambda_type, unit_type, bool_type, int32_type, option_type, string_type, class_type } from "../../../../.fable/fable-library.3.0.0/Reflection.js";
import { XrmEntityReference, XrmEntityReference$reflection } from "../../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { AccessToFunctions$reflection } from "../Types.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../../../../.fable/fable-library.3.0.0/Util.js";
import { Interop_reactApi } from "../../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { map, defaultArg } from "../../../../.fable/fable-library.3.0.0/Option.js";
import { interpolate, toText } from "../../../../.fable/fable-library.3.0.0/String.js";
import { ofArray } from "../../../../.fable/fable-library.3.0.0/List.js";


export class Props extends Record {
    constructor(Id, Name, Description, DescriptionCustomer, Level, ModifiedOn, ModifiedBy, IsSelected, AccessToFunctions, OnPbsEdit, OnPbsEditCustomer, OnServiceDeskStart, OnPbsSelection) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.DescriptionCustomer = DescriptionCustomer;
        this.Level = (Level | 0);
        this.ModifiedOn = ModifiedOn;
        this.ModifiedBy = ModifiedBy;
        this.IsSelected = IsSelected;
        this.AccessToFunctions = AccessToFunctions;
        this.OnPbsEdit = OnPbsEdit;
        this.OnPbsEditCustomer = OnPbsEditCustomer;
        this.OnServiceDeskStart = OnServiceDeskStart;
        this.OnPbsSelection = OnPbsSelection;
    }
}

export function Props$reflection() {
    return record_type("PbsRead.Props", [], Props, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["DescriptionCustomer", option_type(string_type)], ["Level", int32_type], ["ModifiedOn", string_type], ["ModifiedBy", option_type(XrmEntityReference$reflection())], ["IsSelected", bool_type], ["AccessToFunctions", AccessToFunctions$reflection()], ["OnPbsEdit", lambda_type(class_type("System.Guid"), unit_type)], ["OnPbsEditCustomer", lambda_type(class_type("System.Guid"), unit_type)], ["OnServiceDeskStart", option_type(lambda_type(class_type("System.Guid"), unit_type))], ["OnPbsSelection", lambda_type(XrmEntityReference$reflection(), unit_type)]]);
}

function pbsHeading(level, props) {
    switch (level) {
        case 1: {
            return createElement("h3", createObj(props));
        }
        case 2: {
            return createElement("h4", createObj(props));
        }
        case 3: {
            return createElement("h5", createObj(props));
        }
        case 4: {
            return createElement("h5", createObj(props));
        }
        default: {
            return createElement("h5", createObj(props));
        }
    }
}

function pbsEditButton(onPbsEdit, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Edit Pbs",
        onClick: (_arg1) => {
            onPbsEdit(pbsId);
        },
    });
}

function pbsEditCustomerButton(onPbsEditCustomer, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Add Description",
        onClick: (_arg1) => {
            onPbsEditCustomer(pbsId);
        },
    });
}

function serviceDeskButton(onServiceDeskStart, pbsId) {
    if (onServiceDeskStart == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const osdsFn = onServiceDeskStart;
        return createElement("button", {
            className: "btn btn-primary",
            onClick: (_arg1) => {
                osdsFn(pbsId);
            },
            children: "Service Desk",
        });
    }
}

function pbsControlButtons(accessToFunctions, onPbsEdit, onPbsEditCustomer, onServiceDeskStart, pbsId) {
    switch (accessToFunctions.tag) {
        case 1: {
            return createElement("div", {
                className: "btn-group position-absolute top-0 end-0",
                children: Interop_reactApi.Children.toArray([serviceDeskButton(onServiceDeskStart, pbsId)]),
            });
        }
        case 2: {
            return createElement("div", {
                className: "btn-group position-absolute top-0 end-0",
                children: Interop_reactApi.Children.toArray([serviceDeskButton(onServiceDeskStart, pbsId), pbsEditButton(onPbsEdit, pbsId)]),
            });
        }
        case 3: {
            return createElement("div", {
                className: "btn-group position-absolute top-0 end-0",
                children: Interop_reactApi.Children.toArray([serviceDeskButton(onServiceDeskStart, pbsId), pbsEditCustomerButton(onPbsEditCustomer, pbsId)]),
            });
        }
        case 4: {
            return createElement("div", {
                className: "btn-group position-absolute top-0 end-0",
                children: Interop_reactApi.Children.toArray([serviceDeskButton(onServiceDeskStart, pbsId), pbsEditButton(onPbsEdit, pbsId), pbsEditCustomerButton(onPbsEditCustomer, pbsId)]),
            });
        }
        default: {
            return react.createElement(react.Fragment, {});
        }
    }
}

function descriptionCustomerContainer(descriptionCustomer) {
    if (descriptionCustomer != null) {
        const descriptionCustomer_1 = descriptionCustomer;
        return createElement("div", {
            className: "description-customer-container",
            children: Interop_reactApi.Children.toArray([createElement("p", {
                dangerouslySetInnerHTML: {
                    __html: descriptionCustomer_1,
                },
            })]),
        });
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

function modifiedInfo(on, by) {
    const modifiedBy = defaultArg(map((txt) => txt.Name, by), "N/A");
    return createElement("label", {
        className: "modified-by-on-label",
        children: toText(interpolate("Modified on %P()", [on])),
    });
}

export function Render(props) {
    return createElement("div", {
        className: (props.IsSelected === true) ? "selected-process" : "",
        id: props.Id,
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "row position-relative",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "col",
                children: Interop_reactApi.Children.toArray([pbsHeading(props.Level, ofArray([["style", {
                    textDecoration: "underline",
                }], ["className", "process-header"], ["children", props.Name], ["onClick", (_arg1) => {
                    props.OnPbsSelection(new XrmEntityReference(props.Id, props.Name));
                }]]))]),
            }), createElement("div", {
                className: "col",
                children: Interop_reactApi.Children.toArray([pbsControlButtons(props.AccessToFunctions, props.OnPbsEdit, props.OnPbsEditCustomer, props.OnServiceDeskStart, props.Id)]),
            })]),
        }), createElement("p", {
            dangerouslySetInnerHTML: {
                __html: props.Description,
            },
        }), descriptionCustomerContainer(props.DescriptionCustomer), createElement("div", {
            className: "",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "",
                children: Interop_reactApi.Children.toArray([modifiedInfo(props.ModifiedOn, props.ModifiedBy)]),
            })]),
        }), createElement("hr", {})]),
    });
}

