import { Result_Bind, Result_MapError, FSharpResult$2 } from "../fable-library.3.0.0/Choice.js";
import { append, singleton } from "../fable-library.3.0.0/List.js";
import { ofChoice } from "./Result.fs.js";
import { uncurry, curry } from "../fable-library.3.0.0/Util.js";

export function Validation_ok(x) {
    return new FSharpResult$2(0, x);
}

export function Validation_error(e) {
    return new FSharpResult$2(1, singleton(e));
}

export function Validation_ofResult(x) {
    return Result_MapError(singleton, x);
}

export function Validation_ofChoice(x) {
    return Validation_ofResult(ofChoice(x));
}

export function Validation_apply(f, x) {
    const matchValue = [f, x];
    let pattern_matching_result, f_1, x_1, errs, errs1, errs2;
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 1) {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 1) {
            pattern_matching_result = 2;
            errs1 = copyOfStruct.fields[0];
            errs2 = copyOfStruct_1.fields[0];
        }
        else {
            pattern_matching_result = 1;
            errs = copyOfStruct.fields[0];
        }
    }
    else {
        const copyOfStruct_2 = matchValue[1];
        if (copyOfStruct_2.tag === 1) {
            pattern_matching_result = 1;
            errs = copyOfStruct_2.fields[0];
        }
        else {
            pattern_matching_result = 0;
            f_1 = copyOfStruct.fields[0];
            x_1 = copyOfStruct_2.fields[0];
        }
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, f_1(x_1));
        }
        case 1: {
            return new FSharpResult$2(1, errs);
        }
        case 2: {
            return new FSharpResult$2(1, append(errs1, errs2));
        }
    }
}

export function Validation_retn(x) {
    return Validation_ofResult(new FSharpResult$2(0, x));
}

export function Validation_map2(f, x, y) {
    return Validation_apply(Validation_apply(Validation_retn(curry(2, f)), x), y);
}

export function Validation_map3(f, x, y, z) {
    return Validation_apply(Validation_map2(uncurry(2, curry(3, f)), x, y), z);
}

export function Validation_bind(f, x) {
    return Result_Bind(f, x);
}

export function Validation_zip(x1, x2) {
    const matchValue = [x1, x2];
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 1) {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 1) {
            const e1 = copyOfStruct.fields[0];
            const e2 = copyOfStruct_1.fields[0];
            return new FSharpResult$2(1, append(e1, e2));
        }
        else {
            const e = copyOfStruct.fields[0];
            return new FSharpResult$2(1, e);
        }
    }
    else {
        const copyOfStruct_2 = matchValue[1];
        if (copyOfStruct_2.tag === 1) {
            const e_1 = copyOfStruct_2.fields[0];
            return new FSharpResult$2(1, e_1);
        }
        else {
            const x1res = copyOfStruct.fields[0];
            const x2res = copyOfStruct_2.fields[0];
            return new FSharpResult$2(0, [x1res, x2res]);
        }
    }
}

