import * as style from "../../../../../../Aimtec.Components.React/Components/ServiceDesk/style.scss";
import { Record } from "../../../.fable/fable-library.3.0.0/Types.js";
import { record_type, option_type, enum_type, int32_type, string_type, class_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { AimtecServiceDesk_sideString, AimtecServiceDesk_urgencyString, StateCode, StateCode$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Sed.Types.js";
import { XrmEntityReference$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { ofArray, map, ofSeq } from "../../../.fable/fable-library.3.0.0/List.js";
import { append, singleton, delay } from "../../../.fable/fable-library.3.0.0/Seq.js";
import { ServiceDeskMsg } from "./Types.js";
import { FSharpChoice$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { equalsSafe } from "../../../.fable/fable-library.3.0.0/Util.js";
import { map as map_1, defaultArg } from "../../../.fable/fable-library.3.0.0/Option.js";


export class ServiceDeskInTable extends Record {
    constructor(Id, TicketNumber, Name, StateCode, Urgency, Side, Owner) {
        super();
        this.Id = Id;
        this.TicketNumber = TicketNumber;
        this.Name = Name;
        this.StateCode = StateCode;
        this.Urgency = (Urgency | 0);
        this.Side = (Side | 0);
        this.Owner = Owner;
    }
}

export function ServiceDeskInTable$reflection() {
    return record_type("ServiceDesksTable.ServiceDeskInTable", [], ServiceDeskInTable, () => [["Id", class_type("System.Guid")], ["TicketNumber", string_type], ["Name", string_type], ["StateCode", StateCode$reflection()], ["Urgency", enum_type("Aimtec.D365.Sed.Urgency", int32_type, [["High", 100000000], ["Medium", 100000001], ["Low", 100000002]])], ["Side", enum_type("Aimtec.D365.Sed.Side", int32_type, [["Supplier", 100000000], ["Customer", 100000001]])], ["Owner", option_type(XrmEntityReference$reflection())]]);
}

export function ServiceDeskInTable_FromServiceDesk_96E548F(sd) {
    return new ServiceDeskInTable(sd.Id, sd.RequestNumber, sd.Title, sd.StateCode, sd.Urgency, sd.Side, sd.Owner);
}

export function render(serviceDeksk, projectId, pbsId, selectedServieDeskId, isCreatingServiceDesk, dispatch, onServiceDeskQuit) {
    let children_3;
    const serviceDeskButtons = createElement("div", {
        className: "btn-group",
        children: Interop_reactApi.Children.toArray(Array.from(ofSeq(delay(() => (isCreatingServiceDesk ? singleton(react.createElement(react.Fragment, {})) : append(singleton(createElement("button", {
            className: "btn btn-warning",
            children: "Close Service Desk",
            onClick: (_arg1) => {
                onServiceDeskQuit();
            },
        })), delay(() => singleton(createElement("button", {
            className: "btn btn-secondary",
            children: "Create Service Desk",
            onClick: (_arg2) => {
                let tupledArg;
                dispatch((tupledArg = [projectId, pbsId, 200000001, 100000002], new ServiceDeskMsg(5, tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3])));
            },
        }))))))))),
    });
    const attachments = (atts) => {
        if (atts.tail != null) {
            const t = atts.tail;
            const h = atts.head;
            return createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(map((att) => createElement("li", {
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        children: att.Name,
                        href: "TODO",
                        target: "_blank",
                    })]),
                }), atts))),
            });
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    };
    const tableRow = (sd) => {
        let activePatternResult15878;
        const $007CSelected$007CClosed$007COnCustomerSide$007COnSupplierSide$007CAnythingElse$007C = (sd_1) => {
            let sd_2, sd_4, sd_6, sd_8;
            if (sd_2 = sd_1, sd_2.Id === selectedServieDeskId) {
                const sd_3 = sd_1;
                return new FSharpChoice$2(0, void 0);
            }
            else if (sd_4 = sd_1, equalsSafe(sd_4.StateCode, new StateCode(1))) {
                const sd_5 = sd_1;
                return new FSharpChoice$2(1, void 0);
            }
            else if (sd_6 = sd_1, sd_6.Side === 100000001) {
                const sd_7 = sd_1;
                return new FSharpChoice$2(2, void 0);
            }
            else if (sd_8 = sd_1, sd_8.Side === 100000000) {
                const sd_9 = sd_1;
                return new FSharpChoice$2(3, void 0);
            }
            else {
                return new FSharpChoice$2(4, void 0);
            }
        };
        const rowClass = (activePatternResult15878 = $007CSelected$007CClosed$007COnCustomerSide$007COnSupplierSide$007CAnythingElse$007C(sd), (activePatternResult15878.tag === 1) ? "closed-table-row" : ((activePatternResult15878.tag === 2) ? "customer-side-table-row" : ((activePatternResult15878.tag === 3) ? "supplier-side-table-row" : ((activePatternResult15878.tag === 4) ? "" : "selected-table-row"))));
        return createElement("tr", {
            onClick: (_arg3) => {
                let tupledArg_1;
                dispatch((tupledArg_1 = [projectId, pbsId, sd.Id], new ServiceDeskMsg(3, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2])));
            },
            className: (rowClass + " ") + "service-desk-table-row",
            children: Interop_reactApi.Children.toArray([createElement("td", {
                children: sd.Name,
            }), createElement("td", {
                children: sd.TicketNumber,
            }), createElement("td", {
                children: AimtecServiceDesk_urgencyString(sd.Urgency),
            }), createElement("td", {
                children: AimtecServiceDesk_sideString(sd.Side),
            }), createElement("td", {
                children: defaultArg(map_1((o) => o.Name, sd.Owner), "N/A"),
            })]),
        });
    };
    return createElement("div", {
        className: "service-desk-container",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([serviceDeskButtons]),
        }), createElement("table", {
            className: "table",
            children: Interop_reactApi.Children.toArray([createElement("thead", {
                className: "thead-dark",
                children: Interop_reactApi.Children.toArray([(children_3 = ofArray([createElement("td", {
                    children: "Number",
                }), createElement("td", {
                    children: "Name",
                }), createElement("td", {
                    children: "Urgency",
                }), createElement("td", {
                    children: "Side",
                }), createElement("td", {
                    children: "Owner",
                }), react.createElement(react.Fragment, {})]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                }))]),
            }), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(map(tableRow, map(ServiceDeskInTable_FromServiceDesk_96E548F, serviceDeksk)))),
            })]),
        })]),
    });
}

