import { Parsimmon_parse } from "../Fable.Parsimmon.4.1.0/Parsimmon.fs.js";
import { jsonParser } from "./Parser.fs.js";
import { join, toText, printf, toFail } from "../fable-library.3.0.0/String.js";
import { empty, singleton, concat, ofSeq, ofArray, map as map_1 } from "../fable-library.3.0.0/List.js";
import { tryFind, ofList, toList } from "../fable-library.3.0.0/Map.js";
import { map as map_3, delay, getEnumerator } from "../fable-library.3.0.0/Seq.js";
import { toString } from "../fable-library.3.0.0/Decimal.js";
import { toDecimal } from "../fable-library.3.0.0/BigInt.js";
import { toString as toString_1 } from "../fable-library.3.0.0/Date.js";
import { partialApply, isIterable } from "../fable-library.3.0.0/Util.js";
import { value as value_5, some } from "../fable-library.3.0.0/Option.js";
import { $007CNativeObject$007C_$007C, $007CNativeArray$007C_$007C, $007CNull$007C_$007C, $007CNativeBool$007C_$007C, $007CNativeNumber$007C_$007C, $007CNativeString$007C_$007C } from "./TypeCheck.fs.js";
import { Json } from "./Json.fs.js";
import { map as map_2 } from "../fable-library.3.0.0/Array.js";

export function InteropUtil_isDateOffset(x) {
    if (x instanceof Date) {
        return "offset" in x;
    }
    else {
        return false;
    }
}

export function InteropUtil_isObjectLiteral(x) {
    return (typeof x) === "object";
}

export function InteropUtil_isBigInt(x) {
    if (((((!(x == null)) ? InteropUtil_isObjectLiteral(x) : false) ? ("signInt" in x) : false) ? ("v" in x) : false) ? ("digits" in (x["v"])) : false) {
        return "bound" in (x["v"]);
    }
    else {
        return false;
    }
}

export function SimpleJson_tryParse(input) {
    return Parsimmon_parse(input, jsonParser);
}

export function SimpleJson_parse(input) {
    const matchValue = SimpleJson_tryParse(input);
    if (matchValue == null) {
        return toFail(printf("Could not parse the JSON input: %s"))(input);
    }
    else {
        const result = matchValue;
        return result;
    }
}

export function SimpleJson_toString(_arg1) {
    if (_arg1.tag === 2) {
        if (_arg1.fields[0]) {
            return "true";
        }
        else {
            return "false";
        }
    }
    else if (_arg1.tag === 0) {
        const number = _arg1.fields[0];
        return number.toString();
    }
    else if (_arg1.tag === 1) {
        const text = _arg1.fields[0];
        return toText(printf("\"%s\""))(text);
    }
    else if (_arg1.tag === 4) {
        const elements = _arg1.fields[0];
        const arg10_1 = join(",", map_1(SimpleJson_toString, elements));
        return toText(printf("[%s]"))(arg10_1);
    }
    else if (_arg1.tag === 5) {
        const map = _arg1.fields[0];
        const arg10_3 = join(",", map_1((tupledArg) => {
            const key = tupledArg[0];
            const value = tupledArg[1];
            const arg20 = SimpleJson_toString(value);
            return toText(printf("\"%s\":%s"))(key)(arg20);
        }, toList(map)));
        return toText(printf("{%s}"))(arg10_3);
    }
    else {
        return "null";
    }
}

export function SimpleJson_toPlainObject(input) {
    switch (input.tag) {
        case 2: {
            const value = input.fields[0];
            return value;
        }
        case 0: {
            const value_1 = input.fields[0];
            return value_1;
        }
        case 1: {
            const value_2 = input.fields[0];
            return value_2;
        }
        case 4: {
            const values = input.fields[0];
            const array = [];
            const enumerator = getEnumerator(values);
            try {
                while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                    const value_3 = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                    void (array.push(SimpleJson_toPlainObject(value_3)));
                }
            }
            finally {
                enumerator.Dispose();
            }
            return array;
        }
        case 5: {
            const map = input.fields[0];
            const jsObject = ({});
            const enumerator_1 = getEnumerator(toList(map));
            try {
                while (enumerator_1["System.Collections.IEnumerator.MoveNext"]()) {
                    const forLoopVar = enumerator_1["System.Collections.Generic.IEnumerator`1.get_Current"]();
                    const value_4 = forLoopVar[1];
                    const key = forLoopVar[0];
                    jsObject[key] = SimpleJson_toPlainObject(value_4);
                }
            }
            finally {
                enumerator_1.Dispose();
            }
            return jsObject;
        }
        default: {
            return null;
        }
    }
}

export function SimpleJson_stringify(value) {
    if (value == null) {
        return JSON.stringify(null);
    }
    else {
        return JSON.stringify(value, (key, jsonValue) => {
            let copyOfStruct;
            if (InteropUtil_isBigInt(jsonValue)) {
                const bigInt = jsonValue;
                return toString(toDecimal(bigInt));
            }
            else if (jsonValue instanceof Date) {
                const dateOffset = jsonValue;
                return toString_1(dateOffset, "o");
            }
            else {
                return ((typeof jsonValue) === "string") ? jsonValue : (isIterable(jsonValue) ? (Array.isArray(jsonValue) ? jsonValue : (Array.from(jsonValue))) : (InteropUtil_isBigInt(jsonValue) ? toString(toDecimal(jsonValue)) : (InteropUtil_isDateOffset(jsonValue) ? (copyOfStruct = jsonValue, toString_1(copyOfStruct, "O")) : jsonValue)));
            }
        }, some(0));
    }
}

export function SimpleJson_parseNative$0027(x) {
    const activePatternResult3212 = $007CNativeString$007C_$007C(x);
    if (activePatternResult3212 != null) {
        const str = activePatternResult3212;
        return new Json(1, str);
    }
    else {
        const activePatternResult3211 = $007CNativeNumber$007C_$007C(x);
        if (activePatternResult3211 != null) {
            const number = activePatternResult3211;
            return new Json(0, number);
        }
        else {
            const activePatternResult3210 = $007CNativeBool$007C_$007C(x);
            if (activePatternResult3210 != null) {
                const value = activePatternResult3210;
                return new Json(2, value);
            }
            else if ($007CNull$007C_$007C(x) != null) {
                return new Json(3);
            }
            else {
                const activePatternResult3208 = $007CNativeArray$007C_$007C(x);
                if (activePatternResult3208 != null) {
                    const arr = activePatternResult3208;
                    return new Json(4, ofArray(map_2(SimpleJson_parseNative$0027, arr)));
                }
                else {
                    const activePatternResult3207 = $007CNativeObject$007C_$007C(x);
                    if (activePatternResult3207 != null) {
                        const object = value_5(activePatternResult3207);
                        return new Json(5, ofList(ofSeq(delay(() => map_3((key) => [key, SimpleJson_parseNative$0027(object[key])], Object.keys(object))))));
                    }
                    else {
                        return new Json(3);
                    }
                }
            }
        }
    }
}

export function SimpleJson_parseNative(input) {
    const parsed = JSON.parse(input);
    return SimpleJson_parseNative$0027(parsed);
}

export function SimpleJson_tryParseNative(input) {
    try {
        return SimpleJson_parseNative(input);
    }
    catch (ex) {
        return void 0;
    }
}

export function SimpleJson_fromObjectLiteral(x) {
    try {
        return SimpleJson_parseNative$0027(x);
    }
    catch (matchValue) {
        return void 0;
    }
}

export function SimpleJson_mapKeys(f, _arg1) {
    switch (_arg1.tag) {
        case 5: {
            const dictionary = _arg1.fields[0];
            return new Json(5, ofList(map_1((tupledArg) => {
                const key = tupledArg[0];
                const value = tupledArg[1];
                return [f(key), SimpleJson_mapKeys(f, value)];
            }, toList(dictionary))));
        }
        case 4: {
            const values = _arg1.fields[0];
            return new Json(4, map_1((_arg1_1) => SimpleJson_mapKeys(f, _arg1_1), values));
        }
        default: {
            const otherJsonValue = _arg1;
            return otherJsonValue;
        }
    }
}

export function SimpleJson_mapbyKey(f, _arg1) {
    switch (_arg1.tag) {
        case 5: {
            const dictionary = _arg1.fields[0];
            return new Json(5, ofList(map_1((tupledArg) => {
                const key = tupledArg[0];
                const value = tupledArg[1];
                return [key, f(key, value)];
            }, toList(dictionary))));
        }
        case 4: {
            const values = _arg1.fields[0];
            return new Json(4, map_1((_arg1_1) => SimpleJson_mapbyKey(f, _arg1_1), values));
        }
        default: {
            const otherJsonValue = _arg1;
            return otherJsonValue;
        }
    }
}

export function SimpleJson_mapKeysByPath(f, json) {
    const mapKey = (xs, _arg1) => {
        switch (_arg1.tag) {
            case 5: {
                const dictionary = _arg1.fields[0];
                return new Json(5, ofList(map_1((tupledArg) => {
                    const key = tupledArg[0];
                    const value = tupledArg[1];
                    const keyPath = concat([xs, singleton(key)]);
                    const matchValue = f(keyPath);
                    if (matchValue == null) {
                        return [key, mapKey(keyPath, value)];
                    }
                    else {
                        const nextKey = matchValue;
                        return [nextKey, mapKey(keyPath, value)];
                    }
                }, toList(dictionary))));
            }
            case 4: {
                const values = _arg1.fields[0];
                return new Json(4, map_1(partialApply(1, mapKey, [xs]), values));
            }
            default: {
                const otherJsonValue = _arg1;
                return otherJsonValue;
            }
        }
    };
    return mapKey(empty(), json);
}

export function SimpleJson_readPath(keys_mut, input_mut) {
    SimpleJson_readPath:
    while (true) {
        const keys = keys_mut, input = input_mut;
        const matchValue = [keys, input];
        let pattern_matching_result, dict, key, dict_1, firstKey, rest;
        if (matchValue[0].tail != null) {
            if (matchValue[0].tail.tail == null) {
                if (matchValue[1].tag === 5) {
                    pattern_matching_result = 1;
                    dict = matchValue[1].fields[0];
                    key = matchValue[0].head;
                }
                else {
                    pattern_matching_result = 3;
                }
            }
            else if (matchValue[1].tag === 5) {
                pattern_matching_result = 2;
                dict_1 = matchValue[1].fields[0];
                firstKey = matchValue[0].head;
                rest = matchValue[0].tail;
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return void 0;
            }
            case 1: {
                return tryFind(key, dict);
            }
            case 2: {
                const matchValue_1 = tryFind(firstKey, dict_1);
                let pattern_matching_result_1, nextDict;
                if (matchValue_1 != null) {
                    if (matchValue_1.tag === 5) {
                        pattern_matching_result_1 = 0;
                        nextDict = matchValue_1.fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        keys_mut = rest;
                        input_mut = (new Json(5, nextDict));
                        continue SimpleJson_readPath;
                    }
                    case 1: {
                        return void 0;
                    }
                }
            }
            case 3: {
                return void 0;
            }
        }
        break;
    }
}

