import { FSharpChoice$2 } from "../.fable/fable-library.3.0.0/Choice.js";
import * as bootstrap$002Emin from "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import * as style from "../../../src/style.scss";
import { useReact_useEffect_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.40.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { render as render_1 } from "../Aimtec.Components.React/Components/PbsList/View.js";
import { Msg } from "./Types.js";
import { render as render_2 } from "../Aimtec.Components.React/Components/PbsList/MobileView.js";
import { render as render_3 } from "../Aimtec.Components.React/Components/PbsTreeMenu/View.js";
import { render as render_4 } from "../Aimtec.Components.React/Components/PbsTreeMenu/MobileView.js";
import { render as render_5 } from "../Aimtec.Components.React/Components/ServiceDesk/View.js";
import { singleton as singleton_1, ofSeq, filter, map } from "../.fable/fable-library.3.0.0/List.js";
import { render as render_6 } from "./Pages/Projects/ProjectsHomePage.js";
import { ServiceDeskMsg } from "../Aimtec.Components.React/Components/ServiceDesk/Types.js";
import { Interop_reactApi } from "../.fable/Feliz.1.40.0/Interop.fs.js";
import { render as render_7 } from "../Aimtec.Components.React/Components/ErrorPage/RouteNotFoundPage.js";
import { createObj } from "../.fable/fable-library.3.0.0/Util.js";
import { singleton, append, delay } from "../.fable/fable-library.3.0.0/Seq.js";
import { HeaderPropps, render as render_8 } from "../Aimtec.Components.React/Components/AimtecHeader/AimtecHeaderComponent.js";
import { AppConfig_appVersion } from "../Configuration.js";
import { CookieSessionMsg } from "../Aimtec.Components.React/Components/CookieSession/Types.js";
import { render as render_9 } from "../Aimtec.Components.React/Components/ErrorPage/ErrorPage.js";
import { render as render_10 } from "../Aimtec.Components.React/Components/Preloader/Preloader.js";
import { RouterModule_router } from "../.fable/Feliz.Router.3.7.0/Router.fs.js";
import { parseRoute } from "./Router.js";

export function $007CMobile$007CDesktop$007C(width) {
    let width_1;
    if (width_1 = width, width_1 < 700) {
        const width_2 = width;
        return new FSharpChoice$2(0, void 0);
    }
    else {
        return new FSharpChoice$2(1, void 0);
    }
}

export function render(renderInputProps) {
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(window.innerWidth);
    const update = patternInput[1];
    const screenWidth = patternInput[0];
    useReact_useEffect_3A5B6456(() => {
        window.addEventListener("resize", (e) => {
            update(window.innerWidth);
        });
    });
    const pbsesListCmp = (onServiceDeskStart) => {
        const matchValue = state.PbsesListState;
        if (matchValue == null) {
            return createElement("h1", {
                children: ["Unititalised Pbs Component"],
            });
        }
        else {
            const pbsState = matchValue;
            return createElement(render_1, {
                state: pbsState,
                dispatch: (arg) => {
                    dispatch(new Msg(14, arg));
                },
                onServiceDeskStart: onServiceDeskStart,
            });
        }
    };
    const pbsesListMobileCmp = (onServiceDeskStart_1) => {
        const matchValue_1 = state.PbsesListState;
        if (matchValue_1 == null) {
            return createElement("h1", {
                children: ["Unititalised Pbs Component"],
            });
        }
        else {
            const pbsState_1 = matchValue_1;
            return createElement(render_2, {
                state: pbsState_1,
                dispatch: (arg_1) => {
                    dispatch(new Msg(14, arg_1));
                },
                onServiceDeskStart: onServiceDeskStart_1,
            });
        }
    };
    let menuCmp;
    const matchValue_2 = state.PbsTreeMenuState;
    if (matchValue_2 == null) {
        menuCmp = createElement("h1", {
            children: ["Unititalised Menu Component"],
        });
    }
    else {
        const menuState = matchValue_2;
        menuCmp = createElement(render_3, {
            state: menuState,
            dispatch: (arg_2) => {
                dispatch(new Msg(10, arg_2));
            },
            onExportChildren: void 0,
        });
    }
    let mobileMenuCmp;
    const matchValue_3 = state.PbsTreeMenuState;
    if (matchValue_3 == null) {
        mobileMenuCmp = createElement("h1", {
            children: ["Unititalised Menu Component"],
        });
    }
    else {
        const menuState_1 = matchValue_3;
        mobileMenuCmp = createElement(render_4, {
            state: menuState_1,
            dispatch: (arg_3) => {
                dispatch(new Msg(10, arg_3));
            },
        });
    }
    const sedCmp = (onServiceDeskQuit) => {
        const matchValue_4 = state.ServiceDeskState;
        if (matchValue_4 == null) {
            return createElement("h1", {
                children: ["Unititalised Sed Component"],
            });
        }
        else {
            const sedState = matchValue_4;
            return render_5(sedState, (arg_4) => {
                dispatch(new Msg(12, arg_4));
            }, onServiceDeskQuit);
        }
    };
    const page = (sessionState) => {
        const matchValue_5 = state.CurrentRoute;
        switch (matchValue_5.tag) {
            case 0: {
                const projects = map((pa_1) => pa_1.Project, filter((pa) => (pa.AccessToOdc === true), sessionState.ProjectAccesses));
                return render_6(projects, dispatch);
            }
            case 1: {
                const projectId = matchValue_5.fields[0];
                const onServiceDeskStart_2 = (pbsId) => {
                    let tupledArg;
                    dispatch(new Msg(12, (tupledArg = [projectId, pbsId, void 0], new ServiceDeskMsg(0, tupledArg[0], tupledArg[1], tupledArg[2]))));
                };
                if ($007CMobile$007CDesktop$007C(screenWidth).tag === 0) {
                    return createElement("div", {
                        className: "",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([mobileMenuCmp]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsesListMobileCmp(onServiceDeskStart_2)]),
                        })]),
                    });
                }
                else {
                    return createElement("div", {
                        className: "row content",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "col-3",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-menu",
                                children: Interop_reactApi.Children.toArray([menuCmp]),
                            })]),
                        }), createElement("div", {
                            className: "col-9",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "container-pbses",
                                children: Interop_reactApi.Children.toArray([pbsesListCmp(onServiceDeskStart_2)]),
                            })]),
                        })]),
                    });
                }
            }
            case 2: {
                const selectedProcessId = matchValue_5.fields[1];
                const projectId_1 = matchValue_5.fields[0];
                const onServiceDeskStart_3 = (pbsId_1) => {
                    let tupledArg_1;
                    dispatch(new Msg(12, (tupledArg_1 = [projectId_1, pbsId_1, void 0], new ServiceDeskMsg(0, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2]))));
                };
                if ($007CMobile$007CDesktop$007C(screenWidth).tag === 0) {
                    return createElement("div", {
                        className: "",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([mobileMenuCmp]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsesListMobileCmp(onServiceDeskStart_3)]),
                        })]),
                    });
                }
                else {
                    return createElement("div", {
                        className: "row content",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "col-3",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-menu",
                                children: Interop_reactApi.Children.toArray([menuCmp]),
                            })]),
                        }), createElement("div", {
                            className: "col-9",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "container-pbses",
                                children: Interop_reactApi.Children.toArray([pbsesListCmp(onServiceDeskStart_3)]),
                            })]),
                        })]),
                    });
                }
            }
            case 3: {
                const selectedProcessId_1 = matchValue_5.fields[1];
                const projectId_2 = matchValue_5.fields[0];
                const onServiceDeskStart_4 = (pbsId_2) => {
                    let tupledArg_2;
                    dispatch(new Msg(12, (tupledArg_2 = [projectId_2, pbsId_2, void 0], new ServiceDeskMsg(0, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2]))));
                };
                const onServiceDeskQuit_1 = () => {
                    let tupledArg_3;
                    dispatch((tupledArg_3 = [projectId_2, selectedProcessId_1], new Msg(13, tupledArg_3[0], tupledArg_3[1])));
                };
                if ($007CMobile$007CDesktop$007C(screenWidth).tag === 0) {
                    return createElement("div", {
                        className: "",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([mobileMenuCmp]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_1)]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsesListMobileCmp(onServiceDeskStart_4)]),
                        })]),
                    });
                }
                else {
                    return createElement("div", {
                        className: "row content",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "col-3",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-menu",
                                children: Interop_reactApi.Children.toArray([menuCmp]),
                            })]),
                        }), createElement("div", {
                            className: "col-4",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "container-pbses",
                                children: Interop_reactApi.Children.toArray([pbsesListCmp(onServiceDeskStart_4)]),
                            })]),
                        }), createElement("div", {
                            className: "col-5",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-service-desk",
                                children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_1)]),
                            })]),
                        })]),
                    });
                }
            }
            case 4: {
                const selectedServiceDeskId = matchValue_5.fields[2];
                const selectedProcessId_2 = matchValue_5.fields[1];
                const projectId_3 = matchValue_5.fields[0];
                const onServiceDeskStart_5 = (pbsId_3) => {
                    let tupledArg_4;
                    dispatch(new Msg(12, (tupledArg_4 = [projectId_3, pbsId_3, void 0], new ServiceDeskMsg(0, tupledArg_4[0], tupledArg_4[1], tupledArg_4[2]))));
                };
                const onServiceDeskQuit_2 = () => {
                    let tupledArg_5;
                    dispatch((tupledArg_5 = [projectId_3, selectedProcessId_2], new Msg(13, tupledArg_5[0], tupledArg_5[1])));
                };
                if ($007CMobile$007CDesktop$007C(screenWidth).tag === 0) {
                    return createElement("div", {
                        className: "",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([mobileMenuCmp]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_2)]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsesListMobileCmp(onServiceDeskStart_5)]),
                        })]),
                    });
                }
                else {
                    return createElement("div", {
                        className: "row content",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "col-3",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-menu",
                                children: Interop_reactApi.Children.toArray([menuCmp]),
                            })]),
                        }), createElement("div", {
                            className: "col-4",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "container-pbses",
                                children: Interop_reactApi.Children.toArray([pbsesListCmp(onServiceDeskStart_5)]),
                            })]),
                        }), createElement("div", {
                            className: "col-5",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-service-desk",
                                children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_2)]),
                            })]),
                        })]),
                    });
                }
            }
            case 5: {
                const urgency = matchValue_5.fields[3] | 0;
                const selectedProcessId_3 = matchValue_5.fields[1];
                const requestType = matchValue_5.fields[2] | 0;
                const projectId_4 = matchValue_5.fields[0];
                const onServiceDeskStart_6 = (pbsId_4) => {
                    let tupledArg_6;
                    dispatch(new Msg(12, (tupledArg_6 = [projectId_4, pbsId_4, void 0], new ServiceDeskMsg(0, tupledArg_6[0], tupledArg_6[1], tupledArg_6[2]))));
                };
                const onServiceDeskQuit_3 = () => {
                    let tupledArg_7;
                    dispatch((tupledArg_7 = [projectId_4, selectedProcessId_3], new Msg(13, tupledArg_7[0], tupledArg_7[1])));
                };
                if ($007CMobile$007CDesktop$007C(screenWidth).tag === 0) {
                    return createElement("div", {
                        className: "",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([mobileMenuCmp]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_3)]),
                        }), createElement("div", {
                            className: "row",
                            children: Interop_reactApi.Children.toArray([pbsesListMobileCmp(onServiceDeskStart_6)]),
                        })]),
                    });
                }
                else {
                    return createElement("div", {
                        className: "row content",
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "col-3",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-menu",
                                children: Interop_reactApi.Children.toArray([menuCmp]),
                            })]),
                        }), createElement("div", {
                            className: "col-4",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "container-pbses",
                                children: Interop_reactApi.Children.toArray([pbsesListCmp(onServiceDeskStart_6)]),
                            })]),
                        }), createElement("div", {
                            className: "col-5",
                            children: Interop_reactApi.Children.toArray([createElement("div", {
                                className: "sticky-top overflow-auto container-service-desk",
                                children: Interop_reactApi.Children.toArray([sedCmp(onServiceDeskQuit_3)]),
                            })]),
                        })]),
                    });
                }
            }
            default: {
                return render_7;
            }
        }
    };
    const head = (projectRef, pbsRef, userInfo, authFn, logoFn, headerFn, projectFn) => createElement("div", createObj(ofSeq(delay(() => {
        const headerComponent = render_8(new HeaderPropps(AppConfig_appVersion, projectRef, pbsRef, userInfo, authFn, logoFn, headerFn, projectFn));
        return append(singleton(["className", "row sticky-top"]), delay(() => singleton(["children", Interop_reactApi.Children.toArray([headerComponent])])));
    }))));
    let authenticatedOrNot;
    const onLogin = () => {
        dispatch(new Msg(9, new CookieSessionMsg(0)));
    };
    const matchValue_6 = state.CookieSessionState;
    if (matchValue_6 != null) {
        const cookieSession = matchValue_6;
        const matchValue_7 = cookieSession.ComponentState;
        switch (matchValue_7.tag) {
            case 2: {
                const errorMessages = matchValue_7.fields[0];
                const header_2 = head(void 0, void 0, void 0, onLogin, () => {
                    void undefined;
                }, () => {
                    void undefined;
                }, (value_164) => {
                    void value_164;
                });
                authenticatedOrNot = [header_2, render_9(errorMessages, void 0)];
                break;
            }
            case 0: {
                const matchValue_8 = cookieSession.OnlineDocumentationSession;
                if (matchValue_8 != null) {
                    const session = matchValue_8;
                    let projectRef_1;
                    const matchValue_9 = state.PbsesListState;
                    if (matchValue_9 == null) {
                        projectRef_1 = (void 0);
                    }
                    else {
                        const s = matchValue_9;
                        projectRef_1 = s.SelectedProjectId;
                    }
                    let pbsRef_1;
                    const matchValue_10 = state.PbsesListState;
                    if (matchValue_10 == null) {
                        pbsRef_1 = (void 0);
                    }
                    else {
                        const s_1 = matchValue_10;
                        pbsRef_1 = s_1.SelectedPbs;
                    }
                    const user = session.UserInfo;
                    const onLogout = () => {
                        dispatch(new Msg(16));
                    };
                    const logoClick = (_arg1) => {
                        dispatch(new Msg(2));
                    };
                    const headerClick = (_arg2) => {
                        dispatch(new Msg(2));
                    };
                    const projectClick = (id) => {
                        dispatch(new Msg(3, id));
                    };
                    const header_4 = head(void 0, void 0, user, onLogout, logoClick, headerClick, projectClick);
                    authenticatedOrNot = [header_4, page(session)];
                }
                else {
                    const header_3 = head(void 0, void 0, void 0, onLogin, () => {
                        void undefined;
                    }, () => {
                        void undefined;
                    }, (value_167) => {
                        void value_167;
                    });
                    authenticatedOrNot = [header_3, render_9(singleton_1("Session Loaded but None"), void 0)];
                }
                break;
            }
            default: {
                const header_1 = head(void 0, void 0, void 0, onLogin, () => {
                    void undefined;
                }, () => {
                    void undefined;
                }, (value_161) => {
                    void value_161;
                });
                authenticatedOrNot = [header_1, render_10("")];
            }
        }
    }
    else {
        const header = head(void 0, void 0, void 0, onLogin, () => {
            void undefined;
        }, () => {
            void undefined;
        }, (value_157) => {
            void value_157;
        });
        authenticatedOrNot = [header, createElement("h1", {
            children: ["Unauthenticated"],
        })];
    }
    const appHeader = authenticatedOrNot[0];
    const appContent = authenticatedOrNot[1];
    return RouterModule_router({
        onUrlChanged: (arg_6) => {
            dispatch(new Msg(0, parseRoute(arg_6)));
        },
        application: react.createElement(react.Fragment, {}, createElement("div", {
            className: "",
            children: Interop_reactApi.Children.toArray([appHeader, appContent]),
        })),
    });
}

