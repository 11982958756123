import * as style from "../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { map as map_1, find, exists } from "../../../.fable/fable-library.3.0.0/List.js";
import { FSharpChoice$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";
import { map, defaultArg } from "../../../.fable/fable-library.3.0.0/Option.js";
import { XrmEntityReference } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Entity.Types.js";
import { PbsListMsg } from "./Types.js";
import { Render, Props } from "./PbsWindow/PbsRead.js";
import { createElement } from "react";
import * as react from "react";
import { interpolate, toText } from "../../../.fable/fable-library.3.0.0/String.js";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { Render as Render_1, Props as Props_1 } from "./PbsWindow/PbsEdit.js";
import { Render as Render_2, Props as Props_2 } from "./PbsWindow/PbsEditCustomer.js";
import { Render as Render_3 } from "./PbsWindow/PbsSaving.js";
import { Render as Render_4 } from "./PbsWindow/PbsError.js";
import { render as render_1 } from "../Preloader/Preloader.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

function pbsDepthClassName(pbs) {
    const matchValue = pbs.Level | 0;
    switch (matchValue) {
        case 1: {
            return "process-window-depth-1";
        }
        case 2: {
            return "process-window-depth-2";
        }
        case 3: {
            return "process-window-depth-3";
        }
        case 4: {
            return "process-window-depth-4";
        }
        default: {
            return "process-window-depth-5";
        }
    }
}

export function $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(state, pbsId) {
    if (exists((pbs) => (pbs.Id === pbsId), state.PbsesEdited)) {
        return new FSharpChoice$2(1, void 0);
    }
    else if (exists((pbs_1) => (pbs_1.Id === pbsId), state.CustomerDescriptionsEdited)) {
        return new FSharpChoice$2(2, void 0);
    }
    else if (exists((pbs_2) => (pbs_2.Id === pbsId), state.PbsesSaved)) {
        return new FSharpChoice$2(3, void 0);
    }
    else if (exists((pbs_3) => (pbs_3.Id === pbsId), state.CustomerDescriptionsSaved)) {
        return new FSharpChoice$2(4, void 0);
    }
    else if (exists((tupledArg) => {
        const pbs_4 = tupledArg[0];
        return pbs_4.Id === pbsId;
    }, state.PbsesError)) {
        return new FSharpChoice$2(5, void 0);
    }
    else if (exists((tupledArg_1) => {
        const pbs_5 = tupledArg_1[0];
        return pbs_5.Id === pbsId;
    }, state.CustomerDescriptionError)) {
        return new FSharpChoice$2(6, void 0);
    }
    else {
        return new FSharpChoice$2(0, void 0);
    }
}

export function render(renderInputProps) {
    const onServiceDeskStart = renderInputProps.onServiceDeskStart;
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const pbs_3 = (state_1, pbs, isTranslation) => {
        const matchValue = [state_1, pbs.Id];
        let pattern_matching_result;
        const activePatternResult15469 = $007CPbsDisplay$007CPbsEdit$007CPbsEditCustomer$007CPbsSavinng$007CCustomerDescriptionSaving$007CPbsInError$007CCustomerDescriptionInError$007C(matchValue[0], matchValue[1]);
        if (activePatternResult15469.tag === 1) {
            pattern_matching_result = 1;
        }
        else if (activePatternResult15469.tag === 2) {
            pattern_matching_result = 2;
        }
        else if (activePatternResult15469.tag === 3) {
            pattern_matching_result = 3;
        }
        else if (activePatternResult15469.tag === 4) {
            pattern_matching_result = 3;
        }
        else if (activePatternResult15469.tag === 5) {
            pattern_matching_result = 4;
        }
        else if (activePatternResult15469.tag === 6) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                const isSelected = (id) => defaultArg(map((spi) => (spi.Id === id), state_1.SelectedPbs), false);
                const onPbsSelect = (_arg1) => {
                    let tupledArg;
                    dispatch((tupledArg = [new XrmEntityReference(state_1.SelectedProjectId.Id, pbs.Name), new XrmEntityReference(pbs.Id, pbs.Name)], new PbsListMsg(4, tupledArg[0], tupledArg[1])));
                };
                const props = new Props(pbs.Id, pbs.Name, pbs.Description, pbs.DescriptionCustomer, pbs.Level, pbs.ModifiedOn, pbs.ModifiedBy, isSelected(pbs.Id), state_1.AccessToFunctions, (_arg2) => {
                    dispatch(new PbsListMsg(19, pbs.Id));
                }, (_arg3) => {
                    dispatch(new PbsListMsg(13, pbs.Id));
                }, onServiceDeskStart, onPbsSelect);
                return createElement("div", {
                    className: isTranslation ? "process-window" : toText(interpolate("process-window %P()", [pbsDepthClassName(pbs)])),
                    children: Interop_reactApi.Children.toArray([createElement(Render, props)]),
                });
            }
            case 1: {
                const editPbsOrTraFn = isTranslation ? ((tupledArg_3) => {
                    let tupledArg_4;
                    const saveAndClose_1 = tupledArg_3[0];
                    const id_2 = tupledArg_3[1];
                    dispatch((tupledArg_4 = [saveAndClose_1, id_2], new PbsListMsg(28, tupledArg_4[0], tupledArg_4[1])));
                }) : ((tupledArg_1) => {
                    let tupledArg_2;
                    const saveAndClose = tupledArg_1[0];
                    const id_1 = tupledArg_1[1];
                    dispatch((tupledArg_2 = [saveAndClose, id_1], new PbsListMsg(24, tupledArg_2[0], tupledArg_2[1])));
                });
                const pbsEdited = find((p) => (p.Id === pbs.Id), state_1.PbsesEdited);
                const props_3 = new Props_1(pbsEdited.Id, pbsEdited.Name, pbsEdited.Description, (tupledArg_5) => {
                    let tupledArg_6;
                    const id_3 = tupledArg_5[0];
                    const name_2 = tupledArg_5[1];
                    dispatch((tupledArg_6 = [id_3, name_2], new PbsListMsg(21, tupledArg_6[0], tupledArg_6[1])));
                }, (tupledArg_7) => {
                    let tupledArg_8;
                    const id_4 = tupledArg_7[0];
                    const description = tupledArg_7[1];
                    dispatch((tupledArg_8 = [id_4, description], new PbsListMsg(22, tupledArg_8[0], tupledArg_8[1])));
                }, (id_5) => {
                    dispatch(new PbsListMsg(20, id_5));
                }, editPbsOrTraFn, isTranslation, (tupledArg_9) => {
                    let tupledArg_10;
                    const pbsId_1 = tupledArg_9[0];
                    const languageId = tupledArg_9[1];
                    dispatch((tupledArg_10 = [state_1.SelectedProjectId.Id, pbsId_1, languageId], new PbsListMsg(26, tupledArg_10[0], tupledArg_10[1], tupledArg_10[2])));
                });
                return createElement("div", {
                    className: isTranslation ? "process-window" : toText(interpolate("process-window %P()", [pbsDepthClassName(pbs)])),
                    children: Interop_reactApi.Children.toArray([createElement(Render_1, props_3)]),
                });
            }
            case 2: {
                const props_6 = new Props_2(pbs.Id, pbs.Name, "pbs.DescriptionCustomer TODO!", (value_7) => {
                    void value_7;
                }, (value_8) => {
                    void value_8;
                }, (value_9) => {
                    void value_9;
                }, (value_10) => {
                    void value_10;
                });
                return createElement("div", {
                    className: isTranslation ? "process-window" : toText(interpolate("process-window %P()", [pbsDepthClassName(pbs)])),
                    children: Interop_reactApi.Children.toArray([createElement(Render_2, props_6)]),
                });
            }
            case 3: {
                return createElement("div", {
                    className: isTranslation ? "process-window" : toText(interpolate("process-window %P()", [pbsDepthClassName(pbs)])),
                    children: Interop_reactApi.Children.toArray([Render_3]),
                });
            }
            case 4: {
                const patternInput = find((tupledArg_11) => {
                    const p_1 = tupledArg_11[0];
                    const errMsg = tupledArg_11[1];
                    return p_1.Id === pbs.Id;
                }, state_1.PbsesError);
                const pbs_1 = patternInput[0];
                const errorMessage = patternInput[1];
                return createElement(Render_4, {
                    errorMessage: errorMessage,
                    onResetError: () => {
                        dispatch(new PbsListMsg(32, pbs_1.Id));
                    },
                });
            }
            case 5: {
                const patternInput_1 = find((tupledArg_12) => {
                    const p_2 = tupledArg_12[0];
                    const errMsg_1 = tupledArg_12[1];
                    return p_2.Id === pbs.Id;
                }, state_1.PbsesError);
                const pbs_2 = patternInput_1[0];
                const errorMessage_1 = patternInput_1[1];
                return createElement(Render_4, {
                    errorMessage: errorMessage_1,
                    onResetError: () => {
                        dispatch(new PbsListMsg(32, pbs_2.Id));
                    },
                });
            }
        }
    };
    const matchValue_1 = state.ComponentState;
    switch (matchValue_1.tag) {
        case 2: {
            const errorMessages = matchValue_1.fields[0];
            return react.createElement(react.Fragment, {}, ...map_1((msg) => createElement("h1", {
                children: [msg],
            }), errorMessages));
        }
        case 0: {
            let pbsesOrFilteredPbses;
            const matchValue_2 = state.FilteredPbses;
            if (matchValue_2.tail != null) {
                const t = matchValue_2.tail;
                const h = matchValue_2.head;
                pbsesOrFilteredPbses = state.FilteredPbses;
            }
            else {
                pbsesOrFilteredPbses = state.Pbses;
            }
            const pbsTranslationRow = (tupledArg_13) => {
                const p_3 = tupledArg_13[0];
                const t_1 = tupledArg_13[1];
                let translationSomeOrCreateWindow;
                if (t_1 == null) {
                    translationSomeOrCreateWindow = createElement("div", {
                        className: "row",
                        children: Interop_reactApi.Children.toArray([createElement("button", {
                            className: "btn btn-primary",
                            onClick: (_arg6) => {
                                let tupledArg_14;
                                dispatch((tupledArg_14 = [state.SelectedProjectId.Id, p_3.Id, state.SelectedLanguage.Id], new PbsListMsg(26, tupledArg_14[0], tupledArg_14[1], tupledArg_14[2])));
                            },
                            children: "Create Translation",
                        })]),
                    });
                }
                else {
                    const t_2 = t_1;
                    translationSomeOrCreateWindow = pbs_3(state, t_2, true);
                }
                return createElement("div", {
                    className: "row",
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "col-6",
                        children: Interop_reactApi.Children.toArray([pbs_3(state, p_3, false)]),
                    }), createElement("div", {
                        className: "col-6",
                        children: Interop_reactApi.Children.toArray([translationSomeOrCreateWindow]),
                    }), createElement("hr", {})]),
                });
            };
            const pbsesOrTranslations = (state.SelectedLanguage != null) ? map_1(pbsTranslationRow, pbsesOrFilteredPbses) : map_1((tupledArg_15) => {
                const p_4 = tupledArg_15[0];
                return pbs_3(state, p_4, false);
            }, pbsesOrFilteredPbses);
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(pbsesOrTranslations)),
            });
        }
        default: {
            return render_1("");
        }
    }
}

