import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";

export function render(onGoToProjects) {
    let goToProjectsOrFragment;
    if (onGoToProjects != null) {
        const fn = onGoToProjects;
        goToProjectsOrFragment = createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("button", {
                className: "btn btn-secondary",
                onClick: (_arg1) => {
                    fn();
                },
                children: "My Projects",
            })]),
        });
    }
    else {
        goToProjectsOrFragment = react.createElement(react.Fragment, {});
    }
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray([createElement("h1", {
            children: "Online documentation",
        }), createElement("p", {
            children: ["Have you implemented with us for example a warehouse management system project or a project of advanced\r\n      planning and scheduling? Your key-uses received a project documentation from us during the project. But where is\r\n      a project documentation now? There are situations the key-users no longer work in the company, the documentation\r\n      is not passed on to new key-users or there is no data warehouse where responsible people have an overview of the\r\n      project and their processes."],
        }), createElement("p", {
            children: ["We decided to create online documentation provided as a service on the aimtec.cloud platform. So what\r\n      exactly is it? Online documentation is a documentation specifying the scope of delivery in the form of a list\r\n      of implemented processes including a detail process description. It is also connected to the SED application\r\n      (ServiceDesk). This means that you can create a specific change requests for specific processes directly from\r\n      SED. These changes are reflected in the documentation which always keeps in the actual form. Everything is\r\n      online, nothing is „parked“ in the „drawer“ on in your mailbox."],
        }), createElement("p", {
            children: ["What are the benefits? Definetely a saving a lot of time you´re spending on finding information of\r\n      individual procesess. You can solve in average of 30 % created requests on your own. Just simply by having an\r\n      easy way to the documentation you go through before you create SED request. This will save you money.\r\n      The first – response in average up to 50 % is accelerated if you create SED request directly from online\r\n      documentation."],
        }), goToProjectsOrFragment]),
    });
}

