import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { render } from "../../ErrorPage/ErrorPage.js";
import { singleton } from "../../../../.fable/fable-library.3.0.0/List.js";


export function Render(renderInputProps) {
    const onResetError = renderInputProps.onResetError;
    const errorMessage = renderInputProps.errorMessage;
    return render(singleton(errorMessage), onResetError);
}

