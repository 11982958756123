import * as style from "../../../../../../../Aimtec.Components.React/Components/PbsList/style.scss";
import { toString, Record } from "../../../../.fable/fable-library.3.0.0/Types.js";
import { record_type, tuple_type, lambda_type, unit_type, string_type, class_type } from "../../../../.fable/fable-library.3.0.0/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { CKEditorComponent, EditorProps } from "../../CKEDITOR/View.js";
import { interpolate, toText } from "../../../../.fable/fable-library.3.0.0/String.js";
import { Interop_reactApi } from "../../../../.fable/Feliz.1.40.0/Interop.fs.js";


function op_Dynamic() {
    return (o) => ((prop) => o[prop]);
}

export class Props extends Record {
    constructor(Id, Name, Description, OnCancelEdit, OnSave, OnReset, OnDescriptionChange) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.OnCancelEdit = OnCancelEdit;
        this.OnSave = OnSave;
        this.OnReset = OnReset;
        this.OnDescriptionChange = OnDescriptionChange;
    }
}

export function Props$reflection() {
    return record_type("PbsEditCustomer.Props", [], Props, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["OnCancelEdit", lambda_type(class_type("System.Guid"), unit_type)], ["OnSave", lambda_type(class_type("System.Guid"), unit_type)], ["OnReset", lambda_type(class_type("System.Guid"), unit_type)], ["OnDescriptionChange", lambda_type(tuple_type(class_type("System.Guid"), string_type), unit_type)]]);
}

function cancetEditButton(onCancel, pbsId) {
    return createElement("button", {
        className: "btn btn-warning",
        children: "Cancel",
        onClick: (_arg1) => {
            onCancel(pbsId);
        },
    });
}

function saveButton(onSave, pbsId) {
    return createElement("button", {
        className: "btn btn-primary",
        children: "Save",
        onClick: (_arg1) => {
            onSave(pbsId);
        },
    });
}

function resetButton(onReset, pbsId) {
    return createElement("button", {
        className: "btn btn-danger",
        children: "Reset",
        onClick: (_arg1) => {
            onReset(pbsId);
        },
    });
}

function ckeditorCmp(onDescriptionChange, id, description) {
    let editorProps;
    const relatedItemId = toString(id);
    editorProps = (new EditorProps(toString(id), "pbs_ckeditor", description, (editorData) => {
        onDescriptionChange(editorData);
    }, relatedItemId));
    return react.createElement(CKEditorComponent, editorProps);
}

export function Render(props) {
    return createElement("div", {
        className: toText(interpolate(" row edit-border", [])),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "row",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "alert alert-info",
                children: toText(interpolate("Add your description to: %P()", [props.Name])),
            }), createElement("p", {
                dangerouslySetInnerHTML: {
                    __html: props.Description,
                },
            }), createElement("div", {
                className: "btn-group",
                children: Interop_reactApi.Children.toArray([cancetEditButton(props.OnCancelEdit, props.Id), saveButton(props.OnSave, props.Id), resetButton(props.OnReset, props.Id)]),
            }), ckeditorCmp((description) => {
                props.OnDescriptionChange([props.Id, props.Description]);
            }, props.Id, props.Description)]),
        })]),
    });
}

