import { Union, Record } from "../../../.fable/fable-library.3.0.0/Types.js";
import { class_type, union_type, option_type, record_type, lambda_type, unit_type, string_type } from "../../../.fable/fable-library.3.0.0/Reflection.js";
import { ComponentState$reflection } from "../../Types.js";
import { OnlineDocumentationSession$reflection } from "../../../AIM/Aimtec.D365.Domain/Aimtec.D365.Odc.Types.js";
import { FSharpResult$2 } from "../../../.fable/fable-library.3.0.0/Choice.js";

export class CookieSessionAddresses extends Record {
    constructor(ServiceDeskAddress, FetchSessionAddress) {
        super();
        this.ServiceDeskAddress = ServiceDeskAddress;
        this.FetchSessionAddress = FetchSessionAddress;
    }
}

export function CookieSessionAddresses$reflection() {
    return record_type("CookieSessionTypes.CookieSessionAddresses", [], CookieSessionAddresses, () => [["ServiceDeskAddress", string_type], ["FetchSessionAddress", lambda_type(unit_type, string_type)]]);
}

export class CookieSessionState extends Record {
    constructor(CookieSessionAddresses, ComponentState, OnlineDocumentationSession) {
        super();
        this.CookieSessionAddresses = CookieSessionAddresses;
        this.ComponentState = ComponentState;
        this.OnlineDocumentationSession = OnlineDocumentationSession;
    }
}

export function CookieSessionState$reflection() {
    return record_type("CookieSessionTypes.CookieSessionState", [], CookieSessionState, () => [["CookieSessionAddresses", CookieSessionAddresses$reflection()], ["ComponentState", ComponentState$reflection()], ["OnlineDocumentationSession", option_type(OnlineDocumentationSession$reflection())]]);
}

export class ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "SignedIn"];
    }
}

export function ExternalMsg$reflection() {
    return union_type("CookieSessionTypes.ExternalMsg", [], ExternalMsg, () => [[], [["Item", OnlineDocumentationSession$reflection()]]]);
}

export class CookieSessionMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SessionRequest", "SessionResponse", "RedirectToLoginPage", "RedirectToLogoutPage", "RestError"];
    }
}

export function CookieSessionMsg$reflection() {
    return union_type("CookieSessionTypes.CookieSessionMsg", [], CookieSessionMsg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [OnlineDocumentationSession$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", OnlineDocumentationSession$reflection()]], [["ErrorValue", string_type]]])]], [], [], [["Item", class_type("System.Exception")]]]);
}

