import { render as render_1 } from "../ErrorPage/ErrorPage.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../../.fable/Feliz.1.40.0/Interop.fs.js";
import { map } from "../../../.fable/fable-library.3.0.0/List.js";
import { join } from "../../../.fable/fable-library.3.0.0/String.js";
import { render as render_2 } from "../Preloader/Preloader.js";

export function render(renderInputProps) {
    const dispatch = renderInputProps.dispatch;
    const state = renderInputProps.state;
    const matchValue = state.ComponentState;
    switch (matchValue.tag) {
        case 2: {
            const errorMessages = matchValue.fields[0];
            return render_1(errorMessages, void 0);
        }
        case 0: {
            const matchValue_1 = state.OnlineDocumentation;
            if (matchValue_1 != null) {
                const odc = matchValue_1;
                const menuItem = (id, name) => createElement("li", {
                    className: "nav-item",
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        className: "nav-link",
                        children: name,
                        onClick: (value_6) => {
                            void value_6;
                        },
                    })]),
                });
                const menuItems = map((pbs) => menuItem(pbs.Id, pbs.Name), odc.Pbses);
                return createElement("nav", {
                    className: "navbar navbar-expand-lg navbar-dark bg-dark",
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "container-fluid",
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            className: "navbar-brand",
                            children: "Menu",
                        }), createElement("button", {
                            className: "navbar-toggler",
                            type: "button",
                            ["data-bs-toggle"]: "collapse",
                            ["data-bs-target"]: "#navbarSupportedContent",
                            ["aria-controls"]: join(" ", ["navbarSupportedContent"]),
                            ["aria-expanded"]: false,
                            ["aria-label"]: "Toggle navigation",
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                className: "navbar-toggler-icon",
                            })]),
                        }), createElement("div", {
                            id: "navbarSupportedContent",
                            className: "collapse navbar-collapse",
                            children: Interop_reactApi.Children.toArray([createElement("ul", {
                                className: "navbar-nav me-auto mb-2 mb-lg-0",
                                children: Interop_reactApi.Children.toArray([react.createElement(react.Fragment, {}, ...menuItems)]),
                            })]),
                        })]),
                    })]),
                });
            }
            else {
                return react.createElement(react.Fragment, {});
            }
        }
        default: {
            return render_2("");
        }
    }
}

